import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    ...theme.mixins.toolbar,
    textAlign: "center",
      // "@media (max-width: 1330px)": {
      //   minHeight: "56px",
      // },
  },
}));

export default useStyles;
