import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  header: {
    textAlign: "center",
  },
  title: {
    fontFamily: `var(--font)`,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    textAlign: "center",
    color: "#212121",
    // textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px !important",
    },
    "@media (max-width: 1330px)": {
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    "@media (min-width: 1920px)": {
      fontSize: "27px !important",
    },
    "@media (min-width: 3072px)": {
      fontSize: "37px !important",
    },
  },
  titleStyle: {
    marginTop: "20px",
  },
  separator: {
    height: "1px",
    background: theme.palette.accentColor.main,
    margin: "10px 0px",
    border: "1.5px solid " + theme.palette.accentColor.main,
  },
  messageGrid: {
    padding: "10px",
    textAlign: "center",
    lineHeight: "1",
  },
  body: {
    marginTop: "20px",
    marginBottom: "2px",
    justifyContent: "center",
    background: "#FFFFFF",
    boxShadow: "25px 33px 100px 0px #00000026",
    borderRadius: "10px",
    margin: "auto",
    padding: "10px 0 25px 0",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
      marginTop: "0",
    },
    "@media (min-width: 1920px)": {
      padding: "30px 0 53px 0",
    },
    "@media (min-width: 3072px)": {
      padding: "50px 0 73px 0",
    },
  },
  body1: {
    marginTop: "15px",
    marginBottom: "25px",
  },
  footer: {
    justifyContent: "center",
    marginTop: "35px",
  },
  button: {
    padding: "6px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    textTransform: "none",
    color: "#FFFFFF",
    background: theme.palette.accentColor.main + " !important",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
    width: "100%",
    "&.MuiButton-contained.Mui-disabled": {
      background: theme.palette.button + " !important",
      color: "#FFFFFF !important",
    },
    "@media (max-width: 1330px)": {
      fontSize: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "40px !important",
      fontSize: "15px !important",
    },
    "@media (min-width: 1920px)": {
      fontSize: "23px !important",
      height: "45px !important",
    },
    "@media (min-width: 3072px)": {
      fontSize: "29px !important",
      height: "70px !important",
    },
  },
  fieldStyle: {
    width: "100%",
    "& label.Mui-focused": {
      color: theme.palette.accentColor.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.accentColor.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.accentColor.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.accentColor.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.accentColor.main,
      },
    },
    "& .MuiInputLabel-root": {
      fontSize: "15px",
    },
    "& .MuiInputBase-root": {
      fontSize: "15px",
    },
    "@media (max-width: 1330px)": {
      "& .MuiInputLabel-root": {
        fontSize: "10px",
        transform: "translate(14px, 8px) scale(1)",
      },
      "& .MuiInputLabel-shrink": {
        transform: "translate(14px, -7px) scale(0.75)",
      },
      "& .MuiInputBase-root": {
        fontSize: "10px",
        height: "30px",
      },
    },
  },
  forgotlink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  forgot: {
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  formContainer: {
    justifyContent: "center",
  },
  message: {
    fontFamily: `var(--font)`,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    textAlign: "center",
    color: "#424242",
    textTransform: "uppercase",
    "@media (max-width: 1330px)": {
      fontSize: "10px",
    },
    "@media (min-width: 1920px)": {
      fontSize: "18px",
    },
    "@media (min-width: 3072px)": {
      fontSize: "24px !important",
    },
  },
  linkColor: {
    fontSize: "14px",
    color: "#3A7AC5",
    textDecoration: "none",
    boxShadow: "none",
    background: "transparent !important",
    border: "none",
    textTransform: "capitalize",
    padding: "0px",
    "&:hover": {
      textDecoration: "none",
      boxShadow: "none",
      background: "transparent",
      border: "none",
    },
  },
  timeRemain: {
    color: "#999999",
    fontSize: "14px",
  },
  fieldTop: {
    paddingTop: "10px",
    "@media (min-width: 1920px)": {
      paddingTop: "24px !important",
    },
    "@media (min-width: 3072px)": {
      paddingTop: "31px !important",
    },
  },
}));

export default useStyles;
