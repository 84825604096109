module.exports = global.config = {
    // development
    // APP_URL: 'http://192.168.1.16:3001/',
    // API_URL: 'http://192.168.1.16:6001/',

    // staging
    // APP_URL: 'https://dealpopup.ahsan.cloud/',
    // ADMIN_APP_URL: 'https://dealpopupadmin.ahsan.cloud/',
    // API_URL: 'https://dealpopupservice.ahsan.cloud/'

    // production
    APP_URL: 'https://dealpopup.com/',
    ADMIN_APP_URL: 'https://portal.dealpopup.com/',
    API_URL: 'https://services.dealpopup.com/'
};



/* Notes */

// icon color #495057 