/*
 *
 * Session actions
 *
 */

import {
  VERIFY_SESSION,
  VERIFY_SESSION_SUCCESS,
  VERIFY_SESSION_ERROR,
  LOG_IN,
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  LOG_OUT,
  LOG_OUT_SUCCESS,
  LOG_OUT_ERROR,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CLEAR_CACHE,
  DETECT_MOBILE_DEVICE,
  OTP_VERIFICATION,
  OTP_VERIFICATION_SUCCESS,
  OTP_VERIFICATION_ERROR,
  SEND_OTP,
  SEND_OTP_SUCCESS,
  SEND_OTP_ERROR,
  SESSION_CACHE_HIT,
  GEO_LOCATION,
  GEO_LOCATION_SUCCESS,
  GEO_LOCATION_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESEND_OTP,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_ERROR,
  VALIDATE_UPDATE_USER_PROFILE,
  VALIDATE_UPDATE_USER_PROFILE_SUCCESS,
  VALIDATE_UPDATE_USER_PROFILE_ERROR,
  VERIFY_USER_OTP,
  VERIFY_USER_OTP_SUCCESS,
  VERIFY_USER_OTP_ERROR,
  GET_ALL_BANNER,
  GET_ALL_BANNER_SUCCESS,
  GET_ALL_BANNER_ERROR,
  UPLOAD_USER_PROFILE,
  UPLOAD_USER_PROFILE_SUCCESS,
  UPLOAD_USER_PROFILE_ERROR,
  POST_CATEGORIES,
  POST_CATEGORIES_SUCCESS,
  POST_CATEGORIES_ERROR,
} from "./constants";

/**
 * @param {string} secret
 */
export function verifySession(secret) {
  return {
    type: VERIFY_SESSION,
    secret,
  };
}

/**
 * @param {object} user
 */
export function verifySessionSuccess(user) {
  return {
    type: VERIFY_SESSION_SUCCESS,
    user,
  };
}

/**
 * @param {string} error
 */
export function verifySessionError(error) {
  return {
    type: VERIFY_SESSION_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {function} closeModal
 * @param {string} form
 * @param {object} customObj
 * @returns
 */
export function logIn(record, closeModal, form, customObj) {
  return {
    type: LOG_IN,
    record,
    closeModal,
    form,
    customObj,
  };
}

/**
 * @param {object} user
 * @param {string} token
 */
export function logInSuccess(user, token) {
  return {
    type: LOG_IN_SUCCESS,
    user,
    token,
  };
}

/**
 * @param {string} error
 */
export function logInError(error) {
  return {
    type: LOG_IN_ERROR,
    error,
  };
}

export function logOut() {
  return {
    type: LOG_OUT,
  };
}

export function logOutSuccess() {
  return {
    type: LOG_OUT_SUCCESS,
  };
}

/**
 * @param {string} error
 */
export function logOutError(error) {
  return {
    type: LOG_OUT_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {function} closeModal
 * @param {string} form
 * @returns
 */
export function signUp(record, closeModal, form) {
  return {
    type: SIGN_UP,
    record,
    closeModal,
    form,
  };
}

/**
 * @param {string} token
 */
export function signUpSuccess(token) {
  return {
    type: SIGN_UP_SUCCESS,
    token,
  };
}

/**
 * @param {string} error
 */
export function signUpError(error) {
  return {
    type: SIGN_UP_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {function} setSubmitting
 * @param {string} form
 * @returns
 */
export function forgotPassword(record, closeModal, form) {
  return {
    type: FORGOT_PASSWORD,
    record,
    closeModal,
    form,
  };
}

/**
 * @param {string} success
 */
export function forgotPasswordSuccess(success) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    success,
  };
}

/**
 * @param {string} error
 */
export function forgotPasswordError(error) {
  return {
    type: FORGOT_PASSWORD_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {function} setLoader
 * @param {string} form
 * @returns
 */
export function changePassword(record, setLoader, form) {
  return {
    type: CHANGE_PASSWORD,
    record,
    setLoader,
    form,
  };
}

/**
 * @param {string} success
 */
export function changePasswordSuccess(success) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    success,
  };
}

/**
 * @param {string} error
 */
export function changePasswordError(error) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    error,
  };
}

export function clearCache() {
  return {
    type: CLEAR_CACHE,
  };
}

/**
 *
 * @param {boolean} isMobile
 * @returns
 */
export function detectMobileDevice(isMobile) {
  return {
    type: DETECT_MOBILE_DEVICE,
    isMobile,
  };
}

/**
 *
 * @param {object} record
 * @param {function} closeModal
 * @param {string} form
 * @returns
 */
export function otpVerification(record, closeModal, form) {
  return {
    type: OTP_VERIFICATION,
    record,
    closeModal,
    form,
  };
}

/**
 *
 * @param {string} token
 * @param {string} success
 * @returns
 */
export function otpVerificationSuccess(token, success) {
  return {
    type: OTP_VERIFICATION_SUCCESS,
    token,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function otpVerificationError(error) {
  return {
    type: OTP_VERIFICATION_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function sendOtp(record, onError) {
  return {
    type: SEND_OTP,
    record,
    onError
  };
}

/**
 *
 * @param {string} success
 * @returns
 */
export function sendOtpSuccess(success) {
  return {
    type: SEND_OTP_SUCCESS,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function sendOtpError(error) {
  return {
    type: SEND_OTP_ERROR,
    error,
  };
}

export function sessionCacheHit() {
  return {
    type: SESSION_CACHE_HIT,
  };
}

/**
 *
 * @param {object} record
 * @param {function} closeModal
 * @param {function} setBtnLoader
 * @param {object} clearState
 * @returns
 */
export function geoLocation(record, closeModal, setBtnLoader, clearState) {
  return {
    type: GEO_LOCATION,
    record,
    closeModal,
    setBtnLoader,
    clearState,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function geoLocationSuccess(record) {
  return {
    type: GEO_LOCATION_SUCCESS,
    record,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function geoLocationError(error) {
  return {
    type: GEO_LOCATION_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {function} setShowForm
 * @param {string} form
 * @returns
 */
export function updateUserProfile(record, setShowForm, form) {
  return {
    type: UPDATE_USER_PROFILE,
    record,
    setShowForm,
    form,
  };
}

/**
 *
 * @param {object} user
 * @param {string} success
 * @returns
 */
export function updateUserProfileSuccess(user, success) {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    user,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function updateUserProfileError(error) {
  return {
    type: UPDATE_USER_PROFILE_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {function} closeModal
 * @param {string} form
 * @returns
 */
export function resetPassword(record, closeModal, form) {
  return {
    type: RESET_PASSWORD,
    record,
    closeModal,
    form,
  };
}

/**
 *
 * @param {string} success
 * @returns
 */
export function resetPasswordSuccess(success) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function resetPasswordError(error) {
  return {
    type: RESET_PASSWORD_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function resendOtp(record) {
  return {
    type: RESEND_OTP,
    record,
  };
}

/**
 *
 * @param {string} success
 * @returns
 */
export function resendOtpSuccess(success) {
  return {
    type: RESEND_OTP_SUCCESS,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function resendOtpError(error) {
  return {
    type: RESEND_OTP_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {function} setShowForm
 * @param {string} form
 * @param {function} showPopup
 * @returns
 */
export function validateUserProfile(record, setShowForm, form, showPopup) {
  return {
    type: VALIDATE_UPDATE_USER_PROFILE,
    record,
    setShowForm,
    form,
    showPopup,
  };
}

/**
 *
 * @param {string} success
 * @returns
 */
export function validateUserProfileSuccess(success) {
  return {
    type: VALIDATE_UPDATE_USER_PROFILE_SUCCESS,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function validateUserProfileError(error) {
  return {
    type: VALIDATE_UPDATE_USER_PROFILE_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {function} setShowPopup
 * @param {function} setShowForm
 * @param {string} form
 * @returns
 */
export function verifyUserOtp(record, setShowPopup, setShowForm, form) {
  return {
    type: VERIFY_USER_OTP,
    record,
    setShowPopup,
    setShowForm,
    form,
  };
}

/**
 *
 * @param {string} success
 * @returns
 */
export function verifyUserOtpSuccess(success) {
  return {
    type: VERIFY_USER_OTP_SUCCESS,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function verifyUserOtpError(error) {
  return {
    type: VERIFY_USER_OTP_ERROR,
    error,
  };
}

/**
 *
 * @param {boolean} load
 * @returns
 */
export function getAllBanner(load) {
  return {
    type: GET_ALL_BANNER,
    load,
  };
}

/**
 *
 * @param {Array} records
 * @returns
 */
export function getAllBannerSuccess(records) {
  return {
    type: GET_ALL_BANNER_SUCCESS,
    records,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function getAllBannerError(error) {
  return {
    type: GET_ALL_BANNER_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function uploadUserProfile(record) {
  return {
    type: UPLOAD_USER_PROFILE,
    record,
  };
}

/**
 *
 * @param {object} user
 * @returns
 */
export function uploadUserProfileSuccess(user) {
  return {
    type: UPLOAD_USER_PROFILE_SUCCESS,
    user,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function uploadUserProfileError(error) {
  return {
    type: UPLOAD_USER_PROFILE_ERROR,
    error,
  };
}

/**
 *
 * @param {boolean} load
 * @returns
 */
export function postCategories(load) {
  return {
    type: POST_CATEGORIES,
    load,
  };
}

/**
 *
 * @param {Array} categoriesOptions
 * @param {Array} categories
 * @returns
 */
export function postCategoriesSuccess(categoriesOptions, categories) {
  return {
    type: POST_CATEGORIES_SUCCESS,
    categoriesOptions,
    categories,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function postCategoriesError(error) {
  return {
    type: POST_CATEGORIES_ERROR,
    error,
  };
}

const actions = {
  verifySession,
  verifySessionSuccess,
  verifySessionError,
  logIn,
  logInSuccess,
  logInError,
  signUp,
  signUpSuccess,
  signUpError,
  logOut,
  logOutSuccess,
  logOutError,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordError,
  changePassword,
  changePasswordSuccess,
  changePasswordError,
  clearCache,
  detectMobileDevice,
  otpVerification,
  otpVerificationSuccess,
  otpVerificationError,
  sendOtp,
  sendOtpSuccess,
  sendOtpError,
  sessionCacheHit,
  geoLocation,
  geoLocationSuccess,
  geoLocationError,
  updateUserProfile,
  updateUserProfileSuccess,
  updateUserProfileError,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordError,
  resendOtp,
  resendOtpSuccess,
  resendOtpError,
  validateUserProfile,
  validateUserProfileSuccess,
  validateUserProfileError,
  verifyUserOtp,
  verifyUserOtpSuccess,
  verifyUserOtpError,
  getAllBanner,
  getAllBannerSuccess,
  getAllBannerError,
  uploadUserProfile,
  uploadUserProfileSuccess,
  uploadUserProfileError,
  postCategories,
  postCategoriesSuccess,
  postCategoriesError,
};

export default actions;
