/**
 *
 *
 * Router Configure
 *
 *
 */

import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { detectMobile } from "utils/tools";
import {
  selectUser,
  selectLoggedIn,
  selectIsMobileDevice,
  selectBanner,
  selectLoadingBanner,
  selectCategoriesOptions,
  selectCategories,
} from "store/session/selectors";
import ProtectedRoute from "components/ProtectedRoute";
import Spinner from "components/Spinner";

import { detectMobileDevice, postCategories } from "store/session/actions";
import { selectFilterParams } from "store/products/selectors";

import schema from "schema";
import AppRoute from "./route";
import { ImplementationFor } from "./utils";
import store2 from "store2";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import actions from "store/session/actions";
import FloatingButton from 'components/FloatButton';
import FloatWhatsapp from 'components/FloatWhatsapp';

const router = (routerProps) =>
  createBrowserRouter(
    schema().routes.map((data) => {
      return {
        ...data,
        element:
          data.protectedRoute && routerProps?.loggedIn ? (
            <ProtectedRoute isLoggedIn={routerProps?.loggedIn}>
              <AppRoute {...data} {...routerProps} />
            </ProtectedRoute>
          ) : (
            <AppRoute {...data} {...routerProps} />
          ),
        children: data?.childRoutes?.map((child) => {
          const Component = ImplementationFor[child?.component];
          return {
            ...child,
            element: <Component {...child} {...routerProps} />,
          };
        }),
      };
    }),
  );

function Router(props) {
  const {
    dispatch,
    loggedIn,
    isMobile,
    banner,
    loadingBanner,
    categories,
    categoriesOptions,
    filterParams,
  } = props;
  const secret = store2.get("secret");
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [showBacktpTopBtn, setBacktoTopBtn] = useState(false);

  const handleDetectMobile = () => {
    dispatch(detectMobileDevice(detectMobile()));
  };

  const toggleVisibility = () => {
    if (window.pageYOffset > 700) {
      setBacktoTopBtn(true);
    } else {
      setBacktoTopBtn(false);
    }
  };

  useEffect(() => {
    handleDetectMobile();
    dispatch(postCategories(true));
    window.addEventListener("resize", handleDetectMobile);
    return () => window.removeEventListener("resize", handleDetectMobile);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.addEventListener("scroll", toggleVisibility);
  }, []);

  const handleLogout = () => {
    const authToken = store2.get("secret");
    if(!authToken){
      dispatch(actions.logOut());
    } 
  }

  // useEffect(() => {
  //   window.addEventListener('storage', handleLogout);
  //   return () => window.removeEventListener("storage", handleLogout);
  // }, [])

  const routerProps = {
    loggedIn,
    isMobile,
    banner,
    categories,
    categoriesOptions,
    loadingBanner,
    dispatch,
    filterParams,
  };

  return (
    <>
      {secret && !loggedIn ? (
        <Spinner style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "100vh" }} sm={sm} />
      ) : (
        <RouterProvider router={router(routerProps)} />
      )}
      {showBacktpTopBtn && (
        <FloatingButton
          type={"up_arrow"}
          position="left"
          handleClick={() => window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })}
        />
      )}
      <FloatWhatsapp />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  user: selectUser(),
  loggedIn: selectLoggedIn(),
  isMobile: selectIsMobileDevice(),
  banner: selectBanner(),
  loadingBanner: selectLoadingBanner(),
  categories: selectCategories(),
  categoriesOptions: selectCategoriesOptions(),
  filterParams: selectFilterParams(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);
