const containerStyles = {
  width: "100%",
  // height: "380px",
  margin: "0 auto",
};

const slideStyles = {
  width: "100%",
  // minHeight: "380px",
  backgroundSize: "cover",
  backgroundPosition: "top center",
};

const silderButton = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#E6F2EA",
  border: "none",
  borderRadius: "6px",
  width: "20px",
  height: "20px",
};

const rightArrowStyles = {
  ...silderButton,
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  right: "8px",
  fontSize: "45px",
  color: "#fff",
  zIndex: 1,
  cursor: "pointer",
};

const leftArrowStyles = {
  ...silderButton,
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  left: "8px",
  fontSize: "45px",
  color: "#fff",
  zIndex: 1,
  cursor: "pointer",
};

const sliderStyles = {
  position: "relative",
  height: "100%",
};

const dotsContainerStyles = {
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bottom: "8px",
  width: "100%"
};

const customDotsContainerStyles = {
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bottom: "1rem",
  width: "100%"
};

const dotStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  width: "100%",
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "20px",
};

const activeDot = {
  position: "absolute",
};

const mobileContainerStyles = {
  width: "100%",
  height: "100%",
  margin: "0 auto",
};

const mobileSlideStyles = {
  width: "100%",
  minHeight: "150px",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

const dotsChildGrid = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}

export {
  containerStyles,
  slideStyles,
  silderButton,
  rightArrowStyles,
  leftArrowStyles,
  sliderStyles,
  dotsContainerStyles,
  dotStyle,
  activeDot,
  mobileContainerStyles,
  mobileSlideStyles,
  customDotsContainerStyles,
  dotsChildGrid
};
