/**
 *
 *
 * Header Component
 *
 *
 */
import React, { useState, useEffect, memo, useCallback } from "react";
import Mobile from "./components/MobileView";
import Desktop from "./components/DesktopView";
import { Grid } from "@mui/material";
import ModalPopUp from "components/ModalPopUp";
import ModalLocation from "components/ModalLocation";
import Styles from "./styles";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import actions from "store/session/actions";

import {
  selectUser,
  selectLoggedIn,
  selectIsMobileDevice,
  selectBtnLoader,
  selectGeoLocation,
  selectCategories
} from "store/session/selectors";
import { activationCode } from "utils/tools";
import history from "utils/history";
import api from "helpers/api";
import { useNavigate } from "react-router-dom";
import { productFilterParams } from "store/products/actions";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import { decryption } from "cipher/cipher";

function Header(props) {
  const { isMobile, loggedIn, form, dispatch, user, btnLoader, geoLocation, categories } =
    props;

  const classes = Styles();
  const [resgiterPopup, setRegisterPopup] = useState(form);
  const [showMap, setShowMap] = useState(false);
  const activation_code = activationCode(history);
  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState([]);
  const [value, setValue] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const searchInput = localStorage.getItem("search");
  const location = useLocation();
  const breakpoints = 900;

  const handleModalFunc = (type) => {
    setRegisterPopup(type);
  };
  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
  };
  const sendOtp = useCallback(() => {
    dispatch(actions.sendOtp(Object.assign({}, { activation_code }), () => {
      navigate("/")
      window.location.reload()
    }));
  }, [resgiterPopup]);

  useEffect(() => {
    if (resgiterPopup && resgiterPopup === "verification") {
      sendOtp();
    }
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, []);

  useEffect(() => {
    if (
      searchInput &&
      location?.pathname &&
      location?.pathname.indexOf("search") > -1
    ) {
      setValue(searchInput);
    } else {
      setValue("");
      localStorage.removeItem("search");
    }
  }, [location?.pathname]);

  const fetchSuggestion = async (val) => {
    if (val && val !== "clear") {
      await api.get(`/rest/title-suggestion?search=${val}`).then(async (data) => {
        let decryptData = await decryption(data?.data);
        data.data = decryptData;
        let res = data?.data?.response;
        if (res?.length > 0) {
          const filterData = res.map(r =>
            Object.assign({}, r, { label: r.product_title }),
          );
          setSearchResult(filterData);
        } else {
          setSearchResult([]);
        }
      });
    } else {
      setSearchResult([]);
    }
  };

  useEffect(() => {
    optimizedFn(value);
  }, [value]);

  const handleSearchFunc = (data) => {
    const existingProduct = searchResult && Array.isArray(searchResult) && searchResult.length > 0 ? searchResult.find(_ => _.id === data) : false;
    if (existingProduct && existingProduct.id) {
      setSearchResult([]);
      navigate(`/productDetails/new_products/${existingProduct.id}`);
    } else if (data && data.trim()) {
      setSearchResult([]);
      localStorage.setItem("search", data);
      dispatch(productFilterParams(Object.assign({}, { query: data })));
      navigate(`/search?query=${encodeURIComponent(data)}`);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const optimizedFn = useCallback(
    debounce((val) => fetchSuggestion(val)),
    [],
  );

  const handleLogout = () => {
    dispatch(actions.logOut());
  };

  const handleReloadPage = () => {
    navigate("/");
    localStorage.removeItem("search");
    window.location.reload(true);
  };

  const handleClear = () => {
    setValue("clear");
  };

  const componentProps = {
    loggedIn,
    handleModalFunc,
    handleLogout,
    user,
    categories,
    setShowMap: setShowMap,
    geoLocation,
    handleSearchFunc,
    suggestionOptions: searchResult,
    setValue,
    value: value && (value === "clear" ? "" : value),
    handleReloadPage,
    handleClear,
    dispatch,
  };

  return (
    <>
      {isMobile || sm || width < breakpoints ? (
        <Mobile {...componentProps} />
      ) : (
        <Desktop {...componentProps} />
      )}
      <>
        <ModalPopUp
          formState={resgiterPopup}
          show={(resgiterPopup && true) || false}
          paperClassName={classes.modalPaper1}
          handleModalFunc={handleModalFunc}
          onClose={() => setRegisterPopup(false)}
          dispatch={dispatch}
          btnLoader={btnLoader}
        />
      </>
      {
        <ModalLocation
          title="User Geo Location"
          show={showMap}
          onClose={() => setShowMap(false)}
          dispatch={dispatch}
          geoLocation={geoLocation}
          loggedIn={loggedIn}
          user={user}
        />
      }
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  user: selectUser(),
  loggedIn: selectLoggedIn(),
  isMobile: selectIsMobileDevice(),
  btnLoader: selectBtnLoader(),
  geoLocation: selectGeoLocation(),
  categories: selectCategories()
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Header);
