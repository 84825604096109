/*
 *
 * products actions
 *
 */

import {
  LOAD_PRODUCTS_VALID_CACHE,
  LOAD_PRODUCTS,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_ERROR,
  LOAD_PRODUCT,
  LOAD_PRODUCT_SUCCESS,
  LOAD_PRODUCT_ERROR,
  SET_HEADERS_DATA,
  WISHLIST_PRODUCT,
  WISHLIST_PRODUCT_SUCCESS,
  WISHLIST_PRODUCT_ERROR,
  ADD_WISHLIST_PRODUCT,
  ADD_WISHLIST_PRODUCT_SUCCESS,
  ADD_WISHLIST_PRODUCT_ERROR,
  DELETE_WISHLIST_PRODUCT,
  DELETE_WISHLIST_PRODUCT_SUCCESS,
  DELETE_WISHLIST_PRODUCT_ERROR,
  CLEAR_PRODUCTS_RECORDS,
  REMOVE_WISHLIST_PRODUCT,
  REMOVE_WISHLIST_PRODUCT_SUCCESS,
  REMOVE_WISHLIST_PRODUCT_ERROR,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_ERROR,
  SEARCH_PRODUCTS_SUCCESS,
  CREATE_USER_COMMENT,
  CREATE_USER_COMMENT_ERROR,
  CREATE_USER_COMMENT_SUCCESS,
  PRODUCTS_DAY_FILTER,
  PRODUCTS_DAY_FILTER_SUCCESS,
  PRODUCTS_DAY_FILTER_ERROR,
  PRODUCT_FILTER,
  PRODUCT_FILTER_SUCCESS,
  PRODUCT_FILTER_ERROR,
  CREATE_PRODUCT_LIKE,
  CREATE_PRODUCT_LIKE_SUCCESS,
  CREATE_PRODUCT_LIKE_ERROR,
  CREATE_PRODUCT_UNLIKE,
  CREATE_PRODUCT_UNLIKE_SUCCESS,
  CREATE_PRODUCT_UNLIKE_ERROR,
  DELETE_USER_COMMENT,
  DELETE_USER_COMMENT_SUCCESS,
  DELETE_USER_COMMENT_ERROR,
  EDIT_USER_COMMENT,
  EDIT_USER_COMMENT_SUCCESS,
  EDIT_USER_COMMENT_ERROR,
  ADD_WISHLIST_PRODUCT_LIKE,
  ADD_WISHLIST_PRODUCT_UNLIKE,
  ADD_WISHLIST_RELATED_PRODUCT,
  ADD_WISHLIST_RELATED_PRODUCT_SUCCESS,
  ADD_WISHLIST_RELATED_PRODUCT_ERROR,
  DELETE_WISHLIST_RELATED_PRODUCT,
  DELETE_WISHLIST_RELATED_PRODUCT_SUCCESS,
  DELETE_WISHLIST_RELATED_PRODUCT_ERROR,
  LOAD_BANNER,
  LOAD_BANNER_SUCCESS,
  LOAD_BANNER_ERROR,
  PRODUCT_FILTER_PARAMS,
  ADD_WISHLIST_FILTER_PAGE,
  ADD_WISHLIST_FILTER_PAGE_ERROR,
  ADD_WISHLIST_FILTER_PAGE_SUCCESS,
  DELETE_WISHLIST_FILTER_PAGE,
  DELETE_WISHLIST_FILTER_PAGE_SUCCESS,
  DELETE_WISHLIST_FILTER_PAGE_ERROR,
  CLICK_TRACKING,
  CLICK_TRACKING_SUCCESS,
  CLICK_TRACKING_ERROR,
  SHARE_TRACKING,
  SHARE_TRACKING_SUCCESS,
  SHARE_TRACKING_ERROR,
  CREATE_RELATED_PRODUCT_LIKE,
  CREATE_RELATED_PRODUCT_LIKE_SUCCESS,
  CREATE_RELATED_PRODUCT_LIKE_ERROR,
  CREATE_RELATED_PRODUCT_UNLIKE,
  CREATE_RELATED_PRODUCT_UNLIKE_SUCCESS,
  CREATE_RELATED_PRODUCT_UNLIKE_ERROR,
  CLEAR_SEARCH_RECORD,
} from "./constants";

export function loadProductsCacheHit() {
  return {
    type: LOAD_PRODUCTS_VALID_CACHE,
  };
}

/**
 * @param {boolean} load
 * @param {string} query
 * @param {boolean} intialLoad
 */
export function loadProducts(load, query, intialLoad) {
  return {
    type: LOAD_PRODUCTS,
    load,
    query,
    intialLoad,
  };
}

/**
 * @param {Array} records
 * @param {boolean} hasMore
 */
export function loadProductsSuccess(records, hasMore, totalItems) {
  return {
    type: LOAD_PRODUCTS_SUCCESS,
    records,
    hasMore,
    totalItems
  };
}

/**
 * @param {string} error
 */
export function loadProductsError(error) {
  return {
    type: LOAD_PRODUCTS_ERROR,
    error,
  };
}

/**
 * @param {object} record
 */
export function loadProduct(record, page) {
  return {
    type: LOAD_PRODUCT,
    record,
    page,
  };
}

/**
 * @param {object} record
 */
export function loadProductSuccess(record) {
  return {
    type: LOAD_PRODUCT_SUCCESS,
    record,
  };
}

/**
 * @param {string} error
 */
export function loadProductError(error) {
  return {
    type: LOAD_PRODUCT_ERROR,
    error,
  };
}

/**
 * @param {object} record
 */
export function setHeadersData(record) {
  return {
    type: SET_HEADERS_DATA,
    record,
  };
}

/**
 *
 * @param {boolean} load
 * @param {boolean} intialLoad
 * @returns
 */
export function wishListProduct(load, intialLoad) {
  return {
    type: WISHLIST_PRODUCT,
    load,
    intialLoad,
  };
}

/**
 *
 * @param {Array} records
 * @param {boolean} hasMore
 * @returns
 */
export function wishListProductSuccess(records, hasMore, totalItems) {
  return {
    type: WISHLIST_PRODUCT_SUCCESS,
    records,
    hasMore,
    totalItems
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function wishListProductError(error) {
  return {
    type: WISHLIST_PRODUCT_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function addWishListProduct(record) {
  return {
    type: ADD_WISHLIST_PRODUCT,
    record,
  };
}

/**
 *
 * @param {object} record
 * @param {string} success
 * @returns
 */
export function addWishListProductSuccess(record, success) {
  return {
    type: ADD_WISHLIST_PRODUCT_SUCCESS,
    record,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function addWishListProductError(error) {
  return {
    type: ADD_WISHLIST_PRODUCT_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function deleteWishListProduct(record) {
  return {
    type: DELETE_WISHLIST_PRODUCT,
    record,
  };
}

/**
 *
 * @param {object} record
 * @param {string} success
 * @returns
 */
export function deleteWishListProductSuccess(record, success) {
  return {
    type: DELETE_WISHLIST_PRODUCT_SUCCESS,
    record,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function deleteWishListProductError(error) {
  return {
    type: DELETE_WISHLIST_PRODUCT_ERROR,
    error,
  };
}

export function clearProductsRecords() {
  return {
    type: CLEAR_PRODUCTS_RECORDS,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function removeWishListProduct(record) {
  return {
    type: REMOVE_WISHLIST_PRODUCT,
    record,
  };
}

/**
 *
 * @param {object} record
 * @param {string} success
 * @returns
 */
export function removeWishListProductSuccess(record, success) {
  return {
    type: REMOVE_WISHLIST_PRODUCT_SUCCESS,
    record,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function removeWishListProductError(error) {
  return {
    type: REMOVE_WISHLIST_PRODUCT_ERROR,
    error,
  };
}

/**
 *
 * @param {object} query
 * @param {boolean} element
 * @returns
 */
export function searchProducts(query, element) {
  return {
    type: SEARCH_PRODUCTS,
    query,
    element,
  };
}

/**
 *
 * @param {Array} filterRecords
 * @param {Array} categoriesOptions
 * @param {Array} categories
 * @param {boolean} hasMore
 * @param {number} totalItems
 * @param {string} success
 * @returns
 */
export function searchProductsSuccess(
  filterRecords,
  hasMore,
  totalItems,
  success,
) {
  return {
    type: SEARCH_PRODUCTS_SUCCESS,
    filterRecords,
    hasMore,
    totalItems,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function searchProductsError(error) {
  return {
    type: SEARCH_PRODUCTS_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {function} setNewComment
 * @param {function} setLoader
 * @returns
 */
export function createUserComment(record, setNewComment, setLoader) {
  return {
    type: CREATE_USER_COMMENT,
    record,
    setNewComment,
    setLoader,
  };
}

/**
 *
 * @param {object} record
 * @param {string} success
 * @returns
 */
export function createUserCommentSuccess(record, success) {
  return {
    type: CREATE_USER_COMMENT_SUCCESS,
    record,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function createUserCommentError(error) {
  return {
    type: CREATE_USER_COMMENT_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {string} pageName
 * @returns
 */
export function productsDayFilter(record, pageName) {
  return {
    type: PRODUCTS_DAY_FILTER,
    record,
    pageName,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function productsDayFilterSuccess(record) {
  return {
    type: PRODUCTS_DAY_FILTER_SUCCESS,
    record,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function productsDayFilterError(error) {
  return {
    type: PRODUCTS_DAY_FILTER_ERROR,
    error,
  };
}

/**
 *
 * @param {string} productFilter
 * @returns
 */
export function productFilter(productFilter) {
  return {
    type: PRODUCT_FILTER,
    productFilter,
  };
}

/**
 *
 * @param {string} productFilter
 * @returns
 */
export function productFilterSuccess(productFilter) {
  return {
    type: PRODUCT_FILTER_SUCCESS,
    productFilter,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function productFilterError(error) {
  return {
    type: PRODUCT_FILTER_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {string} pageName
 * @returns
 */
export function createProductLike(record, pageName) {
  return {
    type: CREATE_PRODUCT_LIKE,
    record,
    pageName,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function createProductLikeSuccess(record, success) {
  return {
    type: CREATE_PRODUCT_LIKE_SUCCESS,
    record,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function createProductLikeError(error) {
  return {
    type: CREATE_PRODUCT_LIKE_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {string} pageName
 * @returns
 */
export function createProductUnLike(record, pageName) {
  return {
    type: CREATE_PRODUCT_UNLIKE,
    record,
    pageName,
  };
}

/**
 *
 * @param {object} record
 * @param {string} success
 * @returns
 */
export function createProductUnLikeSuccess(record, success) {
  return {
    type: CREATE_PRODUCT_UNLIKE_SUCCESS,
    record,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function createProductUnLikeError(error) {
  return {
    type: CREATE_PRODUCT_UNLIKE_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {function} setLoader
 * @returns
 */
export function deleteUserComment(record, setLoader) {
  return {
    type: DELETE_USER_COMMENT,
    record,
    setLoader,
  };
}

/**
 * @param {object} record
 * @param {string} success
 * @returns
 */
export function deleteUserCommentSuccess(record, success) {
  return {
    type: DELETE_USER_COMMENT_SUCCESS,
    record,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function deleteUserCommentError(error) {
  return {
    type: DELETE_USER_COMMENT_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {function} setEditedCmt
 * @param {function} setLoader
 * @param {function} setEditComment
 * @returns
 */

export function editUserComment(
  record,
  setEditedCmt,
  setLoader,
  setEditComment,
) {
  return {
    type: EDIT_USER_COMMENT,
    record,
    setEditedCmt,
    setLoader,
    setEditComment,
  };
}

/**
 *
 * @param {object} record
 * @param {string} success
 * @returns
 */
export function editUserCommentSuccess(record, success) {
  return {
    type: EDIT_USER_COMMENT_SUCCESS,
    record,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function editUserCommentError(error) {
  return {
    type: EDIT_USER_COMMENT_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {string} success
 * @returns
 */
export function addWishlistProductLike(record, success) {
  return {
    type: ADD_WISHLIST_PRODUCT_LIKE,
    record,
    success,
  };
}

/**
 *
 * @param {object} record
 * @param {string} success
 * @returns
 */
export function addWishlistProductUnLike(record, success) {
  return {
    type: ADD_WISHLIST_PRODUCT_UNLIKE,
    record,
    success,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function addWishListRelatedProduct(record) {
  return {
    type: ADD_WISHLIST_RELATED_PRODUCT,
    record,
  };
}

/**
 *
 * @param {object} record
 * @param {string} success
 * @returns
 */
export function addWishListRelatedProductSuccess(record, success) {
  return {
    type: ADD_WISHLIST_RELATED_PRODUCT_SUCCESS,
    record,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function addWishListRelatedProductError(error) {
  return {
    type: ADD_WISHLIST_RELATED_PRODUCT_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function deleteWishListRelatedProduct(record) {
  return {
    type: DELETE_WISHLIST_RELATED_PRODUCT,
    record,
  };
}

/**
 *
 * @param {object} record
 * @param {string} success
 * @returns
 */
export function deleteWishListRelatedProductSuccess(record, success) {
  return {
    type: DELETE_WISHLIST_RELATED_PRODUCT_SUCCESS,
    record,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function deleteWishListRelatedProductError(error) {
  return {
    type: DELETE_WISHLIST_RELATED_PRODUCT_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @param {object} geoLocation
 * @param {object} loggedIn
 * @returns
 */
export function loadBanner(record, geoLocation, loggedIn) {
  return {
    type: LOAD_BANNER,
    record,
    geoLocation,
    loggedIn,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function loadBannerSuccess(record) {
  return {
    type: LOAD_BANNER_SUCCESS,
    record,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function loadBannerError(error) {
  return {
    type: LOAD_BANNER_ERROR,
    error,
  };
}

/**
 *
 * @param {object} filterParams
 * @returns
 */
export function productFilterParams(filterParams) {
  return {
    type: PRODUCT_FILTER_PARAMS,
    filterParams,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function addWishListFilterPage(record) {
  return {
    type: ADD_WISHLIST_FILTER_PAGE,
    record,
  };
}

/**
 *
 * @param {object} filterRecords
 * @param {string} success
 * @returns
 */
export function addWishListFilterPageSuccess(filterRecords, success) {
  return {
    type: ADD_WISHLIST_FILTER_PAGE_SUCCESS,
    filterRecords,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function addWishListFilterPageError(error) {
  return {
    type: ADD_WISHLIST_FILTER_PAGE_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function deleteWishListFilterPage(record) {
  return {
    type: DELETE_WISHLIST_FILTER_PAGE,
    record,
  };
}

/**
 *
 * @param {object} filterRecords
 * @param {string} success
 * @returns
 */
export function deleteWishListFilterPageSuccess(filterRecords, success) {
  return {
    type: DELETE_WISHLIST_FILTER_PAGE_SUCCESS,
    filterRecords,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function deleteWishListFilterPageError(error) {
  return {
    type: DELETE_WISHLIST_FILTER_PAGE_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function clickTracking(record) {
  return {
    type: CLICK_TRACKING,
    record,
  };
}

/**
 *
 * @param {string} success
 * @returns
 */
export function clickTrackingSuccess(success) {
  return {
    type: CLICK_TRACKING_SUCCESS,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function clickTrackingError(error) {
  return {
    type: CLICK_TRACKING_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function shareTracking(record) {
  return {
    type: SHARE_TRACKING,
    record,
  };
}

/**
 *
 * @param {string} success
 * @returns
 */
export function shareTrackingSuccess(success) {
  return {
    type: SHARE_TRACKING_SUCCESS,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function shareTrackingError(error) {
  return {
    type: SHARE_TRACKING_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function createRelatedProductLike(record) {
  return {
    type: CREATE_RELATED_PRODUCT_LIKE,
    record,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function createRelatedProductLikeSuccess(record, success) {
  return {
    type: CREATE_RELATED_PRODUCT_LIKE_SUCCESS,
    record,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function createRelatedProductLikeError(error) {
  return {
    type: CREATE_RELATED_PRODUCT_LIKE_ERROR,
    error,
  };
}

/**
 *
 * @param {object} record
 * @returns
 */
export function createRelatedProductUnLike(record) {
  return {
    type: CREATE_RELATED_PRODUCT_UNLIKE,
    record,
  };
}

/**
 *
 * @param {object} record
 * @param {string} success
 * @returns
 */
export function createRelatedProductUnLikeSuccess(record, success) {
  return {
    type: CREATE_RELATED_PRODUCT_UNLIKE_SUCCESS,
    record,
    success,
  };
}

/**
 *
 * @param {string} error
 * @returns
 */
export function createRelatedProductUnLikeError(error) {
  return {
    type: CREATE_RELATED_PRODUCT_UNLIKE_ERROR,
    error,
  };
}

export function clearSearchRecord() {
  return {
    type: CLEAR_SEARCH_RECORD,
  };
}

const actions = {
  loadProductsCacheHit,
  loadProducts,
  loadProductsSuccess,
  loadProductsError,
  loadProduct,
  loadProductSuccess,
  loadProductError,
  setHeadersData,
  wishListProduct,
  wishListProductSuccess,
  wishListProductError,
  addWishListProduct,
  addWishListProductSuccess,
  addWishListProductError,
  deleteWishListProduct,
  deleteWishListProductSuccess,
  deleteWishListProductError,
  clearProductsRecords,
  removeWishListProduct,
  removeWishListProductSuccess,
  removeWishListProductError,
  searchProducts,
  searchProductsSuccess,
  searchProductsError,
  createUserComment,
  createUserCommentSuccess,
  createUserCommentError,
  productsDayFilter,
  productsDayFilterSuccess,
  productsDayFilterError,
  productFilter,
  productFilterSuccess,
  productFilterError,
  createProductLike,
  createProductLikeSuccess,
  createProductLikeError,
  createProductUnLike,
  createProductUnLikeSuccess,
  createProductUnLikeError,
  deleteUserComment,
  deleteUserCommentError,
  deleteUserCommentSuccess,
  editUserComment,
  editUserCommentSuccess,
  editUserCommentError,
  addWishlistProductLike,
  addWishlistProductUnLike,
  addWishListRelatedProduct,
  addWishListRelatedProductSuccess,
  addWishListRelatedProductError,
  deleteWishListRelatedProduct,
  deleteWishListRelatedProductSuccess,
  deleteWishListRelatedProductError,
  loadBanner,
  loadBannerSuccess,
  loadBannerError,
  productFilterParams,
  addWishListFilterPage,
  addWishListFilterPageSuccess,
  addWishListFilterPageError,
  deleteWishListFilterPage,
  deleteWishListFilterPageSuccess,
  deleteWishListFilterPageError,
  clickTracking,
  clickTrackingSuccess,
  clickTrackingError,
  shareTracking,
  shareTrackingSuccess,
  shareTrackingError,
  createRelatedProductLike,
  createRelatedProductLikeSuccess,
  createRelatedProductLikeError,
  createRelatedProductUnLike,
  createRelatedProductUnLikeSuccess,
  createRelatedProductUnLikeError,
  clearSearchRecord,
};

export default actions;
