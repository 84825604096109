/**
 *
 * Login Form
 *
 */

import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { Grid, Button, Typography } from "@mui/material";
import PasswordField1 from "components/PasswordField1";
import CheckboxField from "components/CheckboxField";
import Styles from "./styles";
import ButtonSpinner from "components/ButtonSpinner";
import InputField1 from "components/InputField1";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

/**
 *
 * @param {object} props
 * @returns
 */
function LoginForm(props) {
  const {
    handleSubmit,
    pristine,
    submitting,
    errorMessage,
    clearCache,
    destroy,
    locationState = {},
    iframe,
    actions,
    dispatch,
    title,
    separator,
    customNote,
    enableScroll,
    invalid,
    onSubmitClose,
    closeModal,
    progress,
    handleModalFunc,
    enableSubmitBtn,
  } = props;
  const classes = Styles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpoints = window.innerWidth > 1920;

  useEffect(() => {
    return () => destroy();
  }, []);

  return (
    <form onSubmit={handleSubmit} className={classes.form} noValidate>
      <Grid container className={classes.header} justify="space-between">
        <Grid item xs={12}>
          <Typography component="span" className={classes.title}>
            {"Sign in to continue"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        xs={sm ? 12 : 9}
        className={classes.body}
        justify="flex-end"
      >
        <Grid item xs={12} sm={10} md={10} lg={10} className={classes.fieldTop}>
          <Field
            name="username"
            className={classes.fieldStyle}
            label="Email/Phone number"
            component={InputField1}
            type="text"
            variant={"standard"}
            size={"small"}
            required
            autoFocus
            autoCompleteOff={true}
            logoType={"username"}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={10} lg={10} className={classes.fieldTop}>
          <Field
            name="password"
            className={classes.fieldStyle}
            label="Password"
            component={PasswordField1}
            type="text"
            size={"small"}
            logoType={"password"}
            autoCompleteOff={true}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.fieldTop}
          style={{ display: "flex", justifyContent: "center", textTransform: "none" }}
        >
          <Field
            name="remember_me"
            label="Remember me"
            component={CheckboxField}
            breakpoints={breakpoints}
          />
        </Grid>
        <Grid container justify="flex-end" className={classes.footer}>
          <Grid
            item
            xs={12}
            sm={10}
            md={10}
            lg={10}
            className={classes.btnStyle}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={!enableSubmitBtn && (pristine || submitting)}
              onClick={!invalid && onSubmitClose ? closeModal : null}
            >
              {((submitting || progress) && (
                <ButtonSpinner size={sm ? 25 : breakpoints ? 40 : 25} />
              )) ||
                "Sign in"}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={10} md={10} lg={10} className={classes.reset}>
          <Grid container wrap="nowrap">
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography component="span" className={classes.forgot}>
                Forgot your Password? click here to
                <span
                  className={classes.forgotLink}
                  onClick={() => handleModalFunc("forgot")}
                >
                  Reset password
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

const validate = (values) => {
  const errors = {};

  const requiredFields = ["username", "password"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  if (values.username && parseInt(values.username)) {
    let phoneno = /^\d{9}$/;

    if (!values?.username?.match(phoneno)) {
      errors.username = "Invalid Phone number";
    }

    if (values?.username?.length > 9) {
      errors.username = "Phone number must be 9 digits";
    }
  } else {
    if (
      values.username &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.username)
    ) {
      errors.username = "Invalid Email";
    }
  }

  if (values.password && values.password.length < 4) {
    errors.password = "Password must be at least 4 characters";
  }

  return errors;
};

export default reduxForm({
  form: "login",
  validate,
  touchOnChange: false,
})(LoginForm);
