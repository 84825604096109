/*
 *
 * Session reducer
 *
 */

import produce from "immer";

import {
  VERIFY_SESSION_SUCCESS,
  VERIFY_SESSION_ERROR,
  LOG_IN_SUCCESS,
  LOG_OUT_SUCCESS,
  LOG_IN_ERROR,
  LOG_OUT_ERROR,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_CACHE,
  DETECT_MOBILE_DEVICE,
  OTP_VERIFICATION_SUCCESS,
  OTP_VERIFICATION_ERROR,
  SEND_OTP,
  SEND_OTP_SUCCESS,
  SEND_OTP_ERROR,
  SESSION_CACHE_HIT,
  GEO_LOCATION_SUCCESS,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESEND_OTP,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_ERROR,
  VALIDATE_UPDATE_USER_PROFILE_ERROR,
  VERIFY_USER_OTP_ERROR,
  GET_ALL_BANNER,
  GET_ALL_BANNER_SUCCESS,
  GET_ALL_BANNER_ERROR,
  UPLOAD_USER_PROFILE_SUCCESS,
  UPLOAD_USER_PROFILE_ERROR,
  POST_CATEGORIES_SUCCESS,
  POST_CATEGORIES_ERROR,
} from "./constants";

const initialState = {
  loading: false,
  error: false,
  success: false,
  secret: false,
  otp_code: false,
  notification: [],
  version: "1.0",
  isMobile: false,
  btnLoader: false,
  banner: [],
  loadingBanner: false,
};

/**
 * @param {object} state
 * @param {object} action
 */
const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case VERIFY_SESSION_SUCCESS:
        draft.loggedIn = true;
        draft.user = action.user;
        draft.loading = false;
        break;
      case LOG_IN_SUCCESS:
      case SIGN_UP_SUCCESS:
        draft.loggedIn = false;
        draft.error = false;
        draft.success = action.success;
        draft.loading = false;
        break;
      case VERIFY_SESSION_ERROR:
      case LOG_IN_ERROR:
      case SIGN_UP_ERROR:
      case RESET_PASSWORD_ERROR:
        draft.loggedIn = false;
        draft.user = false;
        draft.secret = false;
        draft.error = action.error;
        draft.success = false;
        draft.loading = false;
        draft.btnLoader = false;
        break;
      case FORGOT_PASSWORD_ERROR:
      case CHANGE_PASSWORD_ERROR:
      case OTP_VERIFICATION_ERROR:
      case SEND_OTP_ERROR:
      case UPDATE_USER_PROFILE_ERROR:
      case RESEND_OTP_ERROR:
      case VERIFY_USER_OTP_ERROR:
      case VALIDATE_UPDATE_USER_PROFILE_ERROR:
      case UPLOAD_USER_PROFILE_ERROR:
        draft.error = action.error;
        draft.success = false;
        draft.loading = false;
        draft.btnLoader = false;
        break;
      case LOG_OUT_ERROR:
      case LOG_OUT_SUCCESS:
        draft.loggedIn = false;
        draft.user = false;
        draft.secret = false;
        draft.error = false;
        draft.success = false;
        draft.loading = false;
        break;
      case FORGOT_PASSWORD_SUCCESS:
      case CHANGE_PASSWORD_SUCCESS:
      case SEND_OTP_SUCCESS:
      case RESET_PASSWORD_SUCCESS:
      case RESEND_OTP_SUCCESS:
        draft.error = false;
        draft.success = action.success;
        draft.loading = false;
        draft.btnLoader = false;
        break;
      case CLEAR_CACHE:
        draft.error = false;
        draft.success = false;
        draft.loading = false;
        break;
      case DETECT_MOBILE_DEVICE:
        draft.isMobile = action.isMobile;
        break;
      case OTP_VERIFICATION_SUCCESS:
        draft.loggedIn = true;
        draft.error = false;
        draft.token = action.token;
        draft.success = action.success;
        draft.loading = false;
        break;
      case SESSION_CACHE_HIT:
        draft.error = false;
        draft.success = false;
        draft.loading = false;
        break;
      case GEO_LOCATION_SUCCESS:
        draft.geoLocation = action.record;
        draft.success = false;
        draft.loading = false;
        break;
      case UPDATE_USER_PROFILE_SUCCESS:
      case UPLOAD_USER_PROFILE_SUCCESS:
        draft.user = action.user;
        draft.loading = false;
        draft.success = action.success;
        break;
      case SEND_OTP:  
      case RESEND_OTP:
        draft.btnLoader = true;
        draft.success = false;
        draft.error = false;
        break;
      case GET_ALL_BANNER:
        draft.loadingBanner = true;
        break;
      case GET_ALL_BANNER_SUCCESS:
        draft.banner = action.records;
        draft.loadingBanner = false;
        break;
      case GET_ALL_BANNER_ERROR:
        draft.loadingBanner = false;
        break;
      case POST_CATEGORIES_SUCCESS:
        draft.categoriesOptions = action.categoriesOptions;
        draft.categories = action.categories;
        break;
      case POST_CATEGORIES_ERROR:
        draft.error = action.error;
        break;
      default:
        break;
    }
  });

export default appReducer;
