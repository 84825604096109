/***
 *
 *
 * App Schema
 *
 *
 */

function schema(props) {
  const homePage = {
    name: "home",
    path: "/",
    title: "Trending Page",
    slider: true,
    fields: [],
    component: "Home",
    saga: "products",
    reducer: "products",
    visible: true,
    showCategories: true,
    showFilter: true,
  };

  const productDetails = {
    name: "productDetails",
    path: "productDetails/:type/:id",
    title: "Product Details",
    component: "ProductDetails",
    showCategories: true,
    showFilter: true,
  };

  const productFilter = {
    name: "search",
    path: "search",
    slider: false,
    component: "ProductFilter",
    showCategories: true,
    showFilter: false,
  };

  const profilePage = {
    name: "profile",
    path: "/profile",
    title: "Profile Page",
    slider: false,
    protectedRoute: true,
    fields: [
      {
        id: 1,
        value: "id",
        label: "#",
        editRecord: false,
        viewRecord: true,
        type: "text",
      },
      {
        id: 2,
        value: "name",
        label: "Name",
        editRecord: true,
        viewRecord: true,
        type: "text",
      },
      {
        id: 3,
        value: "email",
        label: "Email",
        editRecord: true,
        viewRecord: true,
        type: "email",
      },
      {
        id: 4,
        value: "birthday",
        label: "Birthday",
        editRecord: true,
        viewRecord: true,
        type: "date",
      },
      {
        id: 5,
        value: "location",
        label: "Location",
        editRecord: true,
        viewRecord: true,
        type: "location",
      },
      {
        id: 6,
        value: "phone",
        label: "Phone No",
        editRecord: true,
        viewRecord: true,
        prefix: '+971',
        formatValues: (val) => val.replace(/(\d{2})(\d{2})(\d{2})(\d{3})/, '$1 $2 $3 $4'),
        type: "phone",
      },
    ],
    component: "Profile",
    saga: "profile",
    reducer: "profile",
    visible: true,
    childRoutes: [
      {
        name: "view.profile",
        path: "viewProfile",
        title: "Profile Page",
        slider: false,
        fields: [
          {
            id: 1,
            value: "id",
            label: "#",
            editRecord: false,
            viewRecord: true,
            type: "text",
          },
          {
            id: 2,
            value: "name",
            label: "Name",
            editRecord: true,
            viewRecord: true,
            type: "input",
          },
          {
            id: 3,
            value: "email",
            label: "Email",
            editRecord: true,
            viewRecord: true,
            mandatory: false,
            type: "input",
          },
          {
            id: 4,
            value: "dob",
            label: "Birthday",
            editRecord: true,
            viewRecord: true,
            type: "date",
          },
          {
            id: 5,
            value: "address",
            label: "Address",
            editRecord: true,
            viewRecord: true,
            type: "input",
          },
          {
            id: 6,
            value: "phone",
            label: "Phone",
            editRecord: true,
            viewRecord: true,
            mandatory: false,
            prefix: '+971',
            formatValues: (val) => val.replace(/(\d{2})(\d{2})(\d{2})(\d{3})/, '$1 $2 $3 $4'),
            type: "phone",
          }
        ],
        component: "ViewProfile",
        saga: "profile",
        reducer: "profile",
        visible: true,
      },
      {
        name: "view.wishList",
        path: "myWishlist",
        title: "Profile Page",
        slider: false,
        fields: [],
        component: "WishList",
        saga: "profile",
        reducer: "profile",
        visible: true,
      },
      {
        name: "changePassword",
        path: "changePassword",
        title: "Profile Page",
        slider: false,
        fields: [
          {
            id: 1,
            value: "id",
            label: "#",
            editRecord: false,
            viewRecord: true,
            type: "text",
          },
          {
            id: 2,
            value: "name",
            label: "Name",
            editRecord: true,
            viewRecord: true,
            type: "text",
          },
          {
            id: 3,
            value: "email",
            label: "Email",
            editRecord: true,
            viewRecord: true,
            type: "email",
          },
        ],
        component: "ChangePassword",
        saga: "profile",
        reducer: "profile",
        visible: true,
      },
    ],
  };

  const contactPage = {
    name: "contact",
    path: "/contact",
    title: "Contact Page",
    slider: false,
    fields: [
      {
        id: 1,
        value: "id",
        label: "#",
        editRecord: false,
        viewRecord: true,
        type: "text",
      },
      {
        id: 2,
        value: "name",
        label: "Name",
        editRecord: true,
        viewRecord: true,
        type: "text",
      },
      {
        id: 3,
        value: "email",
        label: "Email",
        editRecord: true,
        viewRecord: true,
        type: "email",
      },
      {
        id: 4,
        value: "phone",
        label: "Phone Number",
        editRecord: true,
        viewRecord: true,
        prefix: "+971",
        formatValues: (val) => val.replace(/(\d{2})(\d{2})(\d{2})(\d{3})/, '$1 $2 $3 $4'),
        type: "phone",
      },
      {
        id: 5,
        value: "subject",
        label: "Subject",
        editRecord: true,
        viewRecord: true,
        type: "input",
      },
      {
        id: 6,
        value: "message",
        label: "Message",
        editRecord: true,
        viewRecord: true,
        type: "textarea",
      },
    ],
    component: "Contact",
    saga: "contact",
    reducer: "contact",
    visible: true,
  };

  const notFound = {
    path: "*",
    component: "NotFound",
  };

  const users = () => {
    return {
      signInColumns: [
        {
          id: 1,
          value: "name",
          label: "Email/Phone number",
          editRecord: false,
          viewRecord: false,
          style: { width: "100%" },
          type: "input",
        },
        {
          id: 2,
          value: "password",
          label: "Your Password",
          editRecord: true,
          viewRecord: false,
          style: { width: "100%" },
          type: "password",
        },
      ],
    };
  };

  const verificationPage = {
    name: "verification",
    path: "/verify/user",
    title: "Trending Page",
    slider: true,
    fields: [],
    form: "verification",
    component: "Home",
    showFilter: true,
    visible: true,
  };

  const resetPasswordPage = {
    name: "resetPassword",
    path: "/reset-password",
    title: "Trending Page",
    slider: true,
    fields: [],
    form: "reset",
    component: "Home",
    visible: true,
  };

  const AboutPage = {
    name: "about",
    path: "/about",
    title: "About Us",
    fields: [],
    component: "About",
    visible: true,
  };


  const FAQPage = {
    name: "faq",
    path: "/fqa",
    title: "FAQ",
    fields: [],
    component: "FAQ",
    visible: true,
  };


  const PolicyPage = {
    name: "policy",
    path: "/policy",
    title: "Policy Us",
    fields: [],
    component: "Policy",
    visible: true,
  };


  const DisclaimerPage = {
    name: "disclaimer",
    path: "/disclaimer",
    title: "Disclaimer",
    fields: [],
    component: "Disclaimer",
    visible: true,
  };

  const filterOptions = {
    productCategoryFilter: [
      {
        label: "Trending",
        value: "trending_products",
      },
      {
        label: "New",
        value: "new_products",
      },
    ],
    productFilter: [
      {
        id: 1,
        label: "Most recent",
        value: "most_recent",
      },
      {
        id: 2,
        label: "Price high to low",
        value: "price_high_to_low",
      },
      {
        id: 3,
        label: "Price low to high",
        value: "price_low_to_high",
      },
    ],
    productDetail: [
      {
        label: "Description",
        value: "description",
      },
      {
        label: "Specifications",
        value: "specifications",
      },
    ],
    productFilterPage: [
      {
        id: 1,
        label: "Most recent",
        value: "most_recent",
      },
      {
        id: 2,
        label: "Price high to low",
        value: "price_high_to_low",
      },
      {
        id: 3,
        label: "Price low to high",
        value: "price_low_to_high",
      }
    ],
  };

  const routes = [
    homePage,
    profilePage,
    contactPage,
    notFound,
    productFilter,
    verificationPage,
    resetPasswordPage,
    productDetails,
    AboutPage,
    FAQPage,
    PolicyPage,
    DisclaimerPage
  ];

  return {
    routes,
    users,
    filterOptions,
  };
}

export default schema;
