/***
 *
 * Switch Field
 *
 */

import React from "react";
import { Switch, FormControlLabel, FormGroup } from "@mui/material";
import Styles from "./styles";

export default function ({
  input,
  label,
  style,
  errorStyle,
  meta: { touched, error, warning },
}) {
  const { value, onChange } = input;
  const classes = Styles();

  return (
    <div style={style || {}}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={value || false}
              onChange={(e) => onChange(e.target.checked)}
              color="primary"
            />
          }
          label={label}
        />
      </FormGroup>
      <div style={errorStyle || {}} className={classes.error}>
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
}
