import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Styles from "./styles";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getOffset } from "utils/tools";
import useSmoothHorizontalScroll from "hooks/useSmoothHorizontalScroll";
import AllCategories from "./AllCategories";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Transition } from "react-transition-group";

// Common icon
import humburgerMenu from '../../assets/CommonIcons/MenuIcon.png';
import closeIcon from '../../assets/CommonIcons/cross-small.png';

function Categories(props) {
  const { categoriesOptions, categorieRecords, dispatch, filterParams } = props;
  const classes = Styles();
  const [margin, setMargin] = useState(0);
  const [categorieAllWidth, setCategoriesWidth] = useState(0);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  // creating md
  const md = useMediaQuery(theme.breakpoints.between("sm", "md"))

  const navigate = useNavigate();
  const {
    scrollContainerRef,
    scrollTo,
    handleContainerScroll,
    isAtStart,
    isAtEnd,
  } = useSmoothHorizontalScroll();

  const [openCategory, setOpenCategory] = useState(false);
  const [subContainerEntries, setSubContainerEntries] = useState(false);

  const handleClickListItem = () => {
    setOpenCategory(!openCategory);
    if (openCategory) {
      setSubContainerEntries(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setMargin(getOffset("header"));
    });
  }, [categoriesOptions]);

  useEffect(() => {
    setMargin(getOffset("header"));
  }, [margin]);

  useEffect(() => {
    if (!isAtStart && mdDown) {
      setCategoriesWidth(getWidth());
    }
  }, [isAtStart]);

  const handleScroll = (e, val) => {
    e.stopPropagation();
    scrollTo(val);
  };

  const handleFilterFunc = (data) => {
    if (data && data.id) {
      const qUrl = Object.assign({}, filterParams, { query: false });
      let filterData = Object.assign({}, qUrl, { category: data.id });
      navigate(`/search?category=${data.id}`);
    }
  };

  const getWidth = () => {
    let div =
      document.getElementById("allCategories") &&
      document.getElementById("allCategories").offsetWidth;

    return div || 0;
  };
  if (categoriesOptions.length === 0) {
    return null;
  }

  const calculatedMarginTop = () => {
    if (md) {
      return '73px';
    }
    if (margin > 0) {
      if (sm) {
        return '79px';
      }
      return `${margin - theme.mixins.toolbar.minHeight - 6}px`;
    }
    return `${margin}px`;
  };

  return (
    <>
      <Grid
        container
        xs={12}
        item
        ref={scrollContainerRef}
        onScroll={handleContainerScroll}
        className={classes.container}
        id="categorieContainer"
        style={{
          marginTop: calculatedMarginTop()
        }}
      // style={{
      //   marginTop:
      //     // margin > 0 && mdDown ? '72px' : null
      //     margin > 0 && sm
      //       ? /* margin - theme.mixins.toolbar.minHeight */ '79px'
      //       : margin > 0
      //         ? margin - theme.mixins.toolbar.minHeight - 6
      //         : margin,
      // }}
      >
        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <Grid
            item
            className={classes.categorieAll}
            onClick={handleClickListItem}
            id="allCategories"
          >
            {openCategory ? (
              // <CloseIcon className={classes.categorieArrow} />
              <img src={closeIcon} className={classes.categorieArrow} alt="close" />

            ) : (
              // <MenuIcon className={classes.categorieArrow} />
              <img src={humburgerMenu} className={classes.categorieArrow} alt="menu" />
            )}
            <Typography className={classes.categorieAllText}>
              {" "}
              {!sm && "All"}
            </Typography>
          </Grid>
          <Grid item className={classes.arrowItem}>
            {!isAtStart && !sm ? (
              <button
                onClick={(e) => handleScroll(e, -480)}
                className={classes.letfArrow}
                style={{ left: "95px" }}
              >
                <ArrowBackIosNewIcon className={classes.arrow} />
              </button>
            ) : null}
          </Grid>

          <Grid
            item
            md={11}
            xs={10}
            className={classes.categorieItem}
            id="categorieItems"
            style={!sm ? { position: "relative", left: "95px" } : {}}
          >
            {categoriesOptions?.map((e, i) => {
              return (
                <div
                  key={i}
                  className={classes.link}
                  onClick={() => handleFilterFunc(e)}
                >
                  {e.category_name}
                </div>
              );
            })}
          </Grid>
          <Grid item className={classes.arrowItem}>
            {!isAtEnd && !(scrollContainerRef.current?.scrollWidth === scrollContainerRef.current?.offsetWidth) && !sm ? (
              <button
                className={classes.rightArrow}
                onClick={(e) => handleScroll(e, 480)}
              >
                <ArrowForwardIosIcon className={classes.arrow} />
              </button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Transition in={openCategory} timeout={200} mountOnEnter unmountOnExit>
        {(state) => {
          return (
            <div
              className="all_category"
              onClick={() => {
                setOpenCategory(false);
                setSubContainerEntries(false);
              }}
            >
              <AllCategories
                style={{ height: "100%" }}
                categories={categoriesOptions}
                handleTreeSearch={handleFilterFunc}
                handleClose={() => {
                  setOpenCategory(false);
                  setSubContainerEntries(false);
                }}
                state={state}
                setSubContainerEntries={setSubContainerEntries}
                subContainerEntries={subContainerEntries}
              />
            </div>
          );
        }}
      </Transition>
    </>
  );
}

export default Categories;
