import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    outline: "none",
    borderRadius: "20px",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      borderRadius: "0px",
      height: "100%",
    },
  },
  body: {
    marginTop: "25px",
    marginBottom: "25px",
  },
  body1: {
    marginTop: "15px",
    marginBottom: "25px",
  },
  footer: {
    justifyContent: "center",
  },
  message: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    textAlign: "center",
    color: "#999999",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  messageRegular: {
    fontSize: "16px",
    paddingTop: "18px",
  },
  closeIcon: {
    cursor: "pointer",
    color: "#FFFFFF",
    "@media (max-width: 1330px)": {
      width: "20px",
      height: "20px",
    },
  },
  messageGrid: {
    padding: "10px",
    textAlign: "center",
  },
  error: {
    marginTop: "10px",
  },
  note: {
    fontSize: "16px",
  },
  details: {
    position: "abosulte",
    padding: "10px",
    whiteSpace: "pre-wrap",
    borderRadius: "0px 15px 15px 0px",
    backgroundImage: `url(${require("assets/images/login_design2.jpeg")})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    display: "grid",
    [theme.breakpoints.down("md")]: {
      borderRadius: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "280px",
      // backgroundImage: `linear-gradient(161.06deg, #0DCA78 4.71%, #232E90 107.97%)`,
    },
  },
  fieldStyle: {
    width: "100%",
  },
  greeting: {
    fontFamily: `var(--font)`,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    textAlign: "center",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      fontSize: "17px !important",
    },
    "@media (max-width: 1330px)": {
      fontSize: "13px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px !important",
    },
    "@media (min-width: 1920px)": {
      fontSize: "28px",
    },
    "@media (min-width: 3072px)": {
      fontSize: "35px",
    },
  },
  subGreeting: {
    fontFamily: `var(--font)`,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    textAlign: "center",
    color: "#FFFFFF",
    letterSpacing: "0px",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px !important",
    },
    "@media (max-width: 1330px)": {
      fontSize: "10px",
    },
    "@media (min-width: 1920px)": {
      fontSize: "21px !important",
    },
    "@media (min-width: 3072px)": {
      fontSize: "26px !important",
    },
  },
  lineSeparator: {
    height: "1px",
    background: theme.palette.accentColor.main,
    margin: "10px 0px",
    border: "1px solid #FFFFFF",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0px",
    },
    "@media (max-width: 1330px)": {
      fontSize: "12px",
    },
  },
  notes: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    textAlign: "center",
    color: "#F1FFF9",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
    "@media (max-width: 1330px)": {
      fontSize: "12px",
    },
  },
  container: {
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      maxHeight: "100%",
      overflowX: "hidden",
      overflowY: "auto",
    },
  },
  options: {
    border: "1px solid #FFFFFF",
    width: "55%",
    color: "#FFFFFF",
  },
  greetingDiv: {
    textAlign: "center",
    paddingTop: "25px",
    paddingBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "18px",
      paddingBottom: "0x",
    },
  },
  greetingBtn: {
    color: "#FFFFFF",
    outline: "none",
    fontSize: "12px",
    fontStyle: "normal",
    fontFamily: "Poppins",
    fontWeight: 500,
    textTransform: "none",
    borderColor: "#FFFFFF",
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "50% !important",
    },
    "&.MuiButtonBase-root": {
      outline: "none",
      borderColor: "#FFFFFF",
    },
    "@media (max-width: 1330px)": {
      fontSize: "11px",
    },
  },
  boxStyle2: {
    padding: "0 20px",
    [theme.breakpoints.down("md")]: {
      padding: "20px 30px !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 1330px)": {
      // paddingTop: "50px",
      // padding: "70px 20px",
    },
  },
  boxStyle: {
    padding: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "15px 20px !important",
    },
    "@media (max-width: 1330px)": {
      padding: "15px",
    },
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  copyRights: {
    fontFamily: `var(--font)`,
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px",
    "@media (min-width: 1920px)": {
      fontSize: "20px !important",
      marginTop: "40px !important",
      marginBottom: "20px",
    },
    "@media (min-width: 3072px)": {
      fontSize: "32px !important",
      marginTop: "80px !important",
      marginBottom: "30px",
    },
  },
  deal_logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  deal_logo_img: {
    height: "90px",

    "@media (max-width: 1330px)": {
      height: "125px",
    },
    "@media (min-width: 1920px)": {
      height: "250px",
    },
    "@media (min-width: 3072px)": {
      height: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "75px",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "underline",
    fontFamily: `var(--font)`,
    fontWeight: 700,
  },
  signLink: {
    fontFamily: `var(--font)`,
    color: "#FFFFFF",
    fontSize: "13px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
    "@media (max-width: 1330px)": {
      fontSize: "9px",
    },
    "@media (min-width: 1920px)": {
      fontSize: "19px",
    },
    "@media (min-width: 3072px)": {
      fontSize: "29px",
    },
  },
  dash: {
    width: "10px",
    height: "2px",
    border: "2px",
    background: "#FFFFFF",
    cursor: "pointer",
    "@media (min-width: 1920px)": {
      width: "20px",
      height: "4px",
    },
    "@media (min-width: 3072px)": {
      width: "30px",
      height: "7px",
    },
  },
  currentDash: {
    width: "10px",
    height: "4px",
    border: "2px",
    background: "#FFFFFF",
    cursor: "pointer",
    "@media (min-width: 1920px)": {
      width: "26px",
      height: "6px",
    },
    "@media (min-width: 3072px)": {
      width: "40px",
      height: "10px",
    },
  },
}));

export default useStyles;
