/*
 *
 * Session constants
 *
 */

export const VERIFY_SESSION = "lg/session/VERIFY_SESSION";
export const VERIFY_SESSION_SUCCESS = "lg/session/VERIFY_SESSION_SUCCESS";
export const VERIFY_SESSION_ERROR = "lg/session/VERIFY_SESSION_ERROR";

export const LOG_IN = "lg/session/LOG_IN";
export const LOG_IN_SUCCESS = "lg/session/LOG_IN_SUCCESS";
export const LOG_IN_ERROR = "lg/session/LOG_IN_ERROR";

export const LOG_OUT = "lg/session/LOG_OUT";
export const LOG_OUT_SUCCESS = "lg/session/LOG_OUT_SUCCESS";
export const LOG_OUT_ERROR = "lg/session/LOG_OUT_ERROR";

export const SIGN_UP = "lg/session/SIGN_UP";
export const SIGN_UP_SUCCESS = "lg/session/SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "lg/session/SIGN_UP_ERROR";

export const FORGOT_PASSWORD = "lg/session/FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "lg/session/FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "lg/session/FORGOT_PASSWORD_ERROR";

export const CHANGE_PASSWORD = "lg/session/CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "lg/session/CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "lg/session/CHANGE_PASSWORD_ERROR";

export const CLEAR_CACHE = "lg/session/CLEAR_CACHE";

export const DETECT_MOBILE_DEVICE = "lg/session/DETECT_MOBILE_DEVICE";

export const OTP_VERIFICATION = "lg/session/OTP_VERIFICATION";
export const OTP_VERIFICATION_SUCCESS = "lg/session/OTP_VERIFICATION_SUCCESS";
export const OTP_VERIFICATION_ERROR = "lg/session/OTP_VERIFICATION_ERROR";

export const SEND_OTP = "lg/session/SEND_OTP";
export const SEND_OTP_SUCCESS = "lg/session/SEND_OTP_SUCCESS";
export const SEND_OTP_ERROR = "lg/session/SEND_OTP_ERROR";

export const SESSION_CACHE_HIT = "lg/session/SESSION_CACHE_HIT";

export const GEO_LOCATION = "lg/session/GEO_LOCATION";
export const GEO_LOCATION_SUCCESS = "lg/session/GEO_LOCATION_SUCCESS";
export const GEO_LOCATION_ERROR = "lg/session/GEO_LOCATION_ERROR";

export const UPDATE_USER_PROFILE = "lg/session/UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS =
  "lg/session/UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_ERROR = "lg/session/UPDATE_USER_PROFILE_ERROR";

export const RESET_PASSWORD = "lg/session/RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "lg/session/RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "lg/session/RESET_PASSWORD_ERROR";

export const RESEND_OTP = "lg/session/RESEND_OTP";
export const RESEND_OTP_SUCCESS = "lg/session/RESEND_OTP_SUCCESS";
export const RESEND_OTP_ERROR = "lg/session/RESEND_OTP_ERROR";

export const VALIDATE_UPDATE_USER_PROFILE =
  "lg/session/VALIDATE_UPDATE_USER_PROFILE";
export const VALIDATE_UPDATE_USER_PROFILE_SUCCESS =
  "lg/session/VALIDATE_UPDATE_USER_PROFILE_SUCCESS";
export const VALIDATE_UPDATE_USER_PROFILE_ERROR =
  "lg/session/VALIDATE_UPDATE_USER_PROFILE_ERROR";

export const VERIFY_USER_OTP = "lg/session/VERIFY_USER_OTP";
export const VERIFY_USER_OTP_SUCCESS = "lg/session/VERIFY_USER_OTP";
export const VERIFY_USER_OTP_ERROR = "lg/session/VERIFY_USER_OTP";

export const GET_ALL_BANNER = "lg/session/GET_ALL_BANNER";
export const GET_ALL_BANNER_SUCCESS = "lg/session/GET_ALL_BANNER_SUCCESS";
export const GET_ALL_BANNER_ERROR = "lg/session/GET_ALL_BANNER_ERROR";

export const UPLOAD_USER_PROFILE = "lg/session/UPLOAD_USER_PROFILE";
export const UPLOAD_USER_PROFILE_SUCCESS =
  "lg/session/UPLOAD_USER_PROFILE_SUCCESS";
export const UPLOAD_USER_PROFILE_ERROR = "lg/session/UPLOAD_USER_PROFILE_ERROR";

export const POST_CATEGORIES = "lg/session/POST_CATEGORIES";
export const POST_CATEGORIES_SUCCESS = "lg/session/POST_CATEGORIES_SUCCESS";
export const POST_CATEGORIES_ERROR = "lg/session/POST_CATEGORIES_ERROR";
