import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  fieldColor: {
    width: "100%",
    "& :after": {
      borderBottomColor: "#2ca01c",
    },
    "& :before": {
      borderBottomColor: "#2ca01c",
    },
    color: "green !important",
    "& label.Mui-focused": {
      color: "#2ca01c",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2ca01c",
    },
  },
  textSize: {
    fontSize: "14px",
  },
  error: {
    fontSize: "14px",
    color: "red",
  },
  input: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      color: "white",
      borderRadius: "4px",
      outline: "none",
      // background: "#252C3A",
      "& fieldset": {
        borderColor: "#9C9C9C",
      },
      "&:hover fieldset": {
        borderColor: "#9C9C9C",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#9C9C9C",
      },
    },
  },
}));

export default useStyles;
