import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import Styles from "./styles";

export default function EmailInputField(props) {
  const {
    type,
    label,
    placeholder,
    className,
    email,
    fullWidth = false,
    autoCompleteOff,
    input,
    meta: { touched, error, warning },
  } = props;

  const { value, onChange } = input;
  const InputChange = Object.assign(
    {},
    {
      onChange: (e) => onChange(e.target.value),
      value: value || "",
    },
  );

  const classes = Styles();

  return (
    <>
      <TextField
        id={type}
        type={type}
        label={label}
        placeholder={placeholder}
        {...InputChange}
        className={classes.input}
        fullWidth={fullWidth}
        autoComplete={autoCompleteOff && "off"}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: email ? (
            <InputAdornment position="start">
              <EmailIcon style={{ color: "#18AA53" }} />
            </InputAdornment>
          ) : null,
        }}
      />
      <div className={classes.error}>
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </>
  );
}
