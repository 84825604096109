/**
 *
 * Signup Form
 *
 */

import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { Grid, Button, Typography } from "@mui/material";
import InputField from "components/InputField";
import InputField1 from "components/InputField1";
import PasswordField from "components/PasswordField";
import PasswordField1 from "components/PasswordField1";
import ButtonSpinner from "components/ButtonSpinner";
import Styles from "./styles";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";
import { compose } from "redux";
import { Toaster, toast } from "react-hot-toast";

function SignupForm({
  handleSubmit,
  submitting,
  errorMessage,
  clearCache,
  destroy,
  locationState = {},
  invalid,
  onSubmitClose,
  closeModal,
  progress,
  pristine,
  formValues,
}) {
  const classes = Styles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpoints = window.innerWidth > 3072;

  useEffect(() => {
    if (
      formValues &&
      formValues?.username &&
      formValues?.username.startsWith("0")
    )
      toast.error("Phone number should not start with zero.", {
        position: "bottom-center",
      });
  }, [formValues]);

  useEffect(() => {
    return () => destroy();
  }, []);

  

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <Grid container className={classes.header} justify="space-between">
          <Grid item xs={12}>
            <Typography component="span" className={classes.title}>
              {"Create account"}
            </Typography>
            {/* <Grid item xs={12} className={classes.messageGrid}>
              <Typography component="span" className={classes.message}>
                {"Register to access your account"}
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid
          container
          xs={sm ? 12 : 9}
          className={classes.body}
          justify="flex-end"
        >
          <Grid
            item
            xs={12}
            md={10}
            sm={10}
            lg={10}
            className={classes.fieldTop}
          >
            <Field
              name="username"
              id="username"
              className={classes.fieldStyle}
              label="Email/Phone number"
              component={InputField1}
              type="text"
              size={"small"}
              variant={"standard"}
              required
              autoFocus
              logoType={"username"}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            sm={10}
            lg={10}
            className={classes.fieldTop}
          >
            <Field
              name="name"
              id="name"
              className={classes.fieldStyle}
              label="Name"
              component={InputField1}
              type="text"
              size={"small"}
              variant={"standard"}
              required
              logoType={"person_logo"}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            sm={10}
            lg={10}
            className={classes.fieldTop}
          >
            <Field
              name="password"
              id="password"
              className={classes.fieldStyle}
              label="Password"
              component={PasswordField1}
              type="text"
              size={"small"}
              variant={"standard"}
              autoCompleteOff={true}
              logoType={"password"}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            sm={10}
            lg={10}
            className={classes.fieldTop}
          >
            <Field
              name="confirm_password"
              id="confirm_password"
              className={classes.fieldStyle}
              label="Confirm password"
              component={PasswordField1}
              type="text"
              size={"small"}
              variant={"standard"}
              autoCompleteOff={true}
              logoType={"password"}
            />
          </Grid>
          <Grid container justify="flex-end" className={classes.footer}>
            <Grid
              item
              xs={12}
              md={10}
              sm={10}
              lg={10}
              className={classes.btnStyle}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={
                  pristine ||
                  submitting ||
                  (formValues &&
                    formValues?.username &&
                    formValues?.username.startsWith("0"))
                }
                className={classes.button}
                onClick={!invalid && onSubmitClose ? closeModal : null}
              >
                {((submitting || progress) && (
                  <ButtonSpinner size={sm ? 25 : breakpoints ? 40 : 25} />
                )) ||
                  "Sign up"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Toaster />
    </>
  );
}

const validate = (values) => {
  const errors = {};

  const requiredFields = ["email", "name", "password", "confirm_password"];
  const sequence =
    /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678)+/gi;

  const identical = /^(?!.*(.)\1\1.*).*$/gim;
  const commonNames = [
    "123456",
    "password",
    "123456789",
    "12345678",
    "12345",
    "111111",
    "1234567",
    "sunshine",
    "qwerty",
    "iloveyou",
    "princess",
    "admin",
    "welcome",
    "666666",
    "abc123",
    "football",
    "123123",
    "monkey",
    "654321",
    "!@#$%^&amp;*",
    "charlie",
    "aa123456",
    "donald",
    "password1",
    "qwerty123",
  ];

  const consecutive = "Avoid consecutive sequential and identical characters";
  const guessable = "Password is easily guessable";
  const special = "Must contain at least one numeric or special character";
  const charLimit = "Password must be at least 8 characters";
  const misMatch = "Password Mismatch";

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  if (values.username && parseInt(values.username)) {
    let phoneno = /^\d{9}$/;

    if (!values?.username?.match(phoneno)) {
      errors.username = "Invalid Phone number";
    }

    if (values?.username?.length > 9) {
      errors.username = "Phone number must be 9 digits";
    }
  } else {
    if (
      values.username &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.username)
    ) {
      errors.username = "Invalid Email";
    }
  }

  if (values.password && values.password.length < 8) {
    errors.password = charLimit;
  }

  if (
    values.password &&
    values.password.length >= 8 &&
    !/^(?=.*[\d#?!@$%^&*-]).{8,}$/i.test(values.password)
  ) {
    errors.password = special;
  }

  if (
    values.password &&
    (sequence.test(values.password) || !identical.test(values.password))
  ) {
    errors.password = consecutive;
  }

  commonNames.forEach((field) => {
    if (values?.password === field) {
      errors.password = guessable;
    }
    if (values?.confirm_password === field) {
      errors.confirm_password = guessable;
    }
  });

  if (
    values.password &&
    values.confirm_password &&
    values.confirm_password !== values.password
  ) {
    errors.confirm_password = misMatch;
  }

  return errors;
};

const mapStateToProps = ({ form }, props) => {
  return {
    formValues: (form[props.form] && form[props.form].values) || false,
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: "signup",
    validate,
    touchOnChange: false,
  }),
)(SignupForm);
