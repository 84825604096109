/*
 *
 *  records selectors
 *
 */

import { createSelector } from "reselect";

const selectDomain = () => (state) => state.products || false;

export const selectRecords = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.records) || false,
  );

export const selectRecord = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.record) || false,
  );

export const selectError = () =>
  createSelector(selectDomain(), (domain) => (domain && domain.error) || false);

export const selectSuccess = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.success) || false,
  );

export const selectLoading = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.loading) || false,
  );

export const selectNotification = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.notification) || [],
  );

export const selectUpdateError = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.updateError) || false,
  );

export const selectHeaders = () =>
  createSelector(selectDomain(), (domain) => (domain && domain.headers) || {});

export const selectWishListProducts = () =>
  createSelector(selectDomain(), (domain) => (domain && domain.wishList) || []);

export const selectHasMore = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.hasMore) || false,
  );

export const selectWishListHasMore = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.wishListHasMore) || false,
  );

export const selectUpdateSuccess = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.success) || false,
  );

export const selectProductFilter = () =>
  createSelector(selectDomain(), (domain) => domain && domain.productFilter);

export const selectPageLoader = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.pageLoader) || false,
  );

export const selectFilterRecords = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.filterRecords) || [],
  );

export const selectFilterParams = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.filterParams) || {},
  );

export const selectProductBanner = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.productBanner) || [],
  );

export const selectTotalItems = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.totalItems) || false,
  );

export const selectProductError = () =>
  createSelector(
    selectDomain(),
    (domain) => (domain && domain.productError) || false,
  );

const selectors = {
  selectRecords,
  selectRecord,
  selectDomain,
  selectError,
  selectSuccess,
  selectLoading,
  selectNotification,
  selectUpdateError,
  selectHeaders,
  selectWishListProducts,
  selectHasMore,
  selectWishListHasMore,
  selectUpdateSuccess,
  selectProductFilter,
  selectPageLoader,
  selectFilterParams,
  selectProductBanner,
  selectTotalItems,
  selectProductError,
};

export default selectors;
