import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  spinner: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "55px",
    "& .css-1flfzbn": {
      width: "26px",
      height: "26px",
    },
  },
}));

export default useStyles;
