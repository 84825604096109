import headerLogo from "assets/images/logo1.svg";
import footerLogo from "assets/images/footer_logo.svg";
import storeLogo from "assets/images/card/store_2.svg";
import viewLogo from "assets/images/card/view_2.svg";
import email from "assets/auth/mail.png";
import lock from "assets/auth/lock.png";
import person_logo from "assets/auth/user.png";
import call_code from "assets/images/call_code.svg";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function ({ type, style = {}, classStyle }) {
  switch (type) {
    case "header_logo":
      return <img src={headerLogo} alt={type} />;
    case "footer_logo":
      return <img src={footerLogo} alt={type} />;
    case "deal":
      return <img src={viewLogo} alt={type} width="18" height="18" />;
    case "store":
      return <img src={storeLogo} alt={type} width="22" height="22" />;
    case "username":
      return (
        <img src={email} alt={type} style={style} className={classStyle} />
      );
    case "password":
      return <img src={lock} alt={type} style={style} className={classStyle} />;
    case "person_logo":
      return (
        <img
          src={person_logo}
          alt={type}
          style={style}
          className={classStyle}
        />
      );
    case "call_code":
      return (
        <img src={call_code} alt={type} style={style} className={classStyle} />
      );
    case "up_arrow":
      return <ArrowUpwardIcon />
    case "down_arrow":
      return <ArrowDownwardIcon />
    default:
      return;
  }
}
