import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    outline: "none",
    borderRadius: "15px",
    display: "flex",
    minWidth: "40%",
    width: "25%",
    padding: "20px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "80% !important",
    },
    "@media (min-width: 1024px)": {
      width: "60% !important",
    },
    "@media (min-width: 1536px)": {
      width: "40% !important",
    },
    "@global": {
      ".MuiTab-textColorPrimary.Mui-selected": {
        color: theme.palette.accentColor.main,
      },
      ".MuiTabs-indicator": {
        backgroundColor: theme.palette.accentColor.main,
      },
      ".MuiSwitch-colorPrimary.Mui-checked": {
        color: theme.palette.accentColor.main,
      },
      ".MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: theme.palette.accentColor.main,
      },
    },
  },
  header: {
    background: theme.palette.accentColor.main,
    maxHeight: "75px",
    borderRadius: "15px",
    padding: "15px",
    "@media (min-width: 1024px)": {
      padding: "10px",
      maxHeight: "53px",
    },
    "@media (min-width: 1536px)": {
      maxHeight: "75px",
      padding: "15px",
    },
  },
  boxContainer: {
    display: "flex",
    alignItems: "center",
    background: "#EDEDED",
    maxHeight: "75px",
    borderRadius: "15px",
    padding: "15px",
    gap: "27px !important",
    "@media (min-width: 1024px)": {
      gap: "10px !important",
      padding: "10px",
      maxHeight: "53px",
    },
    "@media (min-width: 1536px)": {
      gap: "27px !important",
      maxHeight: "75px",
      padding: "15px",
    },
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  subTitle: {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontWeight: 500,
    color: "#FFFFFF",
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 700,
    color: "#242424",
  },
  subHeading: {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontWeight: 500,
    color: "#242424",
  },
  confirmBtn: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    color: "#FFFFFF",
    textTransform: "none",
    borderRadius: "7px",
    fontSize: "14px",
    paddingLeft: "25px",
    paddingRight: "25px",
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12.6267px !important",
    },
  },
  acceptBtn: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    color: "#000000",
    textTransform: "none",
    borderRadius: "7px",
    fontSize: "14px",
    paddingLeft: "25px",
    paddingRight: "25px",
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12.6267px !important",
    },
  },
  closeContainer: {
    position: "absolute",
    top: "-30px",
    right: "-3%",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      top: "-30px",
      right: "0",
    },
  },
  closeIcon: {
    cursor: "pointer",
    color: "#E0E0E0",
    width: "28px",
    height: "28px",
    "@media (max-width: 1330px)": {
      width: "20px",
      height: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      color: "#fffff !important",
    },
  },
  strickLock: {
    width: "46px",
    [theme.breakpoints.down("sm")]: {
      width: "36px !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "40px !important",
    },
  },
}));

export default useStyles;
