import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  error: {
    fontSize: "14px",
    color: "red",
    marginTop: "5px",
  },
}));

export default useStyles;
