/**
 *
 * Modal PopUp
 *
 */

import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Modal,
  Fade,
  Paper,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ImplementationForm } from "./utils";
import PropTypes from "prop-types";
import Styles from "./styles";
import CssBaseline from "@mui/material/CssBaseline";
import CloseIcon from "@mui/icons-material/Close";
import actions from "store/session/actions";
import history from "utils/history";
import { activationCode } from "utils/tools";
import { setAuthToken } from "helpers/api";
import { useSearchParams, useLocation } from "react-router-dom";
import deal_logo from "assets/logo.png";
import store2 from "store2";

/**
 *
 * @param {object} props
 * @returns
 */
function ModalPopUp(props) {
  const {
    show,
    onClose,
    paperClassName,
    children,
    success,
    loading,
    formState,
    handleModalFunc,
    dispatch,
    btnLoader,
    preventRedirect,
    productId,
  } = props;
  const classes = Styles();
  const [showModal, setModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [currentIndex, setCurrentIndex] = useState(0);

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const location = useLocation();

  const formValue = formState || false;
  const Component = formValue && ImplementationForm[formValue];
  const activation_code = activationCode(history);
  let rememberedUser = store2.get("userdetails");
  const checkCredentials =
    (rememberedUser?.username && rememberedUser?.pwd) || false;
  rememberedUser = checkCredentials
    ? Object.assign(
      {},
      { username: rememberedUser?.username, password: rememberedUser?.pwd },
    )
    : {};

  const slides = [
    "Join us for unbeatable deals, exclusive offers, and personalized recommendations!",
    "Get the best discounts, special rewards, and tailored deals by joining today!",
    "Get exclusive deals on time!",
    "Join our WhatsApp channel for exclusive, timely deals!",
  ];

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      goToNext();
    }, 5000);
    return () => clearTimeout(timer);
  }, [currentIndex]);

  const closeModal = () => {
    setModalOpen(false);
    if (onClose) onClose();
    if (!preventRedirect && location.pathname.indexOf('productDetails') !== 1)
      history.replace({ pathname: "/", state: { ...history.location.state } });
  };

  const handleSubmit = (data, dispatch, { form }) => {

    const submitRecord = Object.assign({}, { ...data });
    if (form === "loginForm") {
      dispatch(
        actions.logIn(submitRecord, closeModal, form, {
          preventRedirect,
          productId,
        }),
      );
    } else if (form === "signupForm") {

      dispatch(actions.signUp(submitRecord, closeModal, form));
    } else if (form === "verificationForm") {
      dispatch(
        actions.otpVerification(
          Object.assign({}, { ...submitRecord }, { activation_code }),
          closeModal,
          form,
        ),
      );
    } else if (form === "forgotForm") {
      dispatch(
        actions.forgotPassword(
          Object.assign({}, { ...submitRecord }),
          closeModal,
          form,
        ),
      );
    } else if (form === "resetForm") {
      const token = searchParams.get("token");
      setAuthToken(`JWT ${token}`);
      dispatch(actions.resetPassword(data, closeModal, form));
    }
  };

  const resendOtp = () => {
    dispatch(actions.resendOtp(Object.assign({}, { activation_code })));
  };

  return (
    <Grid container>
      {children && children(() => setModalOpen(!showModal))}
      <Modal
        open={showModal || show || false}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
        closeAfterTransition
      >
        <Fade in={showModal || show || false}>
          <Paper
            className={`${classes.paper} ${paperClassName}`}
            style={!md ? { width: "100%" } : {}}
          >
            <Grid container className={classes.container} wrap="nowrap">
              <CssBaseline />
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                elevation={6}
                square
                className={classes.formContainer}
              >
                <Box className={classes.boxStyle}>
                  {(formState && (
                    <React.Fragment>
                      <Component
                        initialValues={
                          formState === "login" ? rememberedUser : {}
                        }
                        form={`${formState}Form`}
                        onSubmit={handleSubmit.bind(this)}
                        success={success}
                        handleModalFunc={handleModalFunc}
                        loading={loading}
                        resendOtp={resendOtp}
                        btnLoader={btnLoader}
                        enableSubmitBtn={
                          formState === "login" && checkCredentials
                            ? true
                            : false
                        }
                        {...props}
                      />
                    </React.Fragment>
                  )) ||
                    null}
                  {(!sm && (
                    <Grid
                      container
                      className={classes.copyRights}
                      style={
                        mdDown ? { marginTop: "30px" } : { marginTop: "16px" }
                      }
                    >
                      Copyright © {new Date().getFullYear()} Dealpopup. all rights reserved
                    </Grid>
                  )) ||
                    null}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={5} className={classes.details}>
                <Grid>
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <CloseIcon
                      onClick={closeModal}
                      className={classes.closeIcon}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.boxStyle2}>
                  <Grid item className={classes.deal_logo}>
                    <img
                      src={deal_logo}
                      alt="deal_logo"
                      className={classes.deal_logo_img}
                    />
                  </Grid>
                  <Grid item>
                    <Grid item xs={12} className={classes.greeting}>
                      Fresh deals, Fresh savings!
                    </Grid>
                    <Grid item xs={12} className={classes.subGreeting}>
                      {slides?.[currentIndex]}
                    </Grid>
                    <Grid
                      item
                      sx={{ gap: 5 }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      {slides?.map((e, i) =>
                        currentIndex === i ? (
                          <div
                            className={classes.currentDash}
                            key={i}
                            onClick={() => goToSlide(i)}
                          ></div>
                        ) : (
                          <div
                            className={classes.dash}
                            key={i}
                            onClick={() => goToSlide(i)}
                          ></div>
                        ),
                      )}
                    </Grid>
                    <Grid item xs={12} className={classes.greetingDiv}>
                      {formValue === "login" ? (
                        <Typography className={classes.signLink}>
                          Don't have an account with Dealpopup?{" "}
                          <span
                            onClick={() => handleModalFunc("signup")}
                            className={classes.link}
                          >
                            Sign up
                          </span>
                        </Typography>
                      ) : formValue === "signup" ? (
                        <>
                          <Typography className={classes.signLink}>
                            Already have an account with Dealpopup?{" "}
                            <span
                              onClick={() => handleModalFunc("login")}
                              className={classes.link}
                            >
                              Sign in
                            </span>
                          </Typography>
                          <Typography
                            className={classes.signLink}
                            style={{ marginTop: "5px" }}
                          >
                            By signing up, I agree to Dealpopup.com <br /> Terms
                            of service & Privacy policy
                          </Typography>
                        </>
                      ) : formValue === "forgot" ? (
                        <Typography className={classes.signLink}>
                          Remember It ?{" "}
                          <span
                            onClick={() => handleModalFunc("login")}
                            className={classes.link}
                          >
                            Sign in here
                          </span>
                        </Typography>
                      ) : (
                        <Typography className={classes.signLink}>
                          Don't have an account with Dealpopup?{" "}
                          <span
                            onClick={() => handleModalFunc("signup")}
                            className={classes.link}
                          >
                            Sign up
                          </span>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </Grid>
  );
}

ModalPopUp.propTypes = {
  title: PropTypes.string,
  children: PropTypes.func,
  handleModalRecordForm: PropTypes.func,
  error: PropTypes.string,
};

export default ModalPopUp;
