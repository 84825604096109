import React, { Suspense, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Layout from "components/Layout";
import { ImplementationFor } from "./utils";
import Spinner from "components/Spinner";
import useMediaQuery from "@mui/material/useMediaQuery";
import NetworkError from "components/NetworkError";
import { useTheme } from "@mui/styles";
import ErrorBoundary from "components/ErrorBoundary";
import { useLocation } from "react-router-dom";
import { verifySession } from '../store/session/actions';
import store2 from 'store2'

const AppRoute = ({ component, ...rest }) => {
  const Component = ImplementationFor[component];

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [online, setOnline] = useState(window.navigator.onLine);
  const { dispatch} = rest;

  //Below useEffect for calling session API based on local storage change.
  useEffect(() => {
    dispatch(verifySession(store2.get('secret')));
  }, [store2.get('secret')]);

  useEffect(() => {
    window.addEventListener("online", () => setOnline(true));
    window.addEventListener("offline", () => setOnline(false));
    return () => {
      window.addEventListener("online", () => setOnline(true));
      window.addEventListener("offline", () => setOnline(false));
    }
  }, []);

  if(!online)
    return <NetworkError />

  return (
    <Suspense fallback={<Spinner style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "100vh" }} sm={sm} />}>
      <ErrorBoundary>
        <Layout {...rest}>
          <Component {...rest} />
        </Layout>
      </ErrorBoundary>
    </Suspense>
  );
};

AppRoute.propTypes = {
  component: PropTypes.any,
};

export default AppRoute;
