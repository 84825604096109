/**
 *
 * Checkbox Field
 *
 */

import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import Styles from "./styles";

export default function ({
  input,
  id,
  label,
  required,
  styles,
  note,
  warning,
  labelStyle,
  breakpoints,
  meta: { touched, error },
}) {
  const classes = Styles();

  return (
    <div>
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            id={id}
            size={breakpoints ? "large" : "small"}
            style={
              styles
                ? styles
                : { color: "grey", padding: 0, paddingRight: "5px" }
            }
            className={classes.checkbox}
            checked={input.value || false}
            onChange={(e) => input.onChange(e.target.checked)}
          />
        }
        label={
          <span
            className={classes.textSize}
            style={labelStyle || {}}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        }
      />
      {(note && <div className={classes.textSize}>{note}</div>) || null}
      <div className={classes.error}>
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
}
