import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";

import session from "./session/reducer";
import products from "./products/reducer";

export const rootReducer = (history) => {
  const appReducer = combineReducers({
    session,
    products,
    form: formReducer,
    router: connectRouter(history),
  });
  return (state, action) => {
    if (action.type === "lg/session/LOG_OUT") {
      state = undefined;
    }
    return appReducer(state, action);
  };
};

export default rootReducer;
