/**
 *
 *
 * Layout Component
 *
 *
 */

import React, { useEffect } from "react";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Header from "components/Header";
import Footer from "components/Footer";
import ImageSlider from "components/ImageSlider";
import Styles from "./styles";

import Categories from "components/Categories";
import { shuffleArray } from "utils/tools";
import Skeleton from "@mui/material/Skeleton";
import Cookies from "components/Cookies";
import store2 from "store2";
import { useLocation } from "react-router-dom";
function Layout(props) {
  const classes = Styles();
  const {
    children,
    isMobile,
    form = false,
    banner,
    categoriesOptions,
    categories,
    loadingBanner,
    dispatch,
    filterParams,
  } = props;

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  const cookieConsent = store2.get("cookieConsent");
  const cookieConsentDecline = store2.get("cookieConsentDecline");

  let bannerImage =
    banner &&
    banner?.length > 0 &&
    banner.filter((_) => _.page && _.page === "homePage");
  bannerImage = bannerImage && bannerImage.map((_) => _.url);

  if (bannerImage && bannerImage?.length > 0) {
    bannerImage = shuffleArray(bannerImage);
  }

  useEffect(() => {
    if (location.pathname !== '/') {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 10)
    }
  }, [location.pathname])

  return (
    <>
      <Header form={form} />
      <div>
        <div className={classes.toolbar} />
        {props?.showFilter ? (
          <Categories
            categoriesOptions={categoriesOptions}
            categorieRecords={categories}
            dispatch={dispatch}
            filterParams={filterParams}
          />
        ) : null}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ maxWidth: "1600px", width: "100%" }}>
            {(props?.slider &&
              (loadingBanner ? (
                <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: sm ? "20%" : "15%" }} />
                </Skeleton>
              ) : (
                <>
                  {
                    categories.length === 0 ?
                      <div style={{ marginTop: sm ? "80px" : null }}>
                        <ImageSlider banner={bannerImage} isMobile={isMobile} />
                      </div>
                      :
                      <ImageSlider banner={bannerImage} isMobile={isMobile} />
                  }
                </>
              ))) ||
              null}
            {children}
          </div>
        </div>
      </div>
      {!cookieConsent && !cookieConsentDecline ? <Cookies /> : null}
      <Footer id="footer" />
    </>
  );
}

export default Layout;
