import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    marginTop: "5px",
  },
  textSize: {
    fontSize: "14px !important",
    fontFamily: `var(--font)`,
    [theme.breakpoints.up("md")]: {
      fontSize: "12px !important",
    },
    "@media (min-width: 1920px)": {
      fontSize: "25px !important",
    },
    "@media (min-width: 3072px)": {
      fontSize: "27px !important",
    },
  },
  checkbox: {
    "& .MuiSvgIcon": {
      height: "12px !important",
    },
  },
  error: {
    fontSize: "14px",
    color: "red",
    "@media (min-width: 1920px)": {
      fontSize: "23px !important",
    },
    "@media (min-width: 3072px)": {
      fontSize: "27px",
    },
  },
}));

export default useStyles;
