/**
 *
 * Password Field
 *
 */

import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Grid,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Styles from "./styles";

export default function ({
  input,
  label,
  required,
  placeholder,
  autoCompleteOff,
  id,
  size,
  className,
  meta: { touched, error, warning },
}) {
  const classes = Styles();
  const [showPassword, setshowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <FormControl
        className={className || classes.fieldColor}
        variant="outlined"
        fullWidth
      >
        <InputLabel htmlFor="outlined-adornment-password" size="small">
          {label}
        </InputLabel>
        <OutlinedInput
          id={id}
          type={showPassword ? "text" : "password"}
          size={"small"}
          defaultValue={input.value || ""}
          onChange={(e) => input.onChange(e.target.value)}
          autoComplete={autoCompleteOff && "new-password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityIcon className={classes.passIcon} />
                ) : (
                  <VisibilityOffIcon className={classes.passIcon} />
                )}
              </IconButton>
            </InputAdornment>
          }
          label={label}
          placeholder={placeholder}
        />
      </FormControl>
      <Grid sx={{ mt: 1 }} className={classes.error}>
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </Grid>
    </div>
  );
}
