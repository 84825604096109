import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Typography } from "@mui/material"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate error
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can customize the error message here
        return <Container style={{ minHeight: "500px", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", padding: "25px"  }}>
            <Grid item lg={12}>
                <Typography variant="h2" component="h2">
                    Whoops!
                </Typography>
                <Typography variant="h8" component="h8">
                    Thank you for visiting <Link to="/">Dealpopup.com</Link>, we're currently experiencing some technical difficulties. Rest assured, we are doing everything we can to rectify the problem. Please check back soon.
                </Typography>
            </Grid>
        </Container>;
    }

    // Render children if no error occurred
    return this.props.children;
  }
}

export default ErrorBoundary;