import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  fieldColor: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.accentColor.main,
      },
      "& fieldset": {
        borderColor: theme.palette.accentColor.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.accentColor.main,
      },
    },
    "& label.Mui-focused": {
      color: theme.palette.accentColor.main,
    },
  },
  textSize: {
    fontSize: "14px",
  },
  error: {
    fontSize: "12px",
    color: "red",
    marginTop: "2px",
    fontFamily: "Poppins",
    "@media (max-width: 1330px)": {
      fontSize: "10px",
    },
  },
}));

export default useStyles;
