/**
 *
 * Password Field
 *
 */

import React, { useState } from "react";
import {
  InputAdornment,
  IconButton,
  Grid,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Styles from "./styles";
import Icons from "components/Icons";

export default function ({
  input,
  label,
  required,
  placeholder,
  autoCompleteOff,
  id,
  size,
  className,
  logoType,
  variant,
  meta: { touched, error, warning },
}) {
  const classes = Styles();
  const [showPassword, setshowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div>
        <div>
          <label htmlFor={label} className={classes.label}>
            {label} :
          </label>
        </div>
        <div className={classes.fieldContainer}>
          {(logoType && (
            <Icons type={logoType} classStyle={classes.iconStyle} />
          )) ||
            null}
          <TextField
            id={id}
            type={showPassword ? "text" : "password"}
            fullWidth
            size={size || "medium"}
            variant={variant || "standard"}
            defaultValue={input.value || ""}
            onChange={(e) => input.onChange(e.target.value)}
            autoComplete={autoCompleteOff && "new-password"}
            className={classes.input}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end" style={{ marginRight: "5px" }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityIcon className={classes.passIcon} />
                    ) : (
                      <VisibilityOffIcon className={classes.passIcon} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            endAdornment={<InputAdornment position="end"></InputAdornment>}
            placeholder={placeholder}
          />
        </div>
      </div>
      <Grid sx={{ mt: 1 }} className={classes.error}>
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </Grid>
    </div>
  );
}
