import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    width: "100%",
    height: "36px",
    borderRradius: "5px",
    background: "#F3F3F3",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    gap: 10,
    "& .MuiInputBase-input": {
      paddingTop: "5px"
    },
    "@media (max-width: 1330px)": {
      height: "26px",
      "& .MuiInputBase-root": {
        fontSize: "11px",
      },
      "& .MuiInputBase-input": {
        fontFamily: "Poppins",
        fontSize: "12px"
      }
    },
    [theme.breakpoints.down("sm")]: {
      height: "40px",
      background: "#fff",
      border: "1px solid #afafaf",
      "& .MuiInputBase-root": {
        fontSize: "14px",
      },
      "& .MuiInputBase-input": {
        fontSize: "14px",
      }
    },
    "@media (min-width: 1920px)": {
      height: "56px",
      "& .MuiInputBase-root": {
        fontSize: "24px",
      },
    },
    "@media (min-width: 3072px)": {
      height: "100px",
      "& .MuiInputBase-root": {
        fontSize: "34px",
      },
    },
  },
  input: {
    width: "100%",
    background: "#F3F3F3",
    [theme.breakpoints.down("sm")]: {
      background: "#fff",
    },
  },
  textSize: {
    fontSize: "14px",
  },
  error: {
    fontSize: "12px",
    color: "red",
    marginTop: "2px",
    fontFamily: "Poppins",
    "@media (max-width: 1330px)": {
      fontSize: "10px",
    },
    "@media (min-width: 1920px)": {
      fontSize: "23px !important",
    },
    "@media (min-width: 3072px)": {
      fontSize: "27px !important",
    },
  },
  label: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "21px",
    letterSpacing: "0px",
    textAlign: "left",
    "@media (max-width: 1330px)": {
      fontSize: "11px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
    },
    "@media (min-width: 1920px)": {
      fontSize: "25px !important",
    },
    "@media (min-width: 3072px)": {
      fontSize: "34px",
    },
  },
  iconStyle: {
    height: "16px",
    width: "16px",
    "@media (min-width: 1920px)": {
      width: "30px",
      height: "30px",
    },
    "@media (min-width: 3072px)": {
      width: "50px",
      height: "50px",
    },
  },
}));

export default useStyles;
