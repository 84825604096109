/**
 *
 *
 * Floating Button
 *
 */

import React from "react";
import Fab from "@mui/material/Fab";
import Icons from "components/Icons";
import Styles from "./styles";

const FloatingButton = (props) => {
  const { type, position, handleClick } = props;
  const classes = Styles();

  return (
    <Fab
      color="primary"
      aria-label="add"
      className={position === 'left' ? classes.fab2 : classes.fab}
      onClick={handleClick}
    >
      <Icons type={type} />
    </Fab>
  );
};

export default FloatingButton;
