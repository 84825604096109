/**
 *
 *
 * Button Spinner
 *
 */

import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Grid from "@mui/material/Grid";
import Styles from "./styles";

const ButtonSpinner = ({ color, style, className, size }) => {
  const classes = Styles();
  return (
    <Grid className={`${classes.spinner} ${className}`} style={style}>
      <ClipLoader color={color || "#fff"} size={size || 35} />
    </Grid>
  );
};

export default ButtonSpinner;
