import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Styles from "./styles";
import { Transition } from "react-transition-group";
import SideNavRow from "./SideNavRow";
import { getParentRecord } from "utils/tools";
import { useRef } from "react";

export default function AllCategories({
  categories,
  handleTreeSearch,
  handleClose,
  state,
  setSubContainerEntries,
  subContainerEntries,
}) {
  const [subContainer, setSubContainer] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const classes = Styles();
  const parentRef = useRef(true);
  const categorieTravel = useRef(0);

  const handleScroll = (e) => {
    if (parentRef?.current && !subContainer && !subContainerEntries) {
      setScrollHeight(e.target.scrollTop);
    }
  };

  useEffect(() => {
    document.body.classList.add("body-scroll-disabled");
    const categoryItems = document.getElementById("categoryItems");
    categoryItems.addEventListener("scroll", handleScroll, false);
    return () => {
      document.body.classList.remove("body-scroll-disabled");
      categoryItems.removeEventListener("scroll", handleScroll, false);
      parentRef.current = true;
      categorieTravel.current = 0;
    };
  }, []);

  useEffect(() => {
    const categoryItems = document.getElementById("categoryItems");
    if (parentRef?.current && !subContainer && !subContainerEntries) {
      if (scrollHeight) {
        categoryItems.scrollTop = scrollHeight + 100;
      } else {
        if (categorieTravel?.current) {
          categoryItems.scrollTop = scrollHeight + 100;
          setTimeout(() => {
            categoryItems.scrollTop = 0;
          }, 400);
        } else {
          categoryItems.scrollTop = 0;
        }
      }
    } else {
      categoryItems.scrollTop = 0;
    }
  }, [subContainer]);

  return (
    <Box
      id="categoryItems"
      sx={{ width: "100%", display: "flex", flexDirection: "column", fontSize:"15px" }}
      className={`${classes.categorieItemBox} category_item`}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        style={
          state === "exiting"
            ? { opacity: 0 }
            : state === "entering"
              ? { animation: "moveMainContainer .3s reverse backwards" }
              : null
        }
      >
        <Transition in={subContainer} timeout={400} mountOnEnter unmountOnExit>
          {(newState) => {
            return (
              <div
                style={
                  newState === "entering"
                    ? { animation: "moveSubContainer .6s forwards" }
                    : newState === "entered"
                      ? { transform: "translateX(0px)" }
                      : { animation: "moveSubContainer .4s reverse backwards" }
                }
              >
                {
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSubContainer(false);
                      const parentRecord1 = getParentRecord(
                        subContainerEntries?.parent,
                        categories,
                      );
                      if (parentRecord1 && parentRecord1?.length > 0) {
                        if (parentRecord1[0]?.children) {
                          parentRef.current = false;
                          setSubContainerEntries(parentRecord1[0]);
                          setTimeout(() => {
                            setSubContainer(true);
                          }, 5);
                        }
                      } else {
                        parentRef.current = true;
                        setSubContainer(false);
                        setSubContainerEntries(false);
                      }
                    }}
                    className={classes.backBtn}
                  >
                    {"<"} Back
                  </div>
                }

                <div
                  className={classes.parentHeader}
                  onClick={() => {
                    handleTreeSearch(subContainerEntries);
                    handleClose();
                    setSubContainer(false);
                    setSubContainerEntries(false);
                  }}
                >
                  <div
                    className={classes.parentCategoryTitle}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{ display: "flex", gap: "5px", fontSize: "17px" }}
                    >
                      {subContainerEntries.category_name}
                    </div>
                    <span className={classes.openBtn}>Open</span>
                  </div>
                </div>

                {subContainerEntries &&
                  subContainerEntries?.children &&
                  subContainerEntries?.children?.map((e, i) => {
                    return (
                      <SideNavRow
                        record={e}
                        handleClose={handleClose}
                        handleTreeSearch={handleTreeSearch}
                        setSubContainerEntries={setSubContainerEntries}
                        categories={categories}
                        setSubContainer={setSubContainer}
                        parentRef={parentRef}
                        categorieTravel={categorieTravel}
                      />
                    );
                  })}
              </div>
            );
          }}
        </Transition>
        {!subContainer && !subContainerEntries && (
          <Transition
            in={!subContainer && !subContainerEntries}
            timeout={100}
            mountOnEnter
            unmountOnExit
          >
            {(newState) => {
              return (
                <div
                  style={
                    newState === "entering"
                      ? { animation: "moveSubContainer .3s forwards" }
                      : newState === "entered"
                        ? { transform: "translateX(0px)" }
                        : newState === "exited"
                          ? { animation: "moveSubContainer .3s reverse backwards" }
                          : null
                  }
                >
                  {categories?.map((e, i) => {
                    return (
                      <SideNavRow
                        key={i}
                        record={e}
                        handleClose={handleClose}
                        handleTreeSearch={handleTreeSearch}
                        setSubContainerEntries={setSubContainerEntries}
                        categories={categories}
                        setSubContainer={setSubContainer}
                        parentRef={parentRef}
                        categorieTravel={categorieTravel}
                      />
                    );
                  })}
                </div>
              );
            }}
          </Transition>
        )}
      </div>
    </Box>
  );
}
