/**
 *
 * Email Form
 *
 */

import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { Grid, Button } from "@mui/material";
import EmailInputField from "components/EmailInputField";
import Styles from "./styles";
import ButtonSpinner from "components/ButtonSpinner";

/**
 *
 * @param {object} props
 * @returns
 */
function EmailForm(props) {
  const { handleSubmit, destroy, loading } = props;
  const classes = Styles();

  useEffect(() => {
    return () => destroy();
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      style={{ display: "flex", flexWrap: "wrap" }}
    >
      <Grid item xs={12} md={8}>
        <Field
          name="email"
          placeholder="Email address"
          component={EmailInputField}
          fullWidth
          type="email"
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Button
          type="submit"
          variant="contained"
          color="accentColor"
          fullWidth
          className={classes.subscribeBtn}
          disabled={loading}
        >
          {loading ? <ButtonSpinner /> : "Submit"}
        </Button>
      </Grid>
    </form>
  );
}

const validate = (values) => {
  const errors = {};

  const requiredFields = ["email"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Please enter your email address.";
    }
  });

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid Email Address.";
  }

  return errors;
};

export default reduxForm({
  form: "EmailSubscription",
  validate,
  touchOnChange: false,
})(EmailForm);
