import { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";

const useSmoothHorizontalScroll = () => {
  const scrollContainerRef = useRef(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  useEffect(() => {
    if (!scrollContainerRef.current) return;

    const handleResize = debounce(() => {
      setIsAtEnd(
        scrollContainerRef.current.scrollWidth ===
          scrollContainerRef.current.offsetWidth,
      );
      setIsAtStart(scrollContainerRef.current?.scrollLeft === 0);
    }, 200);

    window.addEventListener("resize", handleResize);
    setIsAtEnd(false);
    setIsAtStart(scrollContainerRef.current?.scrollLeft === 0);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [scrollContainerRef]);

  const handleContainerScroll = () => {
    if (!scrollContainerRef.current) return;

    setIsAtStart(scrollContainerRef.current?.scrollLeft === 0);
    setIsAtEnd(
      Math.floor(
        scrollContainerRef.current?.scrollWidth -
          scrollContainerRef.current?.scrollLeft,
      ) <= scrollContainerRef.current?.offsetWidth,
    );
  };

  const scrollTo = (shift) => {
    if (!scrollContainerRef.current) return;

    scrollContainerRef.current?.scrollTo({
      left: scrollContainerRef.current?.scrollLeft + shift,
      behavior: "smooth",
    });
  };

  return {
    scrollContainerRef,
    handleContainerScroll,
    scrollTo,
    isAtStart,
    isAtEnd,
  };
};

export default useSmoothHorizontalScroll;
