/*
 *
 * records reducer
 *
 */

import produce from "immer";

import merge from "lodash/merge";

import {
  LOAD_PRODUCTS_VALID_CACHE,
  LOAD_PRODUCTS,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_ERROR,
  LOAD_PRODUCT,
  LOAD_PRODUCT_SUCCESS,
  LOAD_PRODUCT_ERROR,
  SET_HEADERS_DATA,
  WISHLIST_PRODUCT,
  WISHLIST_PRODUCT_SUCCESS,
  WISHLIST_PRODUCT_ERROR,
  ADD_WISHLIST_PRODUCT_SUCCESS,
  ADD_WISHLIST_PRODUCT_ERROR,
  DELETE_WISHLIST_PRODUCT_SUCCESS,
  DELETE_WISHLIST_PRODUCT_ERROR,
  CLEAR_PRODUCTS_RECORDS,
  REMOVE_WISHLIST_PRODUCT_SUCCESS,
  REMOVE_WISHLIST_PRODUCT_ERROR,
  CREATE_USER_COMMENT_ERROR,
  CREATE_USER_COMMENT_SUCCESS,
  PRODUCT_FILTER,
  PRODUCT_FILTER_SUCCESS,
  CREATE_PRODUCT_LIKE_SUCCESS,
  CREATE_PRODUCT_LIKE_ERROR,
  CREATE_PRODUCT_UNLIKE_SUCCESS,
  CREATE_PRODUCT_UNLIKE_ERROR,
  DELETE_USER_COMMENT_SUCCESS,
  DELETE_USER_COMMENT_ERROR,
  EDIT_USER_COMMENT_ERROR,
  EDIT_USER_COMMENT_SUCCESS,
  ADD_WISHLIST_PRODUCT_LIKE,
  ADD_WISHLIST_PRODUCT_UNLIKE,
  ADD_WISHLIST_RELATED_PRODUCT_SUCCESS,
  ADD_WISHLIST_RELATED_PRODUCT_ERROR,
  DELETE_WISHLIST_RELATED_PRODUCT_SUCCESS,
  DELETE_WISHLIST_RELATED_PRODUCT_ERROR,
  LOAD_BANNER_SUCCESS,
  LOAD_BANNER,
  LOAD_BANNER_ERROR,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_SUCCESS,
  SEARCH_PRODUCTS_ERROR,
  PRODUCT_FILTER_PARAMS,
  ADD_WISHLIST_FILTER_PAGE_SUCCESS,
  ADD_WISHLIST_FILTER_PAGE_ERROR,
  DELETE_WISHLIST_FILTER_PAGE_SUCCESS,
  DELETE_WISHLIST_FILTER_PAGE_ERROR,
  CREATE_RELATED_PRODUCT_LIKE_SUCCESS,
  CREATE_RELATED_PRODUCT_LIKE_ERROR,
  CREATE_RELATED_PRODUCT_UNLIKE_SUCCESS,
  CREATE_RELATED_PRODUCT_UNLIKE_ERROR,
  CLEAR_SEARCH_RECORD,
} from "./constants";

const initialState = {
  records: [],
  record: {},
  loading: false,
  error: false,
  success: false,
  updateError: false,
  pageLoader: false,
  headers: {
    offset: 0,
    limit: 25,
    search: false,
    filter: false,
    sort: false,
    page: 1,
    prevOffset: 0,
    prevPage: 1
  },
  wishList: [],
  hasMore: false,
  productFilter: "new_products",
  filterRecords: [],
  categoriesOptions: [],
  filterParams: {},
  productBanner: [],
  totalItems: false,
  productError: false,
};

/**
 * @param {object} state
 * @param {object} action
 */
const appReducer = (
  state = initialState,
  {
    type,
    id,
    records,
    record,
    error,
    success,
    hasMore,
    productFilter,
    filterRecords,
    categoriesOptions,
    filterParams,
    categories,
    totalItems,
  },
) =>
  produce(state, (draft) => {
    switch (type) {
      case LOAD_PRODUCTS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.progress = false;
        draft.productError = false;
        break;
      case LOAD_PRODUCTS:
      case WISHLIST_PRODUCT:
      case PRODUCT_FILTER:
      case SEARCH_PRODUCTS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.hasMore = false;
        draft.wishListHasMore = false;
        break;
      case LOAD_PRODUCTS_SUCCESS:
        draft.records = records;
        draft.loading = false;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.hasMore = hasMore;
        draft.totalItems = totalItems;
        break;
      case LOAD_PRODUCTS_ERROR:
      case WISHLIST_PRODUCT_ERROR:
      case ADD_WISHLIST_PRODUCT_ERROR:
      case DELETE_WISHLIST_PRODUCT_ERROR:
      case REMOVE_WISHLIST_PRODUCT_ERROR:
      case CREATE_USER_COMMENT_ERROR:
      case CREATE_PRODUCT_LIKE_ERROR:
      case CREATE_PRODUCT_UNLIKE_ERROR:
      case DELETE_USER_COMMENT_ERROR:
      case EDIT_USER_COMMENT_ERROR:
      case SEARCH_PRODUCTS_ERROR:
      case ADD_WISHLIST_FILTER_PAGE_ERROR:
      case DELETE_WISHLIST_FILTER_PAGE_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.updateError = false;
        draft.success = false;
        break;
      case LOAD_PRODUCT:
      case LOAD_BANNER:
        draft.record = {};
        draft.pageLoader = true;
        draft.error = false;
        draft.success = false;
        break;
      case LOAD_PRODUCT_SUCCESS:
        draft.record = record;
        draft.pageLoader = false;
        draft.error = false;
        draft.success = false;
        break;
      case LOAD_BANNER_ERROR:
        draft.pageLoader = false;
        draft.error = true;
        draft.updateError = false;
        draft.success = false;
        break;
      case LOAD_PRODUCT_ERROR:
        draft.pageLoader = false;
        draft.error = false;
        draft.updateError = false;
        draft.productError = error;
        draft.success = false;
        break;
      case SET_HEADERS_DATA:
        draft.headers = merge({}, draft.headers, record);
        break;
      case WISHLIST_PRODUCT_SUCCESS:
        draft.wishList = records;
        draft.pageLoader = false;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.wishListHasMore = hasMore;
        draft.totalItems = totalItems;
        break;
      case ADD_WISHLIST_PRODUCT_SUCCESS:
      case CREATE_USER_COMMENT_SUCCESS:
      case CREATE_PRODUCT_LIKE_SUCCESS:
      case CREATE_PRODUCT_UNLIKE_SUCCESS:
      case DELETE_USER_COMMENT_SUCCESS:
      case EDIT_USER_COMMENT_SUCCESS:
        draft.records = state.records.find((r) => r.id === record.id)
          ? state.records.map((e) =>
              e.id === record.id ? Object.assign({}, { ...record }) : e,
            )
          : state.records.concat([Object.assign({}, record)]);
        draft.record = Object.assign({}, draft.record, record);
        draft.pageLoader = false;
        draft.loading = false;
        draft.error = false;
        draft.success = success;
        break;
      case DELETE_WISHLIST_PRODUCT_SUCCESS:
        // draft.wishList = draft.wishList.filter((_) => _.id != record.id);
        draft.records = state.records.find((r) => r.id === record.id)
          ? state.records.map((e) =>
              e.id === record.id ? Object.assign({}, { ...record }) : e,
            )
          : state.records.concat([Object.assign({}, record)]);
        draft.record = Object.assign({}, draft.record, record);
        draft.pageLoader = false;
        draft.loading = false;
        draft.error = false;
        draft.success = success;
        break;
      case REMOVE_WISHLIST_PRODUCT_SUCCESS:
        draft.wishList = draft.wishList.filter((_) => _.id !== record.id);
        draft.pageLoader = false;
        draft.loading = false;
        draft.error = false;
        draft.success = success;
        break;
      case ADD_WISHLIST_PRODUCT_LIKE:
      case ADD_WISHLIST_PRODUCT_UNLIKE:
        draft.wishList = state.wishList.find((r) => r.id === record.id)
          ? state.wishList.map((e) =>
              e.id === record.id ? Object.assign({}, { ...record }) : e,
            )
          : state.wishList.concat([Object.assign({}, record)]);
        draft.pageLoader = false;
        draft.loading = false;
        draft.error = false;
        draft.success = success;
        break;
      case CLEAR_PRODUCTS_RECORDS:
        draft.records = [];
        break;
      case PRODUCT_FILTER_SUCCESS:
        draft.productFilter = productFilter;
        break;
      case ADD_WISHLIST_RELATED_PRODUCT_SUCCESS:
      case DELETE_WISHLIST_RELATED_PRODUCT_SUCCESS:
      case CREATE_RELATED_PRODUCT_LIKE_SUCCESS:
      case CREATE_RELATED_PRODUCT_UNLIKE_SUCCESS:
        draft.record = Object.assign({}, draft.record, record);
        draft.pageLoader = false;
        draft.loading = false;
        draft.error = false;
        draft.success = success;
        break;
      case ADD_WISHLIST_RELATED_PRODUCT_ERROR:
      case DELETE_WISHLIST_RELATED_PRODUCT_ERROR:
      case CREATE_RELATED_PRODUCT_LIKE_ERROR:
      case CREATE_RELATED_PRODUCT_UNLIKE_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.updateError = false;
        draft.success = false;
        break;
      case SEARCH_PRODUCTS_SUCCESS:
        draft.filterRecords = filterRecords;
        draft.loading = false;
        draft.error = false;
        draft.updateError = false;
        draft.success = success;
        draft.hasMore = hasMore;
        draft.totalItems = totalItems;
        break;
      case PRODUCT_FILTER_PARAMS:
        draft.filterParams = filterParams;
        break;
      case ADD_WISHLIST_FILTER_PAGE_SUCCESS:
      case DELETE_WISHLIST_FILTER_PAGE_SUCCESS:
        draft.filterRecords = filterRecords;
        draft.loading = false;
        draft.error = false;
        draft.updateError = false;
        draft.success = success;
        break;
      case LOAD_BANNER_SUCCESS:
        draft.productBanner = record;
      case CLEAR_SEARCH_RECORD:
        draft.filterRecords = [];
    }
  });

export default appReducer;
