import React from "react";
import { Navigate } from "react-router-dom";
import store from "store2";

function ProtectedRoute({ isLoggedIn, children }) {
  const secret = store.get("secret");
  if (!isLoggedIn && !secret) {
    return <Navigate to="/" replace />;
  }
  return children;
}
export default ProtectedRoute;
