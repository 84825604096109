/*
 *
 *  session sagas
 *
 */

import {
  call,
  take,
  put,
  all,
  delay,
  takeLatest,
  select,
} from "redux-saga/effects";
import { destroy, startSubmit, stopSubmit } from "redux-form";
import { push } from "react-router-redux";
import { setAuthToken, removeAuthToken } from "../../helpers/api";
import history from "utils/history";

import store2 from "store2";
import Cookies from "js-cookie";
import lodash from "lodash";
import {
  LOG_IN,
  LOG_OUT,
  VERIFY_SESSION,
  SIGN_UP,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  OTP_VERIFICATION,
  SEND_OTP,
  GEO_LOCATION,
  UPDATE_USER_PROFILE,
  RESET_PASSWORD,
  RESEND_OTP,
  VALIDATE_UPDATE_USER_PROFILE,
  VERIFY_USER_OTP,
  GET_ALL_BANNER,
  UPLOAD_USER_PROFILE,
  POST_CATEGORIES,
} from "./constants";

import {
  verifySession as verifySessionAction,
  verifySessionSuccess,
  verifySessionError,
  logInSuccess,
  logInError,
  logOutSuccess,
  logOutError,
  signUpError,
  forgotPasswordError,
  forgotPasswordSuccess,
  changePasswordError,
  changePasswordSuccess,
  signUpSuccess,
  otpVerificationSuccess,
  otpVerificationError,
  sendOtpSuccess,
  sendOtpError,
  geoLocationError,
  geoLocationSuccess,
  updateUserProfile as updateUserProfileAction,
  updateUserProfileSuccess,
  updateUserProfileError,
  resetPasswordSuccess,
  resetPasswordError,
  validateUserProfileSuccess,
  validateUserProfileError,
  verifyUserOtpSuccess,
  verifyUserOtpError,
  getAllBanner as getAllBannerAction,
  getAllBannerSuccess,
  getAllBannerError,
  uploadUserProfileSuccess,
  uploadUserProfileError,
  postCategories as postCategoriesAction,
  postCategoriesSuccess,
  postCategoriesError,
} from "./actions";

import {
  logIn,
  logOut,
  verifySession,
  signUp,
  forgotPassword,
  changePassword,
  otpVerification,
  sendVerificationCode,
  updateUserProfile,
  resetPassword,
  resendOtp,
  sendUserOtp,
  verifyUserOtp,
  getAllbanner,
  loadPostCategories,
} from "./remotes";

import {
  selectUser,
  selectForm,
  selectLoggedIn,
  selectIsMobileDevice,
  selectGeoLocation,
} from "./selectors";

import {
  loadProducts as loadProductsAction,
  loadProduct as loadProductAction,
  clearProductsRecords,
  setHeadersData,
  productFilter as productFilterAction,
} from "store/products/actions";

import { getDefaultHeaders, scrollToTop } from "utils/tools";

export function* verifyInitialSessionSaga() {
  const secret = store2.get("secret");

  if (secret) {
    try {
      setAuthToken(secret);
      store2.set("secret", secret);
      const user = yield call(verifySession);
      yield put(verifySessionSuccess(user));

      if (user && ((user?.latitude && user?.longitude) || user?.radius)) {
        yield put(
          geoLocationSuccess(
            Object.assign({
              coordinates: {
                lat: user?.latitude || null,
                lng: user?.longitude || null,
              },
              locationName: user?.address || null,
              kiloMeter: user?.radius || null,
            }),
          ),
        );
      }

      yield put(getAllBannerAction(true));

      const accept = store2.get("cookieConsent");
      if (accept) {
        Cookies.set("user", JSON.stringify(user), { path: "/" });
      }
    } catch (error) {
      store2.remove("secret");
      yield put(verifySessionError("Error"));
    }
  } else {
    try {
      const location = store2.get("location");
      if (
        location &&
        ((location?.latitude && location?.longitude) || location?.radius)
      ) {
        yield put(
          geoLocationSuccess(
            Object.assign({
              coordinates: {
                lat: location?.latitude || null,
                lng: location?.longitude || null,
              },
              locationName: location?.address || null,
              kiloMeter: location?.radius || null,
            }),
          ),
        );
      }
      yield put(logOutSuccess());
      yield put(getAllBannerAction(true));
    } catch (error) {
      yield put(logOutError(error));
    } finally {
      store2.remove("secret");
      store2.remove("cookies");
    }
  }
}

export function* verifySessionSaga() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const { secret } = yield take(VERIFY_SESSION);
    if (secret) {
      try {
        yield put(getAllBannerAction(true));
        setAuthToken(secret);
        const user = yield call(verifySession);
        yield put(verifySessionSuccess(user));
        if (user && user?.latitude && user?.longitude) {
          yield put(
            geoLocationSuccess(
              Object.assign({
                coordinates: { lat: user?.latitude, lng: user?.longitude },
                locationName: user?.address,
                kiloMeter: user?.radius,
              }),
            ),
          );
        }
      } catch (error) {
        yield put(verifySessionError("Error"));
      }
    } else {
      try {
        removeAuthToken();
        yield put(logOutSuccess());
        yield put(getAllBannerAction(true));
      } catch (error) {
        yield put(logOutError(error));
      } finally {
        store2.remove("secret");
        store2.remove("cookies");
      }
    }
  }
}

export function* loginSaga() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const { record, closeModal, form, customObj } = yield take(LOG_IN);
    yield put(startSubmit(form));
    store2.remove("email");

    try {
      const result = yield call(logIn, record);
      if (result) {
        yield call(closeModal, false);
        yield call(scrollToTop);
        yield delay(500);
        store2.set("email", record?.username);
        const redirect = result?.sample_url;
        const token = result?.authToken;
        if (redirect) {
          window.open(redirect, "_self");
          yield put(
            logInSuccess("Verification details sent through the email."),
          );
        }

        if (token) {
          yield put(
            otpVerificationSuccess(token, "User logged in successfully"),
          );
          yield put(clearProductsRecords());
          setAuthToken(token);
          store2.set("secret", token);
          // if (record?.remember_me) {
          //   store2.set("secret", token);
          // } else {
          //   store2.remove("secret");
          // }
          yield put(verifySessionAction(token));
          yield put(setHeadersData(getDefaultHeaders()))
          yield put(loadProductsAction(true));
          if (customObj && customObj?.preventRedirect && customObj?.productId) {
            yield put(loadProductAction(customObj?.productId));
          }
        }

        yield put(stopSubmit(form));
      }
    } catch (error) {
      yield put(stopSubmit(form));
      const Err =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.error) ||
        "Please provide the correct Details";
      yield put(logInError(Err));
    }
  }
}

export function* logOutSaga() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const watcher = yield take(LOG_OUT);

    if (watcher) {
      try {
        yield put(logOutSuccess());
        yield put(getAllBannerAction(true));
        yield put(postCategoriesAction(true));
        yield put(setHeadersData(getDefaultHeaders()));
        yield put(loadProductsAction(true));
      } catch (error) {
        yield put(logOutError(error));
      } finally {
        store2.remove("secret");
        Cookies.remove("user");
      }
    }
  }
}

export function* signUpSaga() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const { record, closeModal, form } = yield take(SIGN_UP);
    try {
      yield put(startSubmit(form));
      let submitRecord;
      store2.remove("email");
      if (record?.username && parseInt(record?.username)) {
        submitRecord = Object.assign(
          {},
          { ...record },
          { phone: record?.username, country_code: '+971' },
        );
      } else {
        submitRecord = Object.assign(
          {},
          { ...record },
          { email: record?.username },
        );
      }
      const result = yield call(signUp, submitRecord);
      const redirect = result?.sample_url;
      yield put(stopSubmit(form));
      yield call(closeModal, false);
      store2.set("email", record?.username);
      yield put(delay(500));
      if (redirect) {
        window.open(redirect, "_self");
      }
    } catch (error) {
      yield put(stopSubmit(form));
      const Err =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.error) ||
        "Please provide the correct Details";
      yield put(signUpError(Err));
    }
  }
}

export function* forgotPasswordSaga() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const { record, closeModal, form } = yield take(FORGOT_PASSWORD);

    try {
      yield put(startSubmit(form));
      const result = yield call(forgotPassword, record);
      if (result) {
        yield put(forgotPasswordSuccess(result.message));
        yield delay(1000);
        yield put(stopSubmit(form));
        yield call(closeModal, false);
        if (result?.sample_link) {
          window.open(result.sample_link);
        }
      }
    } catch (error) {
      yield put(stopSubmit(form));
      const Err =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.error) ||
        "Please provide the correct Details";
      yield put(forgotPasswordError(Err));
    }
  }
}

export function* changePasswordSaga() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const { record = {}, setLoader, form } = yield take(CHANGE_PASSWORD);
    yield put(startSubmit(form));
    try {
      const result = yield call(changePassword, record);
      yield put(changePasswordSuccess(result.message));
      yield call(setLoader, true);
      yield put(stopSubmit(form));
      yield put(destroy(form));
      yield delay(2000);
      yield call(setLoader, false);
    } catch (error) {
      const Err =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.error) ||
        "Please provide the correct Details";
      yield put(stopSubmit(form));
      yield put(changePasswordError(Err));
    }
  }
}

export function* otpVerificationSaga() {
  yield takeLatest(OTP_VERIFICATION, function* ({ record, closeModal, form }) {
    if (record) {
      yield put(startSubmit(form));
      try {
        const result = yield call(otpVerification, record);
        if (result) {
          const token = result?.authToken;
          yield put(
            otpVerificationSuccess(token, "User logged in successfully"),
          );
          yield put(clearProductsRecords());
          setAuthToken(token);
          store2.set("secret", token);
          yield put(verifySessionAction(token));
          yield put(
            push({ pathname: "/", state: { ...history.location.state } }),
          );
          yield put(loadProductsAction(true));
          yield put(stopSubmit(form));
          yield call(closeModal, false);
          store2.remove("email");
        }
      } catch (error) {
        yield put(stopSubmit(form));
        yield put(otpVerificationError("Invalid otp code"));
      }
    }
  });
}

export function* sendOtpSaga() {
  yield takeLatest(SEND_OTP, function* ({ record, onError }) {
    if (record) {
      try {
        const result = yield call(sendVerificationCode, record);
        if (result) {
          yield put(sendOtpSuccess("send Code successfully."));
        }
      } catch (error) {
        yield call(onError);
        yield put(sendOtpError("Invalid code"));
      }
    }
  });
}

export function* geoLocationSaga() {
  yield takeLatest(
    GEO_LOCATION,
    function* ({ record, closeModal, setBtnLoader, clearState }) {
      if (record) {
        try {
          const loggedIn = yield select(selectLoggedIn());
          let submitRecord;
          if (record?.type === "clearLocation") {
            submitRecord = Object.assign(
              {},
              {
                latitude: null,
                longitude: null,
                address: null,
                radius: null,
              },
            );
            delete submitRecord?.type;
          } else if (record?.locationName && record?.kiloMeter) {
            submitRecord = Object.assign(
              {},
              {
                latitude: record?.coordinates?.lat,
                longitude: record?.coordinates?.lng,
                address: record?.locationName,
                radius: record?.kiloMeter,
              },
            );
          } else if (record?.locationName) {
            submitRecord = Object.assign(
              {},
              {
                latitude: record?.coordinates?.lat,
                longitude: record?.coordinates?.lng,
                address: record?.locationName,
                radius: null,
              },
            );
          } else if (record?.kiloMeter) {
            submitRecord = Object.assign(
              {},
              {
                latitude: null,
                longitude: null,
                address: null,
                radius: record?.kiloMeter,
              },
            );
          } else {
            submitRecord = Object.assign(
              {},
              {
                latitude: record?.coordinates?.lat || null,
                longitude: record?.coordinates?.lng || null,
                address: record?.locationName || null,
                radius: record?.kiloMeter || null,
              },
            );
          }
          const storeLocation = yield select(selectGeoLocation()) || {};
          if (loggedIn) {
            const user_info = yield select(selectUser());
            const result = yield call(
              updateUserProfile,
              Object.assign({}, { ...user_info }, { ...submitRecord }),
            );

            if (result) {
              yield put(updateUserProfileSuccess(result, false));
              yield put(
                geoLocationSuccess(
                  Object.assign({}, { ...storeLocation }, { ...record }),
                ),
              );
              yield put(clearProductsRecords());
              yield put(setHeadersData(getDefaultHeaders()));
              yield put(getAllBannerAction(true));
              yield put(productFilterAction("new_products"));
              yield put(loadProductsAction(true));
              yield delay(2000);
              yield call(closeModal, false);
            }
          } else {
            let location = store2.get("location");
            if (location) {
              submitRecord = Object.assign(
                {},
                { ...location },
                { ...submitRecord },
              );
            }

            yield put(
              geoLocationSuccess(
                Object.assign({}, { ...storeLocation }, { ...record }),
              ),
            );

            store2.set("location", Object.assign({}, { ...submitRecord }));
            yield put(clearProductsRecords());
            yield put(setHeadersData(getDefaultHeaders()));
            yield put(getAllBannerAction(true));
            yield put(productFilterAction("new_products"));
            yield put(loadProductsAction(true));
            yield delay(2000);
            yield call(closeModal, false);
          }
        } catch (error) {
          yield put(geoLocationError("GeoLocation not supported"));
        } finally {
          if (setBtnLoader) {
            yield call(setBtnLoader, false);
          }
          if (clearState) {
            yield call(clearState.setValue, false);
            yield call(clearState.setKiloMeter, false);
          }
        }
      }
    },
  );
}

export function* updateUserProfileSaga() {
  yield takeLatest(
    UPDATE_USER_PROFILE,
    function* ({ record, setShowForm, form }) {
      if (record) {
        yield put(startSubmit(form));
        try {
          let submitRecord;
          if (record?.phone) {
            submitRecord = Object.assign(
              {},
              { ...record },
              {
                country_code: '+971',
              },
            );
          } else {
            submitRecord = record;
          }
          const result = yield call(updateUserProfile, submitRecord);
          if (result) {
            yield call(setShowForm, false);
            yield put(stopSubmit(form));
            yield put(
              updateUserProfileSuccess(
                result,
                "User profile updated successfully.",
              ),
            );
          }
        } catch (error) {
          const Err =
            error.response &&
            error.response.data &&
            error.response.data.error &&
            typeof error.response.data.error === "string"
              ? error.response.data.error
              : "Failed to update the user profile.";
          yield put(stopSubmit(form));
          yield put(updateUserProfileError(Err));
        }
      }
    },
  );
}

export function* resetPasswordSaga() {
  yield takeLatest(
    RESET_PASSWORD,
    function* updater({ record, closeModal, form }) {
      if (record) {
        yield put(startSubmit(form));
        try {
          const result = yield call(resetPassword, record);
          if (result)
            yield put(resetPasswordSuccess("Password successfully reseted"));
          yield delay(2000);
          yield call(closeModal, false);
          yield put(
            push({ pathname: "/", state: { ...history.location.state } }),
          );
        } catch (error) {
          yield put(stopSubmit(form));
          yield put(resetPasswordError("Failed to reset the password."));
        }
      }
    },
  );
}

export function* resendOtpSaga() {
  yield takeLatest(RESEND_OTP, function* updater({ record }) {
    if (record) {
      try {
        const result = yield call(resendOtp, record);
        if (result) yield put(resetPasswordSuccess(`Verification code sent to ${store2.get('email')}`));
      } catch (error) {
        yield put(resetPasswordError("Failed to send otp."));
      }
    }
  });
}

export function* validateUserProfileSaga() {
  yield takeLatest(
    VALIDATE_UPDATE_USER_PROFILE,
    function* ({ record, setShowForm, form, showPopup }) {
      if (record) {
        yield put(startSubmit(form));
        try {
          const user_info = yield select(selectUser());
          if (
            (record?.phone || record?.email) &&
            (record?.phone !== user_info?.phone ||
              record?.email !== user_info?.email)
          ) {
            store2.set(
              "email",
              record?.email !== user_info?.email ? record.email : record?.phone,
            );
            yield call(
              sendUserOtp,
              Object.assign(
                {},
                {
                  id: record.id,
                  username:
                    record?.email !== user_info?.email
                      ? record.email
                      : record?.phone,
                },
              ),
            );
            yield call(showPopup, true);
            yield put(stopSubmit(form));
          } else {
            yield put(updateUserProfileAction(record, setShowForm, form));
          }
        } catch (error) {
          yield put(stopSubmit(form));
          yield put(validateUserProfileError("Failed to sent the otp."));
        }
      }
    },
  );
}

export function* verifyUserOtpSaga() {
  yield takeLatest(
    VERIFY_USER_OTP,
    function* ({ record, setShowPopup, setShowForm, form }) {
      if (record) {
        yield put(startSubmit(form));
        try {
          const user_info = yield select(selectUser());
          const forms = yield select(selectForm());
          const result = yield call(
            verifyUserOtp,
            Object.assign({}, { ...record }, { id: user_info?.id }),
          );

          const formRecord =
            (forms &&
              forms["profileEditForm"] &&
              forms["profileEditForm"]?.values) ||
            false;

          if (result) {
            store2.remove("email");
            yield call(setShowPopup, false);
            yield put(
              updateUserProfileAction(
                formRecord,
                setShowForm,
                "profileEditForm",
              ),
            );
          }
        } catch (error) {
          const Err =
            error.response &&
            error.response.data &&
            error.response.data.error &&
            typeof error.response.data.error === "string"
              ? error.response.data.error
              : "Invalid otp";
          yield put(verifyUserOtpError(Err));
        } finally {
          yield put(stopSubmit(form));
        }
      }
    },
  );
}

export function* getAllBannerSaga() {
  yield takeLatest(GET_ALL_BANNER, function* ({ load }) {
    if (load) {
      try {
        const loggedIn = yield select(selectLoggedIn());
        const geoLocation = yield select(selectGeoLocation());
        const result = yield call(getAllbanner, loggedIn, geoLocation);
        if (result?.response) {
          const isMobile = yield select(selectIsMobileDevice());
          let submitRecord = result?.response;
          if (isMobile) {
            submitRecord = submitRecord?.map((_) =>
              Object.assign({}, { url: _.banner_mobile_url, page: _.page }),
            );
          } else {
            submitRecord = submitRecord?.map((_) =>
              Object.assign({}, { url: _.banner_desktop_url, page: _.page }),
            );
          }

          yield put(getAllBannerSuccess(submitRecord));
        }
      } catch (error) {
        yield put(getAllBannerError("Failed to load banner"));
      }
    }
  });
}

export function* updateUserProfileImageSaga() {
  yield takeLatest(UPLOAD_USER_PROFILE, function* ({ record }) {
    if (record) {
      try {
        const result = yield call(updateUserProfile, record);
        if (result) {
          // yield put(uploadUserProfileSuccess(result));
          yield put(verifySessionAction(store2.get('secret')));
        }
      } catch (error) {
        const Err =
          error.response &&
          error.response.data &&
          error.response.data.error &&
          typeof error.response.data.error === "string"
            ? error.response.data.error
            : "Failed to update the user profile image.";

        yield put(uploadUserProfileError(Err));
      }
    }
  });
}

export function* postCategoriesSaga() {
  yield takeLatest(POST_CATEGORIES, function* updater({ load }) {
    if (load) {
      try {
        const loggedIn = yield select(selectLoggedIn());
        const postCategories = yield call(loadPostCategories, loggedIn);
        if (postCategories) {
          const categories =
            postCategories &&
            postCategories.response &&
            postCategories.response.length > 0
              ? postCategories.response
              : [];
          const postCategoriesOptions = lodash
            .orderBy(
              categories.map(c => Object.assign({}, c, { order: parseInt(c.order)})).filter((_) => !_.parent),
              "order",
            )
            .map((c) => {
              return Object.assign({}, c, {
                value: c.id,
                title: c.category_name,
                disabled:
                  categories.filter((_) => _.parent === c.id).length > 0
                    ? true
                    : false,
                children: lodash
                  .orderBy(
                    categories.filter((_) => _.parent === c.id),
                    "order",
                  )
                  .map((c2) =>
                    Object.assign({}, c2, {
                      value: c2.id,
                      title: c2.category_name,
                      disabled:
                        categories.filter((_) => _.parent === c2.id).length > 0
                          ? true
                          : false,
                      children: lodash
                        .orderBy(
                          categories.filter((_) => _.parent === c2.id),
                          "order",
                        )
                        .map((c3) =>
                          Object.assign({}, c3, {
                            value: c3.id,
                            title: c3.category_name,
                            children: lodash.orderBy(
                              categories.filter((_) => _.parent === c3.id),
                              "order",
                            ),
                          }),
                        ),
                    }),
                  ),
              });
            });

          if (postCategoriesOptions) {
            yield put(postCategoriesSuccess(postCategoriesOptions, categories));
          }
        }
      } catch (error) {
        yield put(postCategoriesError("Failed to load post category"));
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    verifyInitialSessionSaga(),
    verifySessionSaga(),
    loginSaga(),
    logOutSaga(),
    signUpSaga(),
    forgotPasswordSaga(),
    changePasswordSaga(),
    otpVerificationSaga(),
    sendOtpSaga(),
    geoLocationSaga(),
    updateUserProfileSaga(),
    resetPasswordSaga(),
    resendOtpSaga(),
    validateUserProfileSaga(),
    verifyUserOtpSaga(),
    getAllBannerSaga(),
    updateUserProfileImageSaga(),
    postCategoriesSaga(),
  ]);
}
