import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import leftArrow from "assets/images/carousal/leftArrow.svg";
import rightArrow from "assets/images/carousal/rightArrow.svg";
import circle from "assets/images/carousal/circle.svg";
import inner from "assets/images/carousal/inner.svg";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  containerStyles,
  slideStyles,
  rightArrowStyles,
  leftArrowStyles,
  sliderStyles,
  dotsContainerStyles,
  dotStyle,
  activeDot,
  mobileContainerStyles,
  mobileSlideStyles,
  customDotsContainerStyles,
  dotsChildGrid,
} from "./styles";
import ImageGallery from "react-image-gallery";

const Styles = makeStyles((theme) => ({
  slideStyles: (props) => {
    return {
      "& img": props.slideStylesWidthBackground,
      "& .image-gallery-slides div": props.divSlider,
    };
  },
}));

function ImageSlider(props) {
  const {
    isMobile,
    banner,
    customStyle,
    plainSlider = false,
    customAspect,
    isResponsive,
    page,
    id
  } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      goToNext();
    }, 5000);
    return () => clearTimeout(timer);
  }, [currentIndex]);

  const slides = banner || [];

  const silder = isMobile ? mobileSlideStyles : slideStyles;

  let slideStylesWidthBackground = {
    ...silder,
    transition: "2s",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    objectFit: sm ? "fill !important" : "cover !important",
    objectPosition: "center",
    width: "100% !important",
    margin: "0px",
    opacity: 1,
    alignItems: "stretch",
    backgroundRepeat: "no-repeat",
    maxHeight: "100% !important",
    height: "100%",
  };

  slideStylesWidthBackground = customStyle
    ? {
        ...slideStylesWidthBackground,
        aspectRatio: "211 / 45",
      }
    : customAspect
    ? {
        ...slideStylesWidthBackground,
        aspectRatio: customAspect,
      }
    : {
        ...slideStylesWidthBackground,
        aspectRatio: "211 / 35",
      };

  const divSlider =
    isResponsive && isMobile
      ? { ...slideStylesWidthBackground, minHeight: "auto" }
      : slideStylesWidthBackground;

  const classes = Styles({
    slideStylesWidthBackground:
      page === "profile"
        ? Object.assign({}, slideStylesWidthBackground, {
            objectFit: "fill",
          })
        : slideStylesWidthBackground,
    divSlider,
  });
  return (
    <Grid item xl={12}>
      <div style={isMobile ? mobileContainerStyles : containerStyles}>
        <div style={{ ...sliderStyles }}>
          {!md && !plainSlider && slides && slides?.length > 1 && (
            <div>
              <div onClick={goToPrevious} style={leftArrowStyles}>
                <img src={leftArrow} style={{ height: "13px" }} alt="left arrow" />
              </div>
              <div onClick={goToNext} style={rightArrowStyles}>
                <img src={rightArrow} style={{ height: "13px" }} alt="right arrow" />
              </div>
            </div>
          )}
          <div className={classes.sliderGrid} id={id}>
            <ImageGallery
              items={slides.map((p) =>
                Object.assign({}, { original: p, thumbnail: p }),
              )}
              showNav={false}
              startIndex={currentIndex}
              autoPlay={false}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              fullscreen={true}
              className={classes.slideStyles}
              additionalClass={classes.slideStyles}
              onSlide={(slideIndex) => setCurrentIndex(slideIndex)}
            />
            {!plainSlider && slides && slides?.length > 1 && !sm ? (
              <div
                style={
                  customStyle ? customDotsContainerStyles : dotsContainerStyles
                }
              >
                <div style={dotsChildGrid}>
                  {slides.map((slide, slideIndex) => {
                    return (
                      <div
                        style={dotStyle}
                        key={slideIndex}
                        onClick={() => goToSlide(slideIndex)}
                      >
                        <img
                          src={circle}
                          style={{ height: "10px", width: "10px" }}
                          alt="circle"
                        />
                        {currentIndex === slideIndex && (
                          <img
                            src={inner}
                            style={{
                              height: "5px",
                              width: "5px",
                              ...activeDot,
                            }}
                            alt="inner"
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default ImageSlider;
