import { makeStyles, withStyles } from "@mui/styles";
import { ListItem } from "@mui/material";

export const StyledListItem = withStyles({
  root: {
    "&$selected": {
      color: localStorage.getItem("accentColor") + " !important",
      backgroundColor: "transparent",
    },
    "&:hover": {
      backgroundColor: "#EEEEEE",
    },
    "&.MuiListItemIcon-root": {
      minHeight: "40px",
    },
    "&.MuiListItem-button": {
      minHeight: "48px",
    },
    "&.MuiListItem-root.Mui-selected": {
      color: localStorage.getItem("accentColor") + " !important",
      backgroundColor: "transparent",
    },
  },
  selected: {},
})(ListItem);

const useStyles = makeStyles((theme) => {
  let accentColor = String(theme.palette.accentColor.main);
  return ({
    drawerPaperFixed: {
      width: "85%",
      background: "#F5F5F5",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: 0,
      },
    },
    userProfile: {
      background: accentColor,
      height: "270px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    list: {
      margin: "20px",
    },
    listBox: {
      background: "#ffffff",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "7px",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      boxSizing: "border-box",
      padding: "7px",
      "& .MuiMenuItem-root": {
        gap: "10px",
      },
    },
    userName: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "25px",
      lineHeight: "35px",
      textAlign: "center",
      color: "#FFFFFF",
      textTransform: "capitalize",
      letterSpacing: "-0.015em",
    },
    email: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "17px",
      lineHeight: "23px",
      textAlign: "center",
      color: "#FFFFFF",
      textTransform: "lowercase",
      letterSpacing: "-0.015em",
    },
    svg: {
      width: "20px",
      height: "20px",
      cursor: "pointer",
      "& path": {
        fill: "#FFFFFF",
      },
    },
    listLabel: {
      "@global": {
        span: {
          fontFamily: "Poppins",
        },
      },
    },
  })
});

export default useStyles;
