import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    font-family: var(--font);
    // background-color: #f9f9f9;
  }

  #app {
    background-color: #f9f9f9;
    min-height: 100%;
    min-width: 100%;
    font-family: var(--font);
  }

  p,
  label {
    font-family: var(--font);
    line-height: 1.5em;
  }

  a {
    text-decoration: none;
  } 

`;

export default GlobalStyle;
