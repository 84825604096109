import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#1D2846",
    boxSizing: "border-box",
    padding: "40px",
    position: "fixed",
    zIndex: 1000,
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  cookiesImg: {
    width: "65px",
    [theme.breakpoints.down("sm")]: {
      width: "45px",
      fontSize: "16px",
    },
  },
  contentTitle: {
    fontFamily: "Poppins-Bold",
    fontStyle: "normal",
    fontSize: "20px",
    textAlign: "center",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  content: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    textAlign: "center",
    color: "#FFFFFF",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
      width: "100%",
      textAlign: "left"
    },
  },
  cookiesBtn: {
    minWidth: "148px",
    borderRadius: "7px",
    padding: "10px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      width: "70%"
    },
  },
  customBtn: {
    minWidth: "148px",
    borderRadius: "7px",
    padding: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    textAlign: "center",
    color: "#FFFFFF",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      width: "70%"
    },
    buttons: {
      [theme.breakpoints.down("sm")]: {
        display: 'flex',
        flexDirection: 'row'
      }
    }
  },
  contentGrid: {
    display: "flex",
    justifyContent: "center"
  }
}));

export default useStyles;
