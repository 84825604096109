/*
 *
 * products constants
 *
 */

const name = "products";

export const LOAD_PRODUCTS_VALID_CACHE = `lg/${name}/LOAD_PRODUCTS_VALID_CACHE`;

export const LOAD_PRODUCTS = `lg/${name}/LOAD_PRODUCTS`;
export const LOAD_PRODUCTS_SUCCESS = `lg/${name}/LOAD_PRODUCTS_SUCCESS`;
export const LOAD_PRODUCTS_ERROR = `lg/${name}/LOAD_PRODUCTS_ERROR`;

export const LOAD_PRODUCT = `lg/${name}/LOAD_PRODUCT`;
export const LOAD_PRODUCT_SUCCESS = `lg/${name}/LOAD_PRODUCT_SUCCESS`;
export const LOAD_PRODUCT_ERROR = `lg/${name}/LOAD_PRODUCT_ERROR`;

export const SET_HEADERS_DATA = `lg/${name}/SET_HEADERS_DATA`;

export const WISHLIST_PRODUCT = `lg/${name}/WISHLIST_PRODUCT`;
export const WISHLIST_PRODUCT_SUCCESS = `lg/${name}/WISHLIST_PRODUCT_SUCCESS`;
export const WISHLIST_PRODUCT_ERROR = `lg/${name}/WISHLIST_PRODUCT_ERROR`;

export const ADD_WISHLIST_PRODUCT = `lg/${name}/ADD_WISHLIST_PRODUCT`;
export const ADD_WISHLIST_PRODUCT_SUCCESS = `lg/${name}/ADD_WISHLIST_PRODUCT_SUCCESS`;
export const ADD_WISHLIST_PRODUCT_ERROR = `lg/${name}/ADD_WISHLIST_PRODUCT_ERROR`;

export const DELETE_WISHLIST_PRODUCT = `lg/${name}/DELETE_WISHLIST_PRODUCT`;
export const DELETE_WISHLIST_PRODUCT_SUCCESS = `lg/${name}/DELETE_WISHLIST_PRODUCT_SUCCESS`;
export const DELETE_WISHLIST_PRODUCT_ERROR = `lg/${name}/DELETE_WISHLIST_PRODUCT_ERROR`;

export const CLEAR_PRODUCTS_RECORDS = `lg/${name}/CLEAR_PRODUCTS_RECORDS`;

export const REMOVE_WISHLIST_PRODUCT = `lg/${name}/REMOVE_WISHLIST_PRODUCT`;
export const REMOVE_WISHLIST_PRODUCT_SUCCESS = `lg/${name}/REMOVE_WISHLIST_PRODUCT_SUCCESS`;
export const REMOVE_WISHLIST_PRODUCT_ERROR = `lg/${name}/REMOVE_WISHLIST_PRODUCT_ERROR`;

export const SEARCH_PRODUCTS = `lg/${name}/SEARCH_PRODUCTS`;
export const SEARCH_PRODUCTS_ERROR = `lg/${name}/SEARCH_PRODUCTS_ERROR`;
export const SEARCH_PRODUCTS_SUCCESS = `lg/${name}/SEARCH_PRODUCTS_SUCCESS`;

export const CREATE_USER_COMMENT = `lg/${name}/CREATE_USER_COMMENT`;
export const CREATE_USER_COMMENT_ERROR = `lg/${name}/CREATE_USER_COMMENT_ERROR`;
export const CREATE_USER_COMMENT_SUCCESS = `lg/${name}/CREATE_USER_COMMENT_SUCCESS`;

export const PRODUCTS_DAY_FILTER = `lg/${name}/PRODUCTS_DAY_FILTER`;
export const PRODUCTS_DAY_FILTER_ERROR = `lg/${name}/PRODUCTS_DAY_FILTER_ERROR`;
export const PRODUCTS_DAY_FILTER_SUCCESS = `lg/${name}/PRODUCTS_DAY_FILTER_SUCCESS`;

export const PRODUCT_FILTER = `lg/${name}/PRODUCT_FILTER`;
export const PRODUCT_FILTER_SUCCESS = `lg/${name}/PRODUCT_FILTER_SUCCESS`;
export const PRODUCT_FILTER_ERROR = `lg/${name}/PRODUCT_FILTER_ERROR`;

export const CREATE_PRODUCT_LIKE = `lg/${name}/CREATE_PRODUCT_LIKE`;
export const CREATE_PRODUCT_LIKE_SUCCESS = `lg/${name}/CREATE_PRODUCT_LIKE_SUCCESS`;
export const CREATE_PRODUCT_LIKE_ERROR = `lg/${name}/CREATE_PRODUCT_LIKE_ERROR`;

export const CREATE_PRODUCT_UNLIKE = `lg/${name}/CREATE_PRODUCT_UNLIKE`;
export const CREATE_PRODUCT_UNLIKE_SUCCESS = `lg/${name}/CREATE_PRODUCT_UNLIKE_SUCCESS`;
export const CREATE_PRODUCT_UNLIKE_ERROR = `lg/${name}/CREATE_PRODUCT_UNLIKE_ERROR`;

export const DELETE_USER_COMMENT = `lg/${name}/DELETE_USER_COMMENT`;
export const DELETE_USER_COMMENT_SUCCESS = `lg/${name}/DELETE_USER_COMMENT_SUCCESS`;
export const DELETE_USER_COMMENT_ERROR = `lg/${name}/DELETE_USER_COMMENT_ERROR`;

export const EDIT_USER_COMMENT = `lg/${name}/EDIT_USER_COMMENT`;
export const EDIT_USER_COMMENT_SUCCESS = `lg/${name}/EDIT_USER_COMMENT_SUCCESS`;
export const EDIT_USER_COMMENT_ERROR = `lg/${name}/EDIT_USER_COMMENT_ERROR`;

export const ADD_WISHLIST_PRODUCT_LIKE = `lg/${name}/ADD_WISHLIST_PRODUCT_LIKE`;
export const ADD_WISHLIST_PRODUCT_UNLIKE = `lg/${name}/ADD_WISHLIST_PRODUCT_UNLIKE`;

export const ADD_WISHLIST_RELATED_PRODUCT = `lg/${name}/ADD_WISHLIST_RELATED_PRODUCT`;
export const ADD_WISHLIST_RELATED_PRODUCT_SUCCESS = `lg/${name}/ADD_WISHLIST_RELATED_PRODUCT_SUCCESS`;
export const ADD_WISHLIST_RELATED_PRODUCT_ERROR = `lg/${name}/ADD_WISHLIST_RELATED_PRODUCT_ERROR`;

export const DELETE_WISHLIST_RELATED_PRODUCT = `lg/${name}/DELETE_WISHLIST_RELATED_PRODUCT`;
export const DELETE_WISHLIST_RELATED_PRODUCT_SUCCESS = `lg/${name}/DELETE_WISHLIST_RELATED_PRODUCT_SUCCESS`;
export const DELETE_WISHLIST_RELATED_PRODUCT_ERROR = `lg/${name}/DELETE_WISHLIST_RELATED_PRODUCT_ERROR`;

export const LOAD_BANNER = `lg/${name}/LOAD_BANNER`;
export const LOAD_BANNER_SUCCESS = `lg/${name}/LOAD_BANNER_SUCCESS`;
export const LOAD_BANNER_ERROR = `lg/${name}/LOAD_BANNER_ERROR`;

export const PRODUCT_FILTER_PARAMS = `lg/${name}/PRODUCT_FILTER_PARAMS`;
export const UPDATE_FILTER_RECORDS = `lg/${name}/UPDATE_FILTER_RECORDS`;

export const ADD_WISHLIST_FILTER_PAGE = `lg/${name}/ADD_WISHLIST_FILTER_PAGE`;
export const ADD_WISHLIST_FILTER_PAGE_ERROR = `lg/${name}/ADD_WISHLIST_FILTER_PAGE_ERROR`;
export const ADD_WISHLIST_FILTER_PAGE_SUCCESS = `lg/${name}/ADD_WISHLIST_FILTER_PAGE_SUCCESS`;

export const DELETE_WISHLIST_FILTER_PAGE = `lg/${name}/DELETE_WISHLIST_FILTER_PAGE`;
export const DELETE_WISHLIST_FILTER_PAGE_ERROR = `lg/${name}/DELETE_WISHLIST_FILTER_PAGE_ERROR`;
export const DELETE_WISHLIST_FILTER_PAGE_SUCCESS = `lg/${name}/DELETE_WISHLIST_FILTER_PAGE_SUCCESS`;

export const CLICK_TRACKING = `lg/${name}/CLICK_TRACKING`;
export const CLICK_TRACKING_ERROR = `lg/${name}/CLICK_TRACKING_ERROR`;
export const CLICK_TRACKING_SUCCESS = `lg/${name}/CLICK_TRACKING_SUCCESS`;

export const SHARE_TRACKING = `lg/${name}/SHARE_TRACKING`;
export const SHARE_TRACKING_ERROR = `lg/${name}/SHARE_TRACKING_ERROR`;
export const SHARE_TRACKING_SUCCESS = `lg/${name}/SHARE_TRACKING_SUCCESS`;

export const CREATE_RELATED_PRODUCT_LIKE = `lg/${name}/CREATE_RELATED_PRODUCT_LIKE`;
export const CREATE_RELATED_PRODUCT_LIKE_SUCCESS = `lg/${name}/CREATE_RELATED_PRODUCT_LIKE_SUCCESS`;
export const CREATE_RELATED_PRODUCT_LIKE_ERROR = `lg/${name}/CREATE_RELATED_PRODUCT_LIKE_ERROR`;

export const CREATE_RELATED_PRODUCT_UNLIKE = `lg/${name}/CREATE_RELATED_PRODUCT_UNLIKE`;
export const CREATE_RELATED_PRODUCT_UNLIKE_SUCCESS = `lg/${name}/CREATE_RELATED_PRODUCT_UNLIKE_SUCCESS`;
export const CREATE_RELATED_PRODUCT_UNLIKE_ERROR = `lg/${name}/CREATE_RELATED_PRODUCT_UNLIKE_ERROR`;

export const CLEAR_SEARCH_RECORD = `lg/${name}/CLEAR_SEARCH_RECORD`;

// export const POST_CATEGORIES = `lg/${name}/POST_CATEGORIES`;
// export const POST_CATEGORIES_SUCCESS = `lg/${name}/POST_CATEGORIES_SUCCESS`;
// export const POST_CATEGORIES_ERROR = `lg/${name}/POST_CATEGORIES_ERROR`;
