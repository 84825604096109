// global objects

import './config';

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

// import getColors from "./theme/"

import { Provider } from "react-redux";
import history from "utils/history";

import Router from "router";
import store from "store";
import { getColors, getTheme, defaultTheme } from "theme";

// Styles
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider } from "@mui/material/styles";
import "./main.css";
import GlobalStyle from "./global-styles";

//CSS
import "react-image-gallery/styles/css/image-gallery.css";
import { useState } from "react";
import { func } from "prop-types";
import { Helmet } from 'react-helmet';

import FloatWhatsapp from './components/FloatWhatsapp/index';
// import posthog from "posthog-js";

// posthog.init("phc_wnOYaUhloRjFbSiX4Vdskyk5geDIhVjGMndZBFQkcS0", {
//   api_host: "https://app.posthog.com",
// });

const App = () => {

  const [theme, setTheme] = useState(defaultTheme);
  useEffect(() => {
    async function handleLayout() {
      let response = await getColors();
      let themed = await getTheme(response);
      setTheme(themed);
    }
    handleLayout();

  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <StyledEngineProvider injectFirst>
            <GlobalStyle />
            <CssBaseline />
            <Router store={store} history={history} />
          </StyledEngineProvider>
        </Provider>
      </ThemeProvider>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
