import moment from "moment";
import React from "react";
import lodash from "lodash";
const CryptoJS = require("crypto-js");
const base64 = require("base-64");

/**
 *
 * @param {string} id
 */
export function getOffset(id) {
  if (!id) {
    return false;
  }
  const element = document.getElementById(id);
  return (element && element.offsetHeight) || 0;
}

/**
 *
 * @param {object} field
 */
export function normalize(field) {
  return (val) => {
    if (field.type === "phone") {
      let value = val.replace(/[^\d\(\)\-]/g, "");
      return field.max && val.length > field.max
        ? val.substring(0, field.max)
        : value;
    } else {
      return field.dollar && val.match(/^[-+]?[0-9]+\.[0-9]+[0-9]$/)
        ? parseFloat(val).toFixed(2)
        : field.max && val.length > field.max
        ? val.substring(0, field.max)
        : val;
    }
  };
}

export function detectMobile() {
  return (
    window.innerWidth <= 599 &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  );
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export function getDefaultHeaders() {
  return {
    offset: 0,
    limit: 25,
    search: false,
    filter: false,
    sort: false,
    page: 1,
  };
}

export function activationCode(history) {
  const location = history?.location?.search || false;
  const search =
    (location && location.includes("&") && location?.split("&")) || false;
  const activation_code =
    (search?.length > 0 &&
      search[0] &&
      search[0]?.includes("=") &&
      search[0]?.split("=")[1]) ||
    false;

  return activation_code;
}

/**
 * capitalize only the first letter of the string.
 * @param {string} string
 */
export function capitalizeFirstLetter(string) {
  return (string && string.charAt(0).toUpperCase() + string.slice(1)) || string;
}

export function abbreviateLikes(val) {
  const likes = parseInt(val);
  if (likes >= 1e9) {
    return Math.floor((likes / 1e9).toFixed(1)) + "B";
  }
  if (likes >= 1e6) {
    return Math.floor((likes / 1e6).toFixed(1)) + "M";
  }
  if (likes >= 1e3) {
    return Math.floor((likes / 1e3).toFixed(1)) + "K";
  }
  return likes;
}

// export function dayDiff(date1, date2) {
//   let day1 = moment();
//   let day2 = moment(date2);
//   if (day1.format("YYYY-MM-DD") === day2.format("YYYY-MM-DD")) {
//     const startTime = moment(date1, "YYYY-MM-DD HH:mm");
//     const endTime = moment(date2, "YYYY-MM-DD HH:mm");
//     const hours = endTime.diff(startTime, "hours");
//     return { val: hours, suffix: "h" };
//   } else if (day2.startOf("day").diff(day1.startOf("day"), "days") > 1) {
//     return {
//       val: day2.startOf("day").diff(day1.startOf("day"), "days"),
//       suffix: "ds",
//     };
//   } else if (day2.startOf("day").diff(day1.startOf("day"), "days") <= 0) {
//     return {
//       val: 0,
//       suffix: "d",
//     };
//   } else {
//     return {
//       val: day2.startOf("day").diff(day1.startOf("day"), "days"),
//       suffix: "d",
//     };
//   }
// }

export function dayDiff(hours, mins, secs) {
  if (hours && hours <= 23) {
    return { val: parseInt(hours), suffix: "h" };
  } else if (hours && hours > 23) {
    if (hours / 24 >= 2) {
      return { val: Math.floor(hours / 24), suffix: "ds" };
    }
    return { val: Math.floor(hours / 24), suffix: "d" };
  } else if (!hours && mins) {
    return { val: parseInt(mins), suffix: "m" };
  } else if (!hours && !mins && secs) {
    return { val: parseInt(secs), suffix: "s" };
  }
  return {
    val: 0,
    suffix: "m",
  };
}

export function checkString(str, limit, cardCheck) {
  if (cardCheck) {
    return str?.length > limit ? str.slice(0, limit) + "..." : str;
  } else {
    return str?.length > 10 ? str.slice(0, 7) + "..." : str;
  }
}

// export function calculateDaysDifference(startDate, endDate) {
//   const startMoment = moment(startDate);
//   const endMoment = moment(endDate);
//   const daysDifference = endMoment.diff(startMoment, "days");
//   return daysDifference;
// }

// export function calculateDifferenceWithUnits(startDate, endDate) {
//   const startMoment = moment(startDate);
//   const endMoment = moment(endDate);

//   const daysDifference = endMoment.diff(startMoment, "days");

//   if (daysDifference >= 30) {
//     const monthsDifference = endMoment.diff(startMoment, "months");
//     return `${monthsDifference} MONTH`;
//   } else if (daysDifference > 1) {
//     return `${daysDifference} DAYS`;
//   } else if (daysDifference == 1) {
//     return `${daysDifference} DAY`;
//   }
// }

/**
 *
 * @param {object} params
 * @returns
 */
export function createQueryString(params) {
  const queryString = Object.keys(params)
    .filter((key) => params[key])
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    )
    .join("&");
  return queryString ? `${queryString}` : "";
}

/**
 *
 * @param {object} obj
 * @returns
 */
export function objectToQueryString(obj) {
  var str = "";
  for (var key in obj) {
    if (str !== "") {
      str += "&";
    }
    str += key + "=" + encodeURIComponent(obj[key]);
  }

  return str;
}

/**
 *
 * @param {string} entries
 * @returns
 */
export function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

/**
 *
 * @param {string} days
 * @returns
 */
export function calculateTimePeriod(days) {
  if (days >= 365) {
    const years = Math.floor(days / 365);
    return years + (years === 1 ? " year" : " years");
  } else if (days >= 30) {
    const months = Math.floor(days / 30);
    const remainingDays = days % 30;
    if (remainingDays === 0) {
      return months + (months === 1 ? " month" : " months");
    } else {
      return `${days} days`;
    }
  } else {
    return days + (days === 1 ? " day" : " days");
  }
}

export function shuffleArray(array) {
  const shuffledArray = [...array];

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
}

/**
 *
 * @param {Array} arr
 * @param {Array} includedArr
 * @returns
 */
export function insertIncludedObjects(arr, includedArr) {
  let includedIndex = 0;

  if (arr.length === 1) {
    const shuffledArray = shuffleArray(includedArr);
    arr.splice(
      arr.length,
      0,
      Object.assign({}, { type: "banner", data: shuffledArray }),
    );
    return arr;
  }

  if (arr.length <= 10 && arr.length > 1) {
    const shuffledArray = shuffleArray(includedArr);
    arr.splice(
      arr.length - 1,
      0,
      Object.assign({}, { type: "banner", data: shuffledArray }),
    );
    return arr;
  }

  for (let i = 0; i < arr.length; i++) {
    if (i % 10 === 0 && i !== 0) {
      if (includedArr.length > 0) {
        const includedObject = includedArr[includedIndex++];
        const shuffledArray = shuffleArray(includedArr);
        arr.splice(
          i,
          0,
          Object.assign({}, { type: "banner", data: shuffledArray }),
        );
      }
    }
  }

  return arr;
}

/**
 *
 * @param {function} callback
 * @param {array} deps
 */
export function useDidUpdate(callback, deps) {
  const hasMount = React.useRef(false);

  React.useEffect(() => {
    if (hasMount.current) {
      callback();
    } else {
      hasMount.current = true;
    }
  }, deps);
}

export function getDeviceType() {
  const userAgent = navigator.userAgent;

  if (/Android/i.test(userAgent)) {
    return "Android";
  }
  return false;
}

export function mapKeywordsId(categoryParam, categories) {
  if (categoryParam && categories) {
    let categorieIds = [];
    const postCategoriesOptions1 = lodash
      .orderBy(
        categories
          .map((c) => Object.assign({}, c, { order: parseInt(c.order) }))
          .filter((_) => _.id === categoryParam),
        "order",
      )
      .map((c) => {
        return Object.assign({}, c, {
          value: c.id,
          title: c.category_name,
          parent_id: lodash
            .orderBy(
              categories.filter((_) => _.id === c.parent),
              "order",
            )
            .map((c2) =>
              Object.assign({}, c2, {
                value: c2.id,
                title: c2.category_name,
                parent_id: lodash
                  .orderBy(
                    categories.filter((_) => _.id === c2.parent),
                    "order",
                  )
                  .map((c3) =>
                    Object.assign({}, c3, {
                      value: c3.id,
                      title: c3.category_name,
                      parent_id: lodash
                        .orderBy(
                          categories.filter((_) => _.id === c3.parent),
                          "order",
                        )
                        .map((c4) =>
                          Object.assign({}, c4, {
                            value: c4.id,
                            title: c4.category_name,
                            parent_id: lodash.orderBy(
                              categories.filter((_) => _.id === c4.parent),
                              "order",
                            ),
                          }),
                        ),
                    }),
                  ),
              }),
            ),
        });
      });

    function printIdsWithParent(data) {
      data.forEach((item, i) => {
        if (item.parent_id && item.parent_id) {
          categorieIds.push(item.id);

          if (item.parent_id?.length > 0) {
            printIdsWithParent(item.parent_id);
          }
        }
      });
    }

    if (postCategoriesOptions1 && postCategoriesOptions1?.length > 0) {
      printIdsWithParent(postCategoriesOptions1);
    }

    if (categorieIds && categorieIds?.length > 0) {
      return categorieIds?.join(",");
    }
    return false;
  }
}

function findParentRecord(item, categoryId, finalResult) {
  if ((item, categoryId)) {
    item?.map((e) => {
      if (e?.id === categoryId) {
        finalResult.push(e);
        return true;
      }

      if (e?.children && e?.children?.length > 0) {
        findParentRecord(e?.children, categoryId);
      }
    });
  }
}

export const getParentRecord = (categoryId, categorieRecords) => {
  let finalResult = [];
  if (categoryId && categorieRecords) {
    categorieRecords?.map((e, i) => {
      if (e && e.id === categoryId) {
        finalResult.push(e);
        return true;
      }

      if (e?.children && e?.children?.length > 0) {
        findParentRecord(e?.children, categoryId, finalResult);
      }
    });

    return finalResult;
  }
};

/**
 *
 * @param {string} url
 * @param {integer} maxLength
 * @returns
 */
export function shortenUrl(url, maxLength) {
  if (url.length <= maxLength) {
    return url;
  }

  const prefixLength = Math.floor((maxLength - 3) / 2);
  const suffixLength = Math.ceil((maxLength - 3) / 2);

  const shortenedUrl =
    url.slice(0, prefixLength) + "..." + url.slice(-suffixLength);
  return shortenedUrl;
}

/**
 *
 * @param {string} text
 * @returns
 */
export function replaceUrlWithTag(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return (
    text &&
    removeTags(text)
      .replace(/\n/g, " ")
      .replace(/\t/g, " ")
      .split(" ")
      .map((a) =>
        a.match(urlRegex)
          ? `<a href='${a}' target="_blank">${shortenUrl(a, 20)}</a>`
          : a,
      )
      .join(" ")
  );
}

/**
 *
 * @param {string} text
 * @returns
 */
export function replaceShortenUrlWithString(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return (
    text &&
    removeTags(text)
      .replace(/\n/g, " ")
      .replace(/\t/g, " ")
      .split(" ")
      .map((a) => (a.match(urlRegex) ? shortenUrl(a, 20) : a))
      .join(" ")
  );
}

/**
 *
 * @param {string} str
 * @returns
 */
export function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

/**
 *
 * @param {string} input
 * @returns
 */
export const formatPhoneNumber = (input, country_code, format) => {
  // Remove non-numeric characters from the input
  const numericInput = input && input.replace(/\D/g, "");

  // Apply phone number formatting
  const formattedPhoneNumber =
    format && typeof format === "function"
      ? format(numericInput, country_code)
      : country_code === "+33"
      ? numericInput &&
        numericInput.replace(
          /(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/,
          "$1 $2 $3 $4 $5 $6",
        )
      : country_code === "+91"
      ? numericInput.replace(/(\d{2})(\d{5})(\d{5})/, "$1 $2 $3")
      : numericInput.replace(
          /(\d{3})(\d{2})(\d{2})(\d{2})(\d{3})/,
          "$1 $2 $3 $4 $5",
        );

  return formattedPhoneNumber;
};

/**
 *
 * @param {string} number
 * @returns
 */
export const convertToCurrency = (number) => {
  return (
    (number &&
      new Intl.NumberFormat("en-us", {
        style: "currency",
        currency: "AED",
      })
        .format(number)
        .replace("AED", "")
        .replace(".00", "")
        .trim()) ||
    ""
  );
};
