/***
 *
 * Input Field
 *
 */

import React from "react";
import TextField from "@mui/material/TextField";
import Styles from "./styles";
import InputAdornment from "@mui/material/InputAdornment";

export default function ({
  input,
  label,
  placeholder,
  autoFocus,
  type,
  disabled,
  prefix,
  style,
  variant,
  multiline,
  rows,
  rowMax,
  className,
  errorStyle,
  defaultBlur,
  inputProps = {},
  size,
  meta: { touched, error, warning },
}) {
  const classes = Styles();
  const { name, value, onChange } = input;

  const InputChange = defaultBlur
    ? Object.assign(
      {},
      {
        onBlur: (e) => onChange(e.target.value),
        defaultValue: value || "",
      },
    )
    : Object.assign(
      {},
      {
        onChange: (e) => onChange(e.target.value),
        value: value || "",
      },
    );

  return (
    <div style={style || {}}>
      <TextField
        name={name}
        type={type}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        className={className || classes.fieldColor}
        fullWidth
        size="small"
        // size={size || "medium"}
        variant={variant || "outlined"}
        {...InputChange}
        InputProps={{
          ...inputProps,
          startAdornment: prefix ? (
            <InputAdornment position="start">{prefix}</InputAdornment>
          ) : null,
        }}
        autoFocus={autoFocus}
        multiline={multiline || false}
        rows={rows || 1}
      />
      <div style={errorStyle || {}} className={classes.error}>
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
}
