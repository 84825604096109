/**
 *
 * Forgot Password Form
 *
 */

import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { Grid, Button, Typography } from "@mui/material";
import InputField1 from "components/InputField1";
import Styles from "./styles";
import ButtonSpinner from "components/ButtonSpinner";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function ForgotPasswordForm({
  handleSubmit,
  submitting,
  success,
  error,
  errorMessage,
  clearCache,
  destroy,
  locationState,
  change,
  iframe,
  invalid,
  onSubmitClose,
  closeModal,
  progress,
  handleModalFunc,
  pristine,
}) {
  const classes = Styles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpoints = window.innerWidth > 3072;

  useEffect(() => {
    return () => destroy();
  }, []);

  return (
    <form onSubmit={handleSubmit} className={classes.form} noValidate>
      <Grid container className={classes.header} justify="space-between">
        <Grid item xs={12}>
          <Typography component="span" className={classes.title}>
            {"Forget password?"}
          </Typography>
          <Grid item xs={12} className={classes.messageGrid}>
            <Typography component="span" className={classes.message}>
              {
                "Enter your Email/Phone number and instruction will be sent to you!"
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        xs={12}
        className={classes.body}
        justify="flex-end"
      >
        <Grid item xs={12} md={10} sm={10} lg={10}>
          <Field
            name="username"
            className={classes.fieldStyle}
            label="Email/Phone number"
            component={InputField1}
            autoCompleteOff={true}
            type="text"
            size={"small"}
            required
            autoFocus
            variant={"standard"}
            logoType={"username"}
          />
        </Grid>
        <Grid container justify="flex-end" className={classes.footer}>
          <Grid item xs={12} md={10} sm={10} className={classes.btnStyle}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={pristine || submitting}
              onClick={!invalid && onSubmitClose ? closeModal : null}
            >
              {((submitting || progress) && (
                <ButtonSpinner size={sm ? 25 : breakpoints ? 40 : 25} />
              )) ||
                "Submit"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

const validate = (values) => {
  const errors = {};

  const requiredFields = ["username"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  if (values.username && parseInt(values.username)) {
    let phoneno = /^\d{9}$/;

    if (!values?.username?.match(phoneno)) {
      errors.username = "Invalid Phone number";
    }

    if (values?.username?.length > 9) {
      errors.username = "Phone number must be 9 digits";
    }
  } else {
    if (
      values.username &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.username)
    ) {
      errors.username = "Invalid Email";
    }
  }

  return errors;
};

export default reduxForm({
  form: "forgot",
  validate,
  touchOnChange: false,
})(ForgotPasswordForm);
