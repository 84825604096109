import { makeStyles, withStyles } from "@mui/styles";
import { ListItem } from "@mui/material";

export const StyledListItem = withStyles({
  root: {
    "&$selected": {
      color: localStorage.getItem("accentColor") + " !important",
      backgroundColor: "transparent",
    },
    "&:hover": {
      backgroundColor: "#EEEEEE",
    },
    "&.MuiListItemIcon-root": {
      minHeight: "40px",
    },
    "&.MuiListItem-button": {
      minHeight: "48px",
    },
    "&.MuiListItem-root.Mui-selected": {
      color: localStorage.getItem("accentColor") + " !important",
      backgroundColor: "transparent",
    },
  },
  selected: {},
})(ListItem);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: "100%",
    left: "0px",
    top: "0px",
    boxShadow: "0px 7px 4px rgb(0 0 0 / 2%)",
    background: theme.palette.headerBgColor.main,
    zIndex: "999",
    position: "fixed",
    overflow: "hidden",
    height: "64px",
    borderBottom: "1px solid #e0e0e0",
  },
  brandLogo: {
    width: "auto !important",
    // height: "56px",
    cursor: "pointer",
    height: "50px",
    marginTop: "0px"
    // [theme.breakpoints.down("md")]: {
    //   width: "80px",
    // },
    // "@media (max-width: 1330px)": {
    //   width: "72%",
    // },
    // "@media (max-width: 900px)": {
    //   width: "56%",
    // },
  },
  headerSection: {
    paddingRight: "25px",
    paddingLeft: "25px",
    display: "flex",
    flex: "1",
    alignItems: "center",
    flexWrap: "nowrap",
    padding: "0 10px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
    },
    "@media (max-width: 1330px)": {
      minHeight: "56px",
    },
  },
  headerContainer: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
  header_text: {
    position: "relative",
  },
  header_text_1: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "1.813rem",
    lineHeight: "38px",
    color: "#FFFFFF",
    textShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
  },
  header_text_2: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "1.813rem",
    lineHeight: "38px",
    color: "#FFFFFF",
    textShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
  },
  header_sub_text: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "rgba(255, 255, 255, 0.8)",
    textShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
  },
  location: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    cursor: "pointer",
    marginRight: "23px",
    cursor: "pointer",
    // [theme.breakpoints.down("md")]: {
    //   display: "none !important",
    // },
  },
  header_location: {
    marginRight: "5px",
    marginBottom: "2px",
    width: "18px",
    height: "18px",
  },
  headerLocationText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    // textTransform: "capitalize",
  },
  searchBar: {
    "& .MuiOutlinedInput-root": {
      position: "relative",
      background: "#FFFFFF",
      borderRadius: "5px",
      border: "none",
      outline: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "21px",
      textTransform: "capitalize",
      outline: "none",
      "& fieldset": {
        borderColor: "#FFFFFF",
      },
      "&:hover fieldset": {
        borderColor: "#FFFFFF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FFFFFF",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11.5px 14px",
      "@media (max-width: 1330px)": {
        padding: "8px 14px",
        fontSize: "12px",
      },
    },
  },
  loginText: {
    marginLeft: "20px",
    marginRight: "20px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "20px",
    color: "#FFFFFF",
    cursor: "pointer",
  },
  button: {
    // width: "191px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "21px",
    textAlign: "center",
    color: "#fff",
    borderRadius: "5px",
    outline: "none",
    textTransform: "none",
    padding: "10px",
    "@media (max-width: 1330px)": {
      padding: "6px 8px",
      fontSize: "12px",
    },
  },
  userName: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    color: theme.palette.headerTextColor.main,
    textTransform: "capitalize",
    letterSpacing: "-0.015em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
  },
  profileMenu: {
    "& .MuiMenu-paper": {
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      gap: "0.76rem",
      width: "262px",
      height: "235px",
      background: "#f5f5f5",
      boxShadow: "0px 32px 29px rgba(0, 0, 0, 0.19)",
      borderRadius: "15px",
    },
  },
  profile: {
    width: "236px",
    height: "50px",
    boxSizing: "border-box",
    background: "#ffffff",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "7px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    marginBottom: "10px",
    cursor: "pointer",
  },
  profileInfo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontFamily: "Poppins",
  },
  personName: {
    marginLeft: "19px",
    fontFamily: "Poppins",
  },
  list: {
    width: "236px",
    background: "#ffffff",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "7px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: "5px",
    "& .MuiMenuItem-root": {
      gap: "10px",
    },
  },
  profileImg: {
    width: "45px",
    height: "45px",
    objectFit: "cover",
    padding: "4px",
    borderRadius: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "33px !important",
      height: "33px !important",
    },
  },
  searchContainer: {
    display: "flex",
  },
  locationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  accountContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
    },
  },
  text: {
    // fontWeight: "bold",
    color: "#000000",
    lineHeight: "14px",
    fontSize: "14px",
    lineHeight: "15px",
    "@media (max-width: 1330px)": {
      fontSize: "12px",
    },
  },
  listLabel: {
    "@global": {
      span: {
        fontFamily: "Poppins",
      },
    },
  },
}));

export default useStyles;
