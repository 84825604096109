import LoginForm from "components/LoginForm";
import ForgotPasswordForm from "components/ForgotPasswordForm";
import SignupForm from "components/SignupForm";
import ResetPasswordForm from "components/ResetPasswordForm";
import VerificationForm from "components/VerificationForm";

export const ImplementationForm = {
  login: LoginForm,
  forgot: ForgotPasswordForm,
  signup: SignupForm,
  reset: ResetPasswordForm,
  verification: VerificationForm,
};
