import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Styles, { StyledListItem } from "./styles";

import {
  Drawer,
  Grid,
  Typography,
  List,
  ListItemIcon,
  ListItemText,
  Badge,
} from "@mui/material";
import SVG from "react-inlinesvg";
import ImageForm from "components/ImageForm";

import person from "assets/sideNav/user.svg";
import fav from "assets/sideNav/heart.svg";
import lock from "assets/sideNav/user-lock.svg";
import logout from "assets/sideNav/exit.svg";
import notification from "assets/profile/notification-white.png";
import personLogo from "assets/images/card/profile.png";
import { uploadUserProfile as uploadUserProfileAction } from "store/session/actions";
import Tooltip from '@mui/material/Tooltip';

function DrawerComponent(props) {
  const classes = Styles();
  const { children, handleLogout, user, dispatch } = props;
  const { notification_details } = user || {};
  const [openDrawer, setOpenDrawer] = React.useState({ right: false });
  const navigate = useNavigate();
  const location = useLocation();
  const userName = user?.name;
  const email = user?.email;
  const profileLogo = personLogo;

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer({ ...openDrawer, [anchor]: open });
  };

  const handleProfileMenu = (list) => {
    setOpenDrawer({ right: false });
    setTimeout(() => {
      if (list?.value === "logout") {
        handleLogout();
        navigate(`/`);
        window.location.reload();
      } else {
        navigate(`/profile/${list.value}`);
      }
    }, 500);
  };

  const profileOptions = [
    { img: person, label: "My profile", value: "viewProfile" },
    { img: fav, label: "Favorite deals", value: "myWishlist" },
    { img: lock, label: "Change password", value: "changePassword" },
    { img: logout, label: "Logout", value: "logout" },
  ];

  const uploadUserProfile = (value) => {
    dispatch(
      uploadUserProfileAction(
        Object.assign({}, { ...user }, { profile_image: value }),
      ),
    );
  };

  return (
    <>
      {children && children(() => toggleDrawer("right", true))}
      <Drawer
        classes={{
          paper: classes.drawerPaperFixed,
        }}
        anchor={"right"}
        open={openDrawer["right"]}
        onClose={toggleDrawer("right", false)}
      >
        <Grid item xs={12}>
          <Grid item className={classes.userProfile}>
            {user && Object.keys(user).length > 0 ? (
              <Grid item style={{ marginTop: "10px" }}>
                <ImageForm
                  initialValues={Object.assign({}, { ...user })}
                  form={"userProfileForm1"}
                  onSubmit={uploadUserProfile}
                  uploadUserProfile={uploadUserProfile}
                  keepDirtyOnReinitialize
                  customStyle={true}
                />
              </Grid>
            ) : null}
            <Typography variant="h5" className={classes.userName}>
              {userName}
              <Tooltip title="Notification" placement="top">
                <Badge
                  badgeContent={
                    notification_details &&
                    Array.isArray(notification_details) &&
                    notification_details.length
                  }
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  color="error"
                  onClick={() => {
                    navigate("/profile/viewProfile");
                    setOpenDrawer({ right: false });
                  }}
                >
                  {/* <SVG
                    src={notification}
                    className={classes.svg}
                    onClick={() => {
                      navigate("/profile/viewProfile");
                      setOpenDrawer({ right: false });
                    }}
                  /> */}
                  <img src={notification} className={classes.svg} onClick={() => {
                    navigate("/profile/viewProfile");
                    setOpenDrawer({ right: false });
                  }} />
                </Badge>
              </Tooltip>
            </Typography>
            <Typography variant="body1" className={classes.email}>
              {email}
            </Typography>
          </Grid>
          <Grid item className={classes.list}>
            <List className={classes.listBox}>
              {profileOptions?.map((list) => {
                const selected =
                  location?.pathname.indexOf(`/profile/${list.value}`) > -1
                    ? true
                    : false;
                return (
                  <StyledListItem
                    button
                    key={list.value}
                    selected={selected}
                    onClick={() => handleProfileMenu(list)}
                  >
                    <ListItemIcon sx={{ minWidth: "40px" }}>
                      <SVG
                        src={list.img}
                        className={selected ? "selectedOption" : ""}
                        style={{
                          width: "19px",
                          height: "19px",
                          // border: "1px solid black",
                          color: `${(selected) ? localStorage.getItem("accentColor") : "#868686"}`
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={list.label}
                      className={classes.listLabel}
                    />
                  </StyledListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}

export default DrawerComponent;
