/**
 *
 * Error
 *
 */

import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";

const ErrorMessage = ({ style, errorMessage, onClose }) => {
  useEffect(() => {
    if (onClose) {
      setTimeout(onClose, 4000);
    }
  }, []);

  return (
    <div style={style || {}}>
      {errorMessage !== null ? (
        <Alert severity="error" variant="filled">
          {errorMessage}
        </Alert>
      ) : null}
    </div>
  );
};

export default ErrorMessage;
