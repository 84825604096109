/**
 *
 * Verification Form
 *
 */

import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { Grid, Button, Typography } from "@mui/material";
import InputField from "components/InputField";
import Styles from "./styles";
import ButtonSpinner from "components/ButtonSpinner";
import store2 from "store2";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

/**
 *
 * @param {object} props
 * @returns
 */
function VerificationForm(props) {
  const {
    handleSubmit,
    pristine,
    submitting,
    destroy,
    invalid,
    onSubmitClose,
    closeModal,
    progress,
    resendOtp,
    btnLoader
  } = props;
  const classes = Styles();
  const breakpoints = window.innerWidth > 3072;
  const email = store2.get("email");
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [timer, setTimer] = useState(45);

  useEffect(() => {
    let countdown;

    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(countdown);
    };
  }, [timer]);

  useEffect(() => {
    if (!btnLoader)
      setTimer(45)

  }, [btnLoader])

  useEffect(() => {
    return () => destroy();
  }, []);

  if (btnLoader) {
    return <div className={classes.loaderGrid}>
      <div style={{ marginBottom: "25px" }}>Sending Code. Please wait...</div>
      <ButtonSpinner size={50} color="#000" />
    </div>
  }

  return (
    <form onSubmit={handleSubmit} className={classes.form} noValidate>
      <Grid container className={classes.header} justify="space-between">
        <Grid item xs={12}>
          <Typography component="span" className={classes.title}>
            {"Verify for access"}
          </Typography>
          <Grid item xs={12} className={classes.messageGrid}>
            <Typography component="span" className={classes.message}>
              {`Enter the code sent to ${email} to proceed.`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} className={classes.body}>
        <Grid item xs={12} md={10} lg={10} sm={10}>
          <Field
            name="secret_code"
            label="Enter your 6 digit code"
            component={InputField}
            variant="standard"
            type="text"
            size={"small"}
            required
            autoFocus
            logoType={"call_code"}
          />
        </Grid>
        <Grid container justify="flex-end" className={classes.footer}>
          <Grid item xs={12} md={10} sm={10} className={classes.btnStyle}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={pristine || submitting}
              onClick={!invalid && onSubmitClose ? closeModal : null}
            >
              {((submitting || progress) && (
                <ButtonSpinner size={sm ? 25 : breakpoints ? 40 : 25} />
              )) ||
                "Submit"}
            </Button>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} md={10} lg={10} sm={10} style={{ fontSize: '12px', textAlign: 'center', color: 'rgb(16, 202, 119)' }}>Resend Code</Grid> */}
        <Grid item xs={12} sm={10} md={10} lg={10} className={classes.resend}>
          <Grid container wrap="nowrap">
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography component="span" className={classes.resendCode}>
                Didn't receive a code?
                {timer ? <span>&nbsp; {`00:${timer.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`}</span> : <span
                  className={classes.resendCodeLink}
                  onClick={() => {
                    resendOtp();
                    setTimer(45);
                  }}
                >
                  Resend Code
                </span>}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

const validate = (values) => {
  const errors = {};

  const requiredFields = ["secret_code"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  return errors;
};

export default reduxForm({
  form: "verification",
  validate,
  touchOnChange: false,
})(VerificationForm);
