/***
 * 
 * 
 * Network Error Component
 * 
 * 
 */

import React from "react";
import { Container, Grid, Typography } from "@mui/material"


function NetworkError () {

        return (
            <>
                <Container style={{ minHeight: "500px", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", padding: "25px" }}>
                    <Grid item lg={12}>
                        <Typography variant="h2" component="h2">
                            Whoops!
                        </Typography>
                        <Typography variant="h8" component="h8">
                            No Internet connection found. Check your connection and try again.
                        </Typography>      
                    </Grid>
                </Container>
            </>
        )
}

export default NetworkError;
