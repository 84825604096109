import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  let accentColor = theme.palette.accentColor ? String(theme.palette.accentColor.main) : "#000000";
  return ({
    fab: {
      position: "fixed",
      bottom: "16px",
      right: "16px",
      width: "42px",
      height: "42px",
      color: "#FFFFFF",
      opacity: '75%',
      background: "linear-gradient(180deg, #00CE75 0%, " + accentColor + " 100%)",
      [theme.breakpoints.down("sm")]: {
        width: "36px",
        height: "36px",
      },
    },
    fab2: {
      position: "fixed",
      bottom: "16px",
      left: "16px",
      width: "42px",
      height: "42px",
      color: "#FFFFFF",
      opacity: '75%',
      background: "linear-gradient(180deg, #00CE75 0%," + accentColor + " 100%)",
      [theme.breakpoints.down("sm")]: {
        width: "36px",
        height: "36px",
      },
    }
  })
});

export default useStyles;
