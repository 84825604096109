import { lazy } from 'react';
const Home = lazy(() => import('pages/Home'));
const Profile = lazy(() => import('pages/Profile'));
const Contact = lazy(() => import('pages/Contact'));
const ViewProfile = lazy(() => import('pages/ViewProfile'));
const ChangePassword = lazy(() => import('pages/ChangePassword'));
const WishList = lazy(() => import('pages/WishList'));
const NotFound = lazy(() => import('pages/NotFound'));
const ProductDetails = lazy(() => import('pages/ProductDetails'));
const ProductFilter = lazy(() => import('pages/ProductFilter'));
const About = lazy(() => import('pages/About'));
const FAQ = lazy(() => import('pages/FAQ'));
const Policy = lazy(() => import('pages/Policy'));
const Disclaimer = lazy(() => import('pages/Disclaimer'));

export const ImplementationFor = {
  Home,
  Profile,
  ViewProfile,
  ChangePassword,
  Contact,
  WishList,
  NotFound,
  ProductDetails,
  ProductFilter,
  About,
  FAQ,
  Policy,
  Disclaimer
};
