import { Grid } from "@mui/material";
import React from "react";
import { Field, reduxForm } from "redux-form";
import FileUpload from "components/FileUpload";

const ImageForm = ({ handleSubmit, uploadUserProfile, customStyle }) => {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid item>
        <Field
          name={`profile_image`}
          type="text"
          component={FileUpload}
          uploadApi
          upload
          uploadUserProfile={uploadUserProfile}
          contentType="image/*"
          customStyle={customStyle}
        />
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: "imageForm",
  touchOnChange: false,
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
})(ImageForm);
