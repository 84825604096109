/*
 *
 *  session selectors
 *
 */

import { createSelector } from "reselect";
import { Map } from "immutable";

export const selectSession = () => (state) => state ? state.session : Map();

export const selectRouter = () => (state) => state ? state.router : Map();

export const selectForm = () => (state) => state ? state.form : Map();

export const selectLoggedIn = () =>
  createSelector(
    selectSession(),
    (sessionState) => (sessionState && sessionState.loggedIn) || false,
  );

export const selectUser = () =>
  createSelector(
    selectSession(),
    (sessionState) => (sessionState && sessionState.user) || {},
  );

export const selectError = () =>
  createSelector(
    selectSession(),
    (sessionState) => (sessionState && sessionState.error) || false,
  );

export const selectSuccess = () =>
  createSelector(
    selectSession(),
    (sessionState) => (sessionState && sessionState.success) || false,
  );

export const selectLoading = () =>
  createSelector(
    selectSession(),
    (sessionState) => (sessionState && sessionState.loading) || false,
  );

export const selectNotification = () =>
  createSelector(
    selectSession(),
    (sessionState) => (sessionState && sessionState.notification) || [],
  );

export const selectIsMobileDevice = () =>
  createSelector(
    selectSession(),
    (sessionState) => (sessionState && sessionState.isMobile) || false,
  );

export const selectGeoLocation = () =>
  createSelector(
    selectSession(),
    (sessionState) => (sessionState && sessionState.geoLocation) || false,
  );

export const selectBtnLoader = () =>
  createSelector(
    selectSession(),
    (sessionState) => (sessionState && sessionState.btnLoader) || false,
  );

export const selectBanner = () =>
  createSelector(
    selectSession(),
    (sessionState) => (sessionState && sessionState.banner) || false,
  );
export const selectLoadingBanner = () =>
  createSelector(
    selectSession(),
    (sessionState) => (sessionState && sessionState.loadingBanner) || false,
  );
export const selectCategories = () =>
  createSelector(
    selectSession(),
    (sessionState) => (sessionState && sessionState.categories) || [],
  );

export const selectCategoriesOptions = () =>
  createSelector(
    selectSession(),
    (sessionState) => (sessionState && sessionState.categoriesOptions) || [],
  );

const selectors = {
  selectSession,
  selectLoggedIn,
  selectUser,
  selectError,
  selectSuccess,
  selectLoading,
  selectNotification,
  selectIsMobileDevice,
  selectGeoLocation,
  selectBtnLoader,
  selectBanner,
  selectLoadingBanner,
  selectCategories,
  selectCategoriesOptions,
};

export default selectors;
