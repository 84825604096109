import React from "react";
import TextField from "@mui/material/TextField";
import Styles from "./styles";
import Icons from "components/Icons";
import InputAdornment from "@mui/material/InputAdornment";

function InputField({
  input,
  label,
  placeholder,
  autoFocus,
  type,
  disabled,
  prefix,
  style,
  variant,
  multiline,
  rows,
  rowMax,
  className,
  errorStyle,
  defaultBlur,
  inputProps = {},
  size,
  logoType,
  meta: { touched, error, warning },
}) {
  const classes = Styles();

  const { name, value, onChange } = input;

  const InputChange = defaultBlur
    ? Object.assign(
        {},
        {
          onBlur: (e) => onChange(e.target.value),
          defaultValue: value || "",
        },
      )
    : Object.assign(
        {},
        {
          onChange: (e) => onChange(e.target.value),
          value: value || "",
        },
      );
  return (
    <div>
      <div>
        <label htmlFor={label} className={classes.label}>
          {label} :
        </label>
      </div>
      <div className={classes.fieldContainer}>
        {(logoType && (
          <Icons type={logoType} classStyle={classes.iconStyle} />
        )) ||
          null}
        <TextField
          className={classes.input}
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          fullWidth
          size={size || "medium"}
          variant={variant || "outlined"}
          {...InputChange}
          InputProps={{
            ...inputProps,
            startAdornment: value && parseInt(value) ? <InputAdornment position="start">+971</InputAdornment> : null,
            disableUnderline: true,
          }}
          autoFocus={autoFocus}
          multiline={multiline || false}
          rows={rows || 1}
        />
      </div>
      <div style={errorStyle || {}} className={classes.error}>
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
}

export default InputField;
