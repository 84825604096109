import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  modalPaper1: {
    width: "71% !important",
    minHeight: "600px",
    maxWidth: "1500px",
    "@media (max-width: 1300px)": {
      width: "71% !important",
      minHeight: "444px !important",
    },
    "@media (min-width: 1920px)": {
      width: "71% !important",
      minHeight: "800px !important",
    },
    "@media (min-width: 3072px)": {
      width: "71% !important",
      minHeight: "1120px !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
      minHeight: "auto",
    },
  },
}));

export default useStyles;
