/**
 *
 *
 * Spinner
 *
 */

import React, { useEffect } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { getColors } from "../../theme/index"

const Spinner = (props) => {
  useEffect(() => {
    if (!props.disableFullPageLoader) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'auto';
      }
    }
  }, []);

  return (
    <Grid container style={{...props.style, zIndex:"1001"}} className={props.className} >
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <FadeLoader
          height={props?.sm ? 9 : 15}
          loading
          margin={props?.sm ? 7 : 16}
          speedMultiplier={1}
          color={localStorage.getItem("accentColor") || "#00571e"}
        />
      </Grid>
    </Grid>
  );
};

export default Spinner;
