/**
 *
 * Reset Password Form
 *
 */

import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { Grid, Button, Typography } from "@mui/material";
import PasswordField1 from "components/PasswordField1";
import ButtonSpinner from "components/ButtonSpinner";
import Styles from "./styles";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

/**
 *
 * @param {object} props
 * @returns
 */
function ResetPasswordForm(props) {
  const {
    handleSubmit,
    pristine,
    submitting,
    error,
    success,
    destroy,
    invalid,
    onSubmitClose,
    closeModal,
    progress,
  } = props;
  const classes = Styles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpoints = window.innerWidth > 3072;

  useEffect(() => {
    return () => destroy();
  }, []);

  return (
    <form onSubmit={handleSubmit} className={classes.form} noValidate>
      <Grid container className={classes.header} justify="space-between">
        <Grid item xs={12}>
          <Typography component="span" className={classes.title}>
            {"New credentials"}
          </Typography>
          <Grid item xs={12} className={classes.messageGrid}>
            <Typography component="span" className={classes.message} style={{ textTransform: "none" }}>
              {"Your identity has been verified set your new password"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        xs={sm ? 12 : 9}
        className={classes.body}
        justify="flex-end"
      >
        <Grid item xs={12} md={10} lg={10} sm={10} className={classes.fieldTop}>
          <Field
            name="new_password"
            className={classes.fieldStyle}
            label="New Password"
            component={PasswordField1}
            type="text"
            size={"small"}
            required
            autoFocus
            variant={"standard"}
            autoCompleteOff={true}
            logoType={"password"}
          />
        </Grid>
        <Grid item xs={12} md={10} lg={10} sm={10} className={classes.fieldTop}>
          <Field
            name="confirm_password"
            className={classes.fieldStyle}
            label="Confirm password"
            component={PasswordField1}
            type="text"
            size={"small"}
            required
            autoFocus
            variant={"standard"}
            autoCompleteOff={true}
            logoType={"password"}
          />
        </Grid>
        <Grid container justify="flex-end" className={classes.footer}>
          <Grid item xs={12} md={10} sm={10} className={classes.btnStyle}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={pristine || submitting}
              className={classes.button}
              onClick={!invalid && onSubmitClose ? closeModal : null}
            >
              {((submitting || progress) && (
                <ButtonSpinner size={sm ? 25 : breakpoints ? 40 : 25} />
              )) ||
                "Submit"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

const validate = (values) => {
  const errors = {};

  const sequence =
    /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678)+/gi;

  const identical = /^(?!.*(.)\1\1.*).*$/gim;

  const commonNames = [
    "123456",
    "password",
    "123456789",
    "12345678",
    "12345",
    "111111",
    "1234567",
    "sunshine",
    "qwerty",
    "iloveyou",
    "princess",
    "admin",
    "welcome",
    "666666",
    "abc123",
    "football",
    "123123",
    "monkey",
    "654321",
    "!@#$%^&amp;*",
    "charlie",
    "aa123456",
    "donald",
    "password1",
    "qwerty123",
  ];

  const requiredFields = ["new_password", "confirm_password"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  if (values.new_password && values.new_password.length < 8) {
    errors.new_password = "Password must be at least 8 characters";
  }

  if (
    values.new_password &&
    values.new_password.length >= 8 &&
    !/^(?=.*[\d#?!@$%^&*-]).{8,}$/i.test(values.new_password)
  ) {
    errors.new_password =
      "Must contain at least one numeric or special character ";
  }

  if (
    (values.new_password && sequence.test(values.new_password)) ||
    !identical.test(values.new_password)
  ) {
    errors.new_password =
      "Avoid consecutive sequential and identical characters";
  }

  commonNames.forEach((field) => {
    if (values.new_password === field) {
      errors.new_password = "Password is easily guessable";
    }
  });

  if (
    values.new_password &&
    values.confirm_password &&
    values.confirm_password !== values.new_password
  ) {
    errors.confirm_password = "Password Mismatch";
  }

  return errors;
};

export default reduxForm({
  form: "reset",
  validate,
  touchOnChange: false,
})(ResetPasswordForm);
